export const TRACKER_QUERY_STRINGS = Object.freeze({
  LOGIN: '#login'
})

export const TRACKER_OBJECTS = Object.freeze({
  USER: 'User',
  PAGE: 'Page',
  LIBRARY_ITEM: 'LibraryItem',
  LIBRARY_3DFILE: 'Library3DFile',
  LIBRARY_IMAGE: 'LibraryImage',
  RENDER: 'Render',
  RENDER_IMAGE: 'RenderImage',
  STYLE: 'Style',
  STYLE_VERSION: 'StyleVersion',
  STYLE_COLORWAY: 'StyleColorway',
  STYLE_IMAGE: 'StyleImage',
  COMMENT: 'Comment',
  PLUGIN: 'Plugin'
})

export const TRACKER_EVENTS = Object.freeze({
  LOGGED_IN: 'LoggedIn',
  LOGGED_OUT: 'LoggedOut',
  SIGNED_UP: 'SignedUp',
  CHANGED_PASSWORD: 'ChangedPassword',
  FILTERED: 'Filtered',
  CLEARED_FILTERS: 'ClearedFilters',
  VIEWED: 'Viewed',
  CLOSED: 'Closed',
  DELETED: 'Deleted',
  RERENDERED: 'ReRendered',
  BULK_DELETED: 'BulkDeleted',
  UPDATED: 'Updated',
  ADDED: 'Added',
  DOWNLOADED: 'Downloaded',
  RENDERED: 'Rendered',
  SENT_TO_PLUGIN: 'SentToPlugin',
  SET_AS_MAIN: 'SetAsMain',
  REORDERED: 'Reordered',
  ZOOMED: 'Zoomed'
})

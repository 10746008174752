import Vue from 'vue'
import serverAPI from '@/services/serverAPI'

// ////////////////////////////////////////////////////
// Actions  (ROOT)
// ////////////////////////////////////////////////////

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       route
 */
const setRoute = ({ commit }, route) => {
  commit('setRoute', route)
}

/**
 * @param {Store}          context
 * @param {Store.commit}   context.commit
 * @param {Store.dispatch} context.dispatch
 */
const fetchCognitoUserData = async ({ getters, commit, dispatch }) => {
  let userInfo = await serverAPI.whoami()

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.VUE_APP_DEV_EMAIL &&
    process.env.VUE_APP_API_ENVIRONMENT !== 'local'
  ) {
    userInfo = {
      email: process.env.VUE_APP_DEV_EMAIL
    }
  }

  commit('setCognitoUserData', userInfo)

  // If there's only one workspace, it gets immediately selected, bypassing the
  // workspaces selection page
  if (userInfo.workspaces.length === 1) {
    dispatch('setActiveWorkspace', userInfo.workspaces[0])
  }

  const activeWorkspace = getters.getActiveWorkspace

  if (
    activeWorkspace &&
    userInfo.workspaces.some(item => item.code === activeWorkspace.code)
  ) {
    dispatch('setActiveWorkspace', activeWorkspace)
  }

  if (userInfo.teams[0]) {
    commit('setActiveTeam', userInfo.teams[0])
  }
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {boolean}      isNotFound
 */
const setIsNotFound = ({ commit }, isNotFound) => {
  commit('setIsNotFound', isNotFound)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {string}       payload.action
 * @param {object}       payload.item
 * @param {string}       [payload.libraryType='unknown']
 * @param {object}       payload.object
 * @param {*}            payload.value
 * @param {string}       payload.label
 */
const trackEvent = (
  { commit },
  {
    object,
    action,
    item = { id: 'no id', name: 'no name' },
    value,
    label,
    libraryType = 'unknown'
  }
) => {
  Vue.tracking.trackEvent({
    object,
    action,
    category: libraryType,
    label: label || item.name,
    value,
    item
  })
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {TeamShape}    team
 */
const setActiveTeam = ({ commit }, team) => {
  commit('setActiveTeam', team)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const setActiveWorkspace = ({ commit }, payload) => {
  commit('setActiveWorkspace', payload)
}

/**
 *
 * @param {Store.commit} commit
 * @param {boolean}      isDarkMode
 */
const setDarkMode = ({ commit }, isDarkMode) => {
  commit('setIsDarkMode', isDarkMode)
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////
const actions = {
  setRoute,
  fetchCognitoUserData,
  setIsNotFound,
  trackEvent,
  setActiveTeam,
  setActiveWorkspace,
  setDarkMode
}

export default actions

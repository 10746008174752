<template>
  <div
    id="turntable"
    ref="viewerContainer"
  >
    <StitchLoader
      v-if="!imagesLoaded"
      size="medium"
      class="stitch-image-turntable-viewer__loader"
    />
    <div
      ref="viewport"
      class="f-panzoom stitch-image-viewer__full"
    >
      <canvas
        ref="imageContainer"
        class="f-panzoom__content stitch-image-viewer__full-canvas"
      />
    </div>
    <div class="v360-curve-icon" />
  </div>
</template>

<script>
import { Panzoom } from '@fancyapps/ui/dist/panzoom/panzoom.umd.js'
import '@fancyapps/ui/dist/panzoom/panzoom.css'

export default {
  name: 'Option360Viewer',

  props: {
    imagesList: {
      type: Array,
      require: true,
      default: () => []
    }
  },

  data () {
    return {
      currentScale: 1.0,
      currentTopPosition: 0,
      currentLeftPosition: 0,
      currentImage: null,
      dragging: false,
      canvas: null,
      ctx: null,
      dragStart: null,
      lastX: 0,
      lastY: 0,
      currentCanvasImage: null,
      isFullScreen: true,
      viewPortElementWidth: null,
      movementStart: 0,
      movement: false,
      speedFactor: 13,
      activeImage: 1,
      stopAtEdges: false,
      imagesLoaded: false,
      loadedImages: 0,
      centerX: 0,
      centerY: 0,
      panmode: false,
      images: [],
      amount: 0,
      percentage: 0,
      disableScrolling: false
    }
  },

  watch: {
    /**
     */
    currentLeftPosition () {
      this.redraw()
    },

    /**
     */
    currentTopPosition () {
      this.redraw()
    },

    /**
     */
    viewPortElementWidth () {
      this.update()
    },

    /**
     */
    panmode () {
      this.attachEvents()
    },

    /**
     * @param {object} value
     */
    isFullScreen (value) {
      if (!value) {
        this.$refs.viewerContainer.classList.remove('v360-main')
        this.$refs.viewerContainer.classList.remove('v360-fullscreen')
      } else {
        this.$refs.viewerContainer.classList.add('v360-main')
        this.$refs.viewerContainer.classList.add('v360-fullscreen')
      }

      this.setImage()
    }
  },

  mounted () {
    this.toggleFullScreen()
    this.preloadImages()
    document.addEventListener('fullscreenchange', this.exitHandler)
    document.addEventListener('webkitfullscreenchange', this.exitHandler)
    document.addEventListener('mozfullscreenchange', this.exitHandler)
    document.addEventListener('MSFullscreenChange', this.exitHandler)

    // eslint-disable-next-line no-new -- Panzoom has to be invoked with new to init the zoom
    new Panzoom(this.$refs.viewport, {
      click: 'toggleCover',
      spinner: false,
      panOnlyZoomed: true,
      on: {
        endAnimation: instance => {
          if (instance.scale > 1.05) {
            this.disableScrolling = true
            this.$refs.viewport.style.cursor = 'move'
          } else {
            this.disableScrolling = false
            this.$refs.viewport.style.cursor = 'grab'
          }
        }
      }
    })
  },

  methods: {
    /**
     *
     */
    initData () {
      this.loadInitialImage()

      this.canvas = this.$refs.imageContainer
      this.ctx = this.canvas.getContext('2d')
      this.attachEvents()
      window.addEventListener('resize', this.resizeWindow)
      this.resizeWindow()
    },

    /**
     *
     */
    fetchData () {
      this.imagesList.forEach(image => {
        this.imageData.push(image)
      })

      this.preloadImages()
    },

    /**
     *
     */
    preloadImages () {
      if (this.imagesList.length) {
        try {
          this.amount = this.imagesList.length
          this.imagesList.forEach(src => {
            this.addImage(src)
          })
        } catch (error) {
          console.error(
            `Something went wrong while loading images: ${error.message}`
          )
        }
      } else {
        console.error('No Images Found')
      }
    },

    /**
     *
     * @param {string} resultSrc
     */
    addImage (resultSrc) {
      const image = new Image()
      image.src = resultSrc
      image.referrerPolicy = 'no-referrer'
      image.onload = this.onImageLoad.bind(this)
      image.onerror = this.onImageLoad.bind(this)

      this.images.push(image)
    },

    /**
     * @param {event} event
     */
    onImageLoad (event) {
      const percentage = Math.round((this.loadedImages / this.amount) * 100)

      this.loadedImages += 1
      this.updatePercentageInLoader(percentage)

      if (this.loadedImages === this.amount) {
        this.onAllImagesLoaded(event)
      }
    },

    /**
     * @param {number} percentage
     */
    updatePercentageInLoader (percentage) {
      this.percentage = percentage + '%'
    },

    /**
     */
    onAllImagesLoaded () {
      this.imagesLoaded = true
      this.$refs.viewport.style.cursor = 'grab'
      this.initData()
    },

    /**
     *
     */
    loadInitialImage () {
      this.currentImage = this.imagesList[0]
      this.setImage()
    },

    /**
     *
     */
    resizeWindow () {
      this.setImage()
    },

    /**
     *
     */
    attachEvents () {
      this.bind360ModeEvents()
    },

    /**
     *
     */
    bind360ModeEvents () {
      this.$refs.viewport.removeEventListener('mouseup', this.stopDragging)
      this.$refs.viewport.removeEventListener('mousedown', this.startDragging)
      this.$refs.viewport.removeEventListener('mousemove', this.doDragging)

      this.$refs.viewport.addEventListener('mouseup', this.stopMoving)
      this.$refs.viewport.addEventListener('mousedown', this.startMoving)
      this.$refs.viewport.addEventListener('mousemove', this.doMoving)
    },

    /**
     * @param {boolean} cached
     */
    setImage (cached = false) {
      this.currentLeftPosition = this.currentTopPosition = 0

      if (!cached) {
        this.currentCanvasImage = new Image()
        this.currentCanvasImage.referrerPolicy = 'no-referrer'
        this.currentCanvasImage.src = this.currentImage

        this.currentCanvasImage.onload = () => {
          const viewportElement = this.$refs.viewport.getBoundingClientRect()
          this.canvas.width = this.isFullScreen
            ? viewportElement.width
            : this.currentCanvasImage.width
          this.canvas.height = this.isFullScreen
            ? viewportElement.height
            : this.currentCanvasImage.height
          this.trackTransforms(this.ctx)

          this.redraw()
        }
      } else {
        this.currentCanvasImage = this.images[0]
        const viewportElement = this.$refs.viewport.getBoundingClientRect()
        this.canvas.width = this.isFullScreen
          ? viewportElement.width
          : this.currentCanvasImage.width
        this.canvas.height = this.isFullScreen
          ? viewportElement.height
          : this.currentCanvasImage.height
        this.trackTransforms(this.ctx)

        this.redraw()
      }
    },

    /**
     *
     */
    redraw () {
      try {
        const p1 = this.ctx.transformedPoint(0, 0)
        const p2 = this.ctx.transformedPoint(
          this.canvas.width,
          this.canvas.height
        )

        const hRatio = this.canvas.width / this.currentCanvasImage.width
        const vRatio = this.canvas.height / this.currentCanvasImage.height
        const ratio = Math.min(hRatio, vRatio)
        const centerShiftX =
          (this.canvas.width - this.currentCanvasImage.width * ratio) / 2
        const centerShiftY =
          (this.canvas.height - this.currentCanvasImage.height * ratio) / 2
        this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y)

        this.centerX = (this.currentCanvasImage.width * ratio) / 2
        this.centerY = (this.currentCanvasImage.height * ratio) / 2

        // center image
        this.ctx.drawImage(
          this.currentCanvasImage,
          this.currentLeftPosition,
          this.currentTopPosition,
          this.currentCanvasImage.width,
          this.currentCanvasImage.height,
          centerShiftX,
          centerShiftY,
          this.currentCanvasImage.width * ratio,
          this.currentCanvasImage.height * ratio
        )
      } catch (e) {
        this.trackTransforms(this.ctx)
      }
    },

    /**
     * @param {number} pageDirection
     */
    onMove (pageDirection) {
      if (pageDirection - this.movementStart >= this.speedFactor) {
        const itemsSkippedRight =
          Math.floor((pageDirection - this.movementStart) / this.speedFactor) ||
          1

        this.movementStart = pageDirection
        this.moveActiveIndexUp(itemsSkippedRight)
        this.redraw()
      } else if (this.movementStart - pageDirection >= this.speedFactor) {
        const itemsSkippedLeft =
          Math.floor((this.movementStart - pageDirection) / this.speedFactor) ||
          1

        this.movementStart = pageDirection

        this.moveActiveIndexDown(itemsSkippedLeft)

        this.redraw()
      }
    },

    /**
     * @param {event} evt
     */
    startMoving (evt) {
      this.movement = true
      this.movementStart = evt.pageX
    },

    /**
     * @param {event} evt
     */
    doMoving (evt) {
      if (this.movement && !this.disableScrolling) {
        this.onMove(evt.clientX)
      }
    },

    /**
     * @param {number} itemsSkipped
     */
    moveActiveIndexUp (itemsSkipped) {
      if (this.stopAtEdges) {
        if (this.activeImage + itemsSkipped >= this.amount) {
          this.activeImage = this.amount
        } else {
          this.activeImage += itemsSkipped
        }
      } else {
        this.activeImage =
          (this.activeImage + itemsSkipped) % this.amount || this.amount
      }

      this.update()
    },

    /**
     * @param {number} itemsSkipped
     */
    moveActiveIndexDown (itemsSkipped) {
      if (this.stopAtEdges) {
        if (this.activeImage - itemsSkipped <= 1) {
          this.activeImage = 1
        } else {
          this.activeImage -= itemsSkipped
        }
      } else {
        if (this.activeImage - itemsSkipped < 1) {
          this.activeImage = this.amount + (this.activeImage - itemsSkipped)
        } else {
          this.activeImage -= itemsSkipped
        }
      }

      this.update()
    },

    /**
     *
     */
    update () {
      this.currentCanvasImage = this.images[this.activeImage - 1]
      this.redraw()
    },

    /**
     *
     */
    stopMoving () {
      this.movement = false
      this.movementStart = 0
    },

    /**
     * @param {event} evt
     */
    startDragging (evt) {
      this.dragging = true
      document.body.style.mozUserSelect =
        document.body.style.webkitUserSelect =
        document.body.style.userSelect =
          'none'

      this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft
      this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop
      this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY)
    },

    /**
     * @param {event} evt
     */
    doDragging (evt) {
      this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft
      this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop

      if (this.dragStart) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY)
        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y)
        this.redraw()
      }
    },

    /**
     * @param {event} evt
     */
    stopDragging () {
      this.dragging = false
      this.dragStart = null
    },

    /**
     * @param   {object} ctx
     *
     * @returns {object}
     */
    trackTransforms (ctx) {
      return new Promise(resolve => {
        const svg = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        )
        let xform = svg.createSVGMatrix()
        this.ctx.getTransform = () => {
          return xform
        }

        const savedTransforms = []
        const save = ctx.save
        this.ctx.save = () => {
          savedTransforms.push(xform.translate(0, 0))

          return save.call(this.ctx)
        }

        const restore = ctx.restore
        this.ctx.restore = () => {
          xform = savedTransforms.pop()

          return restore.call(this.ctx)
        }

        const scale = this.ctx.scale
        this.ctx.scale = (sx, sy) => {
          xform = xform.scaleNonUniform(sx, sy)

          return scale.call(this.ctx, sx, sy)
        }

        const rotate = this.ctx.rotate
        this.ctx.rotate = radians => {
          xform = xform.rotate((radians * 180) / Math.PI)

          return rotate.call(this.ctx, radians)
        }

        const translate = this.ctx.translate
        this.ctx.translate = (dx, dy) => {
          xform = xform.translate(dx, dy)

          return translate.call(this.ctx, dx, dy)
        }

        const transform = this.ctx.transform
        this.ctx.transform = (a, b, c, d, e, f) => {
          const m2 = svg.createSVGMatrix()
          m2.a = a
          m2.b = b
          m2.c = c
          m2.d = d
          m2.e = e
          m2.f = f
          xform = xform.multiply(m2)

          return transform.call(this.ctx, a, b, c, d, e, f)
        }

        const setTransform = this.ctx.setTransform
        this.ctx.setTransform = (a, b, c, d, e, f) => {
          xform.a = a
          xform.b = b
          xform.c = c
          xform.d = d
          xform.e = e
          xform.f = f

          return setTransform.call(this.ctx, a, b, c, d, e, f)
        }

        const pt = svg.createSVGPoint()
        this.ctx.transformedPoint = (x, y) => {
          pt.x = x
          pt.y = y

          return pt.matrixTransform(xform.inverse())
        }

        resolve(this.ctx)
      })
    },

    /**
     *
     */
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
    }
  }
}
</script>

<style lang="scss">
$stitch-slideshow-z-index: 9999;
$stitch-image-viewer-image-z-index: 9999;
$stitch-panzoom-max-width: 65%;
$stitch-curve-icon-bottom-space: 5%;
$stitch-image-viewer-loader-z-index: 9999;
$stitch-image-loader-position: 50%;

.v360-main {
  position: relative;
  display: contents;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: spacing(128);
  height: 100%;
  margin: spacing(3) auto;
  cursor: default;
  filter: alpha(opacity=50);
}

.v360-viewport {
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $white;
}

.v360-viewport iframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.v360-viewport img {
  position: relative;
}

.v360-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $stitch-slideshow-z-index;
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  padding: 0;
}

.v360-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.v360-curve-icon {
  position: absolute;
  bottom: $stitch-curve-icon-bottom-space;
  left: 0;
  width: 100%;
  height: spacing(4);
  margin-top: spacing(2);
  background-image: url('~@/assets/images/360curve.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-origin: content-box;
}

.f-panzoom__content {
  max-width: $stitch-panzoom-max-width;
  height: spacing(92);
  min-height: spacing(62);
}

.stitch-image-turntable-viewer__loader {
  position: absolute;
  top: $stitch-image-loader-position;
  left: $stitch-image-loader-position;
  z-index: $stitch-image-viewer-loader-z-index;
  display: block !important;
  width: 100%;
  height: 100%;
}

.stitch-image-viewer__full {
  z-index: $stitch-image-viewer-image-z-index;
  width: 100%;
  height: 100%;
  background: transparent;
}

.stitch-image-viewer__full-canvas {
  width: spacing(68);
}
</style>

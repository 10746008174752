import axios from 'axios'
import Utils from '@/services/utils'

/// //////////////////////////////////////////////
// Init AXIOS
/// //////////////////////////////////////////////
let successCallback = null
let errorCallback = null

/**
 * @returns {string}
 */
const getApiBaseUrl = () => {
  // Local development env variable (only)
  let baseUrl = process.env.VUE_APP_RENDER_FARM_MASTER_URL

  if (!baseUrl) {
    baseUrl = `https://${
      process.env.VUE_APP_RENDERS_API_SUBDOMAIN
    }.${Utils.getAppDomain()}/`
  }

  return baseUrl
}

const HTTPRenderFarmMaster = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Stitch-Client': 'Hub-FE'
  },
  withCredentials: true
})

HTTPRenderFarmMaster.interceptors.response.use(
  response => {
    if (successCallback) {
      successCallback(response)
    }

    return response
  },
  error => {
    if (errorCallback && !axios.isCancel(error)) {
      errorCallback(error)
    }

    return Promise.reject(error)
  }
)

/**
 * @param {object} parameters
 */
const setCallbacks = parameters => {
  successCallback = parameters.successCallback
  errorCallback = parameters.errorCallback
}

/**
 * @param   {number[]} listIds
 *
 * @returns {object[]}         - [{id, status, status_detail}]
 */
const fetchJobRenderStatus = async listIds => {
  const response = await HTTPRenderFarmMaster.get(
    `/job_statuses/?job_id__in=${listIds.join(',')}`
  )

  return response.data
}

/// //////////////////////////////////////////////
// JOBS
/// //////////////////////////////////////////////

let cancelFunction = null
let previousCalledPage = -1
/**
 * @param   {object} payload
 *
 * @returns {Array}
 */
const fetchAllJobs = async payload => {
  // Check if need to cancel previous call
  if (payload.page <= previousCalledPage) {
    if (cancelFunction) {
      cancelFunction('cancelation')
    }
  }

  previousCalledPage = payload.page

  const name = payload.search
  const limit = payload.limit
  const offset = (payload.page - 1) * limit
  const sortingParameters = payload.sortingParameters

  const url = '/jobs/?preview'

  try {
    const response = await HTTPRenderFarmMaster.get(url, {
      params: {
        type: 'dh-blend-file-task,render-fbx-zip,dh-render-vsp-user,dh-render-plugin-vray',
        sort_by: sortingParameters.sortBy,
        order: sortingParameters.order,
        name,
        personal: true,
        offset,
        limit
      },
      cancelToken: new axios.CancelToken(cancel => {
        cancelFunction = cancel
      })
    })

    return response.data.results
  } catch (error) {
    // If error, the error will be caught in the error callback
    // Returning an empty array to prevent error related to the ui
    if (!axios.isCancel(error)) {
      console.warn(error)
    }

    return null
  }
}

/**
 * @param   {number} jobId
 * @param   {string} search
 *
 * @returns {Array}
 */
const fetchNewJobs = async (jobId, search) => {
  const name = search

  try {
    const response = await HTTPRenderFarmMaster.get(
      `/jobs/?id__gt=${jobId}&preview`,
      {
        params: {
          name,
          personal: true,
          type: 'dh-blend-file-task,render-fbx-zip,dh-render-vsp-user,dh-render-plugin-vray'
        }
      }
    )

    return response.data
  } catch (error) {
    // If error, the error will be caught in the error callback
    // Returning an empty array to prevent error related to the ui
    console.warn(error)

    return []
  }
}

/**
 * @param   {number}       jobId
 *
 * @returns {AxiosPromise}
 */
const deleteJob = jobId => {
  return HTTPRenderFarmMaster.delete(`/jobs/${jobId}/`)
}

/**
 * @param   {number} jobId
 * @param   {string} query
 *
 * @returns {object}
 */
const fetchJobDetail = async (jobId, query = 'preview&include-task-files') => {
  const response = await HTTPRenderFarmMaster.get(`/jobs/${jobId}/?${query}`)

  return response.data
}

/**
 * @param   {number} jobID
 *
 * @returns {string}
 */
const getRendersDownloadURL = async jobID => {
  const response = await HTTPRenderFarmMaster.get(
    `/jobs/${jobID}/files/zip/?type=render`
  )

  return response.data
}

/// //////////////////////////////////////////////
// Object Building
/// //////////////////////////////////////////////

const jobsCalls = {
  fetchAllJobs,
  fetchNewJobs,
  deleteJob,
  fetchJobDetail,
  getRendersDownloadURL
}

const RenderFarmMasterAPI = {
  getApiBaseUrl,
  setCallbacks,
  fetchJobRenderStatus,
  ...jobsCalls
}

export default RenderFarmMasterAPI

<template>
  <div
    :class="[
      'stitch-loader',
      { [`stitch-loader__${placement}`]: placement },
      { 'stitch-loader--fill-container': fillContainer }
    ]"
  >
    <div
      :class="[
        'stitch-loader__item',
        { [`stitch-loader__item--${size}`]: size }
      ]"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export const LOADER_SIZES = {
  MINI: 'mini',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

export const LOADER_PLACEMENT = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
}

export default {
  name: 'StitchLoader',

  props: {
    size: VueTypes.oneOf(Object.values(LOADER_SIZES)).def(LOADER_SIZES.SMALL),
    fillContainer: VueTypes.bool.def(true),
    placement: VueTypes.oneOf(Object.values(LOADER_PLACEMENT)).def(
      LOADER_PLACEMENT.CENTER
    )
  }
}
</script>

<style lang="scss" scoped>
$spinner-mini-size: spacing(2);
$spinner-small-size: spacing(3);
$spinner-medium-size: spacing(4);
$spinner-large-size: spacing(5);
$spinner-margin-top: 20px;
$spinner-top-position: -15px;

.stitch-loader {
  @include flex-center;

  &.stitch-loader__start {
    justify-content: flex-start;
  }

  &.stitch-loader__center {
    justify-content: center;
  }

  &.stitch-loader__end {
    justify-content: flex-end;
  }
}

.stitch-loader--fill-container {
  width: 100%;
  height: 100%;
}

.stitch-loader__item {
  background-image: url('~@/assets/images/spinner.svg');
  background-repeat: no-repeat;
  background-size: cover;
  animation: rotating $transition-duration-2 linear infinite;

  &.stitch-loader__item--mini {
    position: relative;
    top: $spinner-top-position;
    width: $spinner-mini-size;
    height: $spinner-mini-size;
    margin-top: $spinner-margin-top;
  }

  &.stitch-loader__item--small {
    width: $spinner-small-size;
    height: $spinner-small-size;
  }

  &.stitch-loader__item--medium {
    width: $spinner-medium-size;
    height: $spinner-medium-size;
  }

  &.stitch-loader__item--large {
    width: $spinner-large-size;
    height: $spinner-large-size;
  }
}
</style>

import serverAPI from '@/services/serverAPI'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import { FILE_TYPES } from '@/constants/fileTypes'

/**
 * @param   {string} fileType
 *
 * @returns {string}
 */
const getTrackerObject = fileType => {
  return fileType === FILE_TYPES.THUMBNAIL
    ? TRACKER_OBJECTS.LIBRARY_IMAGE
    : TRACKER_OBJECTS.LIBRARY_3DFILE
}

/**
 * @param {Store}          context
 * @param {Store.getter}   context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.itemId
 * @param {string}         payload.fileType
 * @param {File}           payload.file
 * @param {string|null}    [payload.description=null]
 * @param {string}         payload.extension
 * @param {string}         payload.libraryType
 */
const addItemFile = async (
  { getters, dispatch },
  { itemId, fileType, file, description = null, extension, libraryType }
) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  await serverAPI.addFile({
    itemId,
    fileType,
    extension,
    file,
    description,
    libraryType,
    activeWorkspace
  })

  dispatch('trackEvent', {
    object: getTrackerObject(fileType),
    action: TRACKER_EVENTS.ADDED,
    item: file,
    value: itemId,
    label: description,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.itemId
 * @param {File[]}         payload.files
 * @param {string}         payload.fileType
 * @param {string}         payload.libraryType
 */
const updateItemFiles = async (
  { dispatch },
  { itemId, files, fileType, libraryType }
) => {
  const { filesToRemove, filesToKeep, filesToAdd } = files

  // Remove old files to hard delete them from the DB
  for (const file of filesToRemove) {
    await dispatch('deleteItemFile', {
      itemId,
      fileType,
      fileId: file.id,
      libraryType
    })
  }

  for (const file of filesToKeep) {
    if (!file.description || !file.descriptionChanged) {
      continue
    }

    await dispatch('updateItemFileDescription', {
      itemId,
      fileType,
      fileId: file.id,
      fileDescription: file.description,
      libraryType
    })
  }

  // Add new ones
  for (const file of filesToAdd) {
    await dispatch('addItemFile', {
      itemId,
      fileType,
      file: file.file,
      description: file.description,
      extension: file.extension,
      libraryType
    })
  }
}

/**
 * @param {Store}          context
 * @param {Store.getter}   context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.fileId
 * @param {string}         payload.fileType
 * @param {number}         payload.itemId
 * @param {string}         payload.libraryType
 */
const deleteItemFile = async (
  { getters, dispatch },
  { fileId, fileType, itemId, libraryType }
) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  dispatch('trackEvent', {
    object: getTrackerObject(fileType),
    action: TRACKER_EVENTS.DELETED,
    item: itemId,
    value: fileId,
    label: `File deleted: ${fileId}`,
    libraryType
  })

  await serverAPI.deleteFile(libraryType, fileId, activeWorkspace)
}

/**
 * @param {Store}          context
 * @param {Store.getter}   context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.fileId
 * @param {string}         payload.fileDescription
 * @param {string}         payload.fileType
 * @param {number}         payload.itemId
 * @param {string}         payload.libraryType
 */
const updateItemFileDescription = async (
  { getters, dispatch },
  { fileId, fileDescription, fileType, itemId, libraryType }
) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  dispatch('trackEvent', {
    object: getTrackerObject(fileType),
    action: TRACKER_EVENTS.UPDATED,
    item: itemId,
    value: fileId,
    label: `File updated: ${fileId}`,
    libraryType
  })

  await serverAPI.updateFileDescription(
    libraryType,
    fileId,
    fileDescription,
    activeWorkspace
  )
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////

const actions = {
  addItemFile,
  updateItemFiles,
  deleteItemFile,
  updateItemFileDescription
}

export default actions

<template>
  <div class="detail-thumbnail-loader">
    <div
      v-if="loadingState === loadingStates.UPLOADING"
      :ref="loadingStates.UPLOADING"
      class="image-loading__icon"
    >
      <StitchLoader />
    </div>

    <div
      v-else-if="loadingState === loadingStates.RENDERING"
      :ref="loadingStates.RENDERING"
      class="image-loading"
    >
      <img
        :src="placeholderUrl"
        :alt="loadingState"
        class="detail-thumbnail-loader__image"
      >
      <div class="image-loading__icon">
        <StitchLoader />
      </div>
    </div>

    <img
      v-else
      :ref="loadingStates.COMPLETE"
      class="detail-thumbnail-loader__image"
      :src="src || placeholderUrl"
    >
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export const THUMBNAIL_LOADING_STATE = {
  UPLOADING: 'uploading',
  RENDERING: 'rendering',
  COMPLETE: 'complete'
}

export default {
  name: 'DetailThumbnailLoader',

  props: {
    loadingState: VueTypes.oneOf(Object.values(THUMBNAIL_LOADING_STATE))
      .isRequired,
    src: VueTypes.string
  },

  data () {
    return {
      loadingStates: THUMBNAIL_LOADING_STATE,
      placeholderUrl: require('@/assets/images/img-placeholder.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
$thumbnail-loader-size: 50px;
$icon-font-size: 24px;

.detail-thumbnail-loader {
  position: relative;
  flex: 0 0 auto;
  width: $thumbnail-loader-size;
  height: $thumbnail-loader-size;
  margin-right: spacing(2);
}

.detail-thumbnail-loader__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-loading {
  position: relative;
  z-index: $z-index-layer-2;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-s;
}

.image-loading__icon {
  @include position-absolute;
  @include flex-center;
}
</style>

import store from '@/store/store'
import styleUploadFormConfiguration from '@/stubs/styleUploadFormConfiguration/styleUploadFormConfiguration.json'
import { camelCase, cloneDeep } from 'lodash'
import { FILTER_TYPE } from '@/constants/filterType'

/**
 * Merges the default configuration with flexible attributes to be used by forms
 *
 * @returns {object} - Form Configuration
 */
export const getConfig = () => {
  const defaultConfiguration = cloneDeep(styleUploadFormConfiguration)

  const attributes = store ? store.getters.getStylesAttributes : []

  if (attributes) {
    Object.entries(attributes).forEach(entry => {
      const [key, value] = entry

      // TODO PYDX-436: Handle asset_status while creating/updating a style
      if (key !== FILTER_TYPE.ASSET_STATUS) {
        if (
          !defaultConfiguration.steps[1].fields.some(
            attribute => camelCase(attribute.id) === camelCase(key)
          )
        ) {
          defaultConfiguration.steps[1].fields.push({
            id: key,
            name: key,
            required: false,
            type: 'select',
            injectOptions: value
          })
        }
      }
    })
  }

  return defaultConfiguration
}

export default class FeatureFlagsManager {
  /**
   * @param {object} config
   */
  constructor (config) {
    /**
     * data contains the url to retrieve the feature flags, and the features
     *
     * @type {object}
     * @public
     */
    this.data = {
      url: config.url || null,
      features: config.features || {}
    }
  }

  /**
   */
  async initialize () {
    if (this.data.url) {
      const response = await fetch(this.data.url)

      if (!response || !response.ok) {
        console.warn('Error:', response)

        throw new Error(
          `Error retrieving user access to features.
           URL: ${this.data.url}
           Status: ${response.status}
           StatusText: ${response.statusText}`
        )
      }

      const features = await response.json()
      this.data.features = features.reduce((featureMap, feature) => {
        featureMap[feature.name] = feature

        return featureMap
      }, {})
    }
  }

  /**
   * @param   {string}  featureName
   * @param   {object}  options
   *
   * @returns {boolean}
   */
  canAccessFeature (featureName, options) {
    const feature = this.data.features[featureName]
    const { user } = options

    if (!feature) {
      console.warn(
        `"${featureName}" is not a valid Feature Flag. Please check the existing ones and try again.`
      )

      return false
    }

    const canUserAccessFeature =
      feature.whitelist &&
      this.validateEmail(user.email, feature.whitelist) &&
      !this.validateEmail(user.email, feature.blacklist)

    return feature.active && canUserAccessFeature
  }

  /**
   * @param   {string}  featureName
   * @param   {object}  options
   *
   * @returns {boolean}
   */
  isTeamBlacklisted (featureName, options) {
    const feature = this.data.features[featureName]

    const { currentTeamId } = options

    if (!feature) {
      console.warn(
        `"${featureName}" is not a valid Feature Flag. Please check the existing ones and try again.`
      )

      return false
    }

    const isTeamInBlacklist =
      feature.blacklist && feature.blacklist.includes(Number(currentTeamId))

    return feature.active && isTeamInBlacklist
  }

  /**
   * @param   {string}  featureName
   * @param   {object}  options
   *
   * @returns {boolean}
   */
  canTeamAccessFeature (featureName, options) {
    const feature = this.data.features[featureName]

    const { currentTeamId } = options

    if (!feature) {
      console.warn(
        `"${featureName}" is not a valid Feature Flag. Please check the existing ones and try again.`
      )

      return false
    }

    const canAccessFeature =
      feature.whitelist && feature.whitelist.includes(Number(currentTeamId))

    return feature.active && canAccessFeature
  }

  /**
   * @param   {string}  email
   * @param   {string}  pattern
   *
   * @returns {boolean}
   */
  validateEmail (email, pattern) {
    const regexPattern = pattern
      .join('|')
      .replace(/[.+^${}()[\]\\]/g, '\\$&')
      .replace(/\*/g, '.*')
      .replace(/\?/g, '.')

    const validationPattern = new RegExp(`^${regexPattern}$`, 'i')

    return validationPattern.test(email)
  }
}

<template>
  <div class="library-status-selector">
    <p v-if="vendorCompanyId">
      {{ vendorCompany.name }}
    </p>
    <ElementSelect
      v-else
      v-model="selectedVendor"
      placeholder="Vendor Company"
      size="small"
      class="item-vendor__selector"
      @change="updateSelectedVendor"
    >
      <ElementOption
        v-for="vendor in vendors"
        :key="vendor.id"
        :label="vendor.name"
        :value="vendor.id"
      />
    </ElementSelect>
  </div>
</template>

<script>
export default {
  name: 'VendorSelector',

  props: {
    vendorCompanyId: {
      type: Number,
      default: () => null
    },

    vendors: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update-vendor'],

  data () {
    return {
      selectedVendor: null
    }
  },

  computed: {
    /**
     * @returns {string|object}
     */
    vendorCompany () {
      if (this.vendorCompanyId) {
        return this.vendors.find(v => v.id === this.vendorCompanyId) || {}
      }

      return ''
    }
  },

  methods: {
    /**
     */
    updateSelectedVendor () {
      const selectedVendor = this.vendors.find(
        v => v.id === this.selectedVendor
      )
      this.$confirm(
        `Are you sure you want to assign this style to the vendor company "${selectedVendor.name}"?`,
        'Info',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('update-vendor', this.selectedVendor)
          this.selectedVendor = null
        })
        .catch(() => {
          this.selectedVendor = null
        })
    }
  }
}
</script>

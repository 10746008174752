export const ICON_TYPE = Object.freeze({
  MENU: 'icon--more'
})

export const STYLE_TYPE = Object.freeze({
  DANGER: 'danger'
})

export const DROPDOWN_PLACEMENT = Object.freeze({
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end'
})

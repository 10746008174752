var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'stitch-loader',
    ( _obj = {}, _obj[("stitch-loader__" + _vm.placement)] = _vm.placement, _obj ),
    { 'stitch-loader--fill-container': _vm.fillContainer }
  ]},[_c('div',{class:[
      'stitch-loader__item',
      ( _obj$1 = {}, _obj$1[("stitch-loader__item--" + _vm.size)] = _vm.size, _obj$1 )
    ]})])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <StitchDialog
    class="option-slideshow"
    append-to-body
    visible
    fullscreen
    @close="emitClose"
  >
    <div class="option-slideshow__content">
      <aside
        v-if="showNavigation"
        class="option-slideshow__navigation option-slideshow__navigation--left"
      >
        <ElementButton
          ref="previousSlideButton"
          circle
          @click="showPreviousSlide"
        >
          <i class="el-icon-arrow-left" />
        </ElementButton>
      </aside>

      <div
        v-for="(slide, index) in slides"
        v-show="index === activeSlideIndex"
        :key="slide"
        :ref="`slide-${index}`"
        class="option-slideshow__slide"
      >
        <template
          v-if="
            index === 0 && activeSlideIndex === 0 && viewer360Images.length > 0
          "
        >
          <Option360Viewer :images-list="viewer360Images" />
        </template>
        <template v-else>
          <StitchImageViewer
            :ref="`slide-image-${index}`"
            :image-url="slide"
            :image-thumbnail-url="slideThumbnails[index]"
          />
        </template>
      </div>

      <aside
        v-if="showNavigation"
        class="option-slideshow__navigation option-slideshow__navigation--right"
      >
        <ElementButton
          ref="nextSlideButton"
          circle
          @click="showNextSlide"
        >
          <i class="el-icon-arrow-right" />
        </ElementButton>
      </aside>
    </div>
  </StitchDialog>
</template>

<script>
import VueTypes from 'vue-types'
// s3Trim
import { DataUtils } from '@/mixins/utils'
import StitchImageViewer from '@/components/common/StitchImageViewer'
import { IMAGE_RESOLUTION } from '@/constants/image'
import { KEYBOARD_KEY } from '@/constants/keyboardKey'
import Option360Viewer from '@/components/option/OptionList/components/Option360Viewer.vue'

export default {
  name: 'OptionSlideshow',

  components: {
    Option360Viewer,
    StitchImageViewer
  },

  mixins: [DataUtils],

  props: {
    imageUrls: VueTypes.arrayOf(String).def([]),
    activeIndex: VueTypes.number.def(0),
    viewerImageUrls: VueTypes.arrayOf(String).def([])
  },

  data () {
    return {
      slides: [],
      slideThumbnails: [],
      activeSlideIndex: 0,
      viewer360Images: []
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    showNavigation () {
      return this.slides.length > 1
    },

    /**
     * @returns {number}
     */
    nextSlideIndex () {
      if (this.activeSlideIndex === this.slides.length - 1) {
        return 0
      } else {
        return this.activeSlideIndex + 1
      }
    },

    /**
     * @returns {number}
     */
    previousSlideIndex () {
      if (this.activeSlideIndex === 0) {
        return this.slides.length - 1
      } else {
        return this.activeSlideIndex - 1
      }
    }
  },

  created () {
    this.activeSlideIndex = this.activeIndex

    for (const imageUrl of this.imageUrls) {
      this.slides.push(this.s3trim(imageUrl, IMAGE_RESOLUTION.PREVIEW_OPTION))
      this.slideThumbnails.push(
        this.s3trim(imageUrl, IMAGE_RESOLUTION.THUMBNAIL)
      )
    }

    for (const imageUrl of this.viewerImageUrls) {
      this.viewer360Images.push(
        this.s3trim(imageUrl, IMAGE_RESOLUTION.PREVIEW_OPTION)
      )
    }

    window.addEventListener('keyup', this.handleKeyUp, true)
    this.$emit('slide-change', this.activeSlideIndex)
  },

  destroyed () {
    window.removeEventListener('keyup', this.handleKeyUp, true)
  },

  methods: {
    /**
     * @param {KeyboardEvent} event
     */
    handleKeyUp (event) {
      // No one overlays a Dialog so it is meant to stopPropagation
      event.stopPropagation()

      if (KEYBOARD_KEY.LEFT.includes(event.key)) {
        this.showPreviousSlide()
      } else if (KEYBOARD_KEY.RIGHT.includes(event.key)) {
        this.showNextSlide()
      }
    },

    /**
     */
    showPreviousSlide () {
      this.activeSlideIndex = this.previousSlideIndex

      this.$emit('slide-change', this.activeSlideIndex)
    },

    /**
     */
    showNextSlide () {
      this.activeSlideIndex = this.nextSlideIndex

      this.$emit('slide-change', this.activeSlideIndex)
    },

    /**
     */
    emitClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
$image-slideshow-max-height: 100vh;
$image-slideshow-content-max-height: 80vh;
$navigation-arrow-position: spacing(4);

.option-slideshow {
  /deep/ .el-dialog {
    aspect-ratio: 1/1;
    height: 100%;
    max-height: $image-slideshow-max-height;
    margin: 0 auto;
  }

  /deep/ .el-dialog__body {
    overflow-y: hidden;
  }
}

.option-slideshow__content {
  display: flex;
  align-self: center;
  width: 100%;
  height: 100%;
  max-height: $image-slideshow-content-max-height;
}

.option-slideshow__navigation {
  @include position-absolute(right, center);

  z-index: $z-index-layer-0;
}

.option-slideshow__navigation--left {
  right: auto;
  left: $navigation-arrow-position;
}

.option-slideshow__navigation--right {
  right: $navigation-arrow-position;
}

.option-slideshow__slide {
  flex: 1;
  padding: 0 spacing(10);
}
</style>

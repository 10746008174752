import Vue from 'vue'
import * as SentryClient from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

const ignoredSentryErrorCodes = [400, 401, 403, 404]

/**
 * @param   {object}  sentryEvent
 * @param   {object}  sentryHint
 *
 * @returns {boolean}
 */
const isAllowedSentryException = (sentryEvent, sentryHint) => {
  if (
    sentryHint.originalException &&
    sentryHint.originalException.response &&
    ignoredSentryErrorCodes.includes(
      sentryHint.originalException.response.status
    )
  ) {
    return false
  } else {
    return true
  }
}

/**
 * @param {string} email
 */
const identifyUserByEmail = email => {
  SentryClient.configureScope(scope => {
    scope.setUser({
      email
    })
  })
}

/**
 * @param {string} message
 * @param {*}      [data]
 */
const addBreadcrumb = (message, data) => {
  SentryClient.addBreadcrumb({
    category: 'console',
    message,
    data: {
      data
    }
  })
}

/**
 */
const initialize = () => {
  if (process.env.NODE_ENV === 'production') {
    SentryClient.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.SENTRY_RELEASE,
      environment: process.env.VUE_APP_ENVIRONMENT,
      integrations: [new VueIntegration({ Vue, logErrors: true })],

      /**
       * @param   {Event}      event
       * @param   {object}     hint
       *
       * @returns {Event|null}
       */
      beforeSend (event, hint) {
        if (isAllowedSentryException(event, hint)) {
          return event
        } else {
          return null
        }
      }
    })
  } else {
    /* eslint-disable no-console -- Serves as a debug for Sentry, it can be pretty useful! */
    console.debug(
      'Not configuring Sentry, environment is',
      process.env.VUE_APP_ENVIRONMENT,
      process.env.NODE_ENV
    )
    /* eslint-enable no-console -- We need to enable the console after */
  }
}

const Sentry = {
  initialize,
  identifyUserByEmail,
  addBreadcrumb
}

export default Sentry

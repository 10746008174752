import { LIBRARY_TYPE } from '@/constants/libraryType'
import { SORT_OPTION } from '@/constants/sortOption'

const items = Object.values(LIBRARY_TYPE)

/**
 * @returns {object}
 */
const getDefaultState = () => {
  const state = {
    activeLibraryType: null
  }

  items.forEach(item => {
    state[item] = {
      items: [],
      itemDetail: null,
      itemStatus: null,
      uploadStatuses: {},
      availableFilters: null,
      appliedFilters: {},
      search: '',
      sorting: SORT_OPTION.NAME,
      paging: {
        currentPage: 1,
        itemsLimit: 10,
        itemsCount: null
      },
      hasReachedEnd: false,
      hasToScrollToTop: false
    }

    if (item === LIBRARY_TYPE.STYLE || item === LIBRARY_TYPE.BLOCK) {
      state[item].versionsUploadInProgress = {}
      state[item].allVersionsImagesUploadInProgress = {}
      state[item].allOptionsImagesUploadInProgress = {}
    }

    if (item === LIBRARY_TYPE.STYLE) {
      state[item].plmOptions = []
      state[item].tags = []
      state[item].sorting = SORT_OPTION.NEWEST_FIRST
      state[item].attributes = {}
    }
  })

  return state
}

export default getDefaultState

<template>
  <div
    v-if="hasItems"
    ref="reference"
    v-click-outside="hideDropdown"
  >
    <StitchTooltip
      :message="disabledMessage"
      :is-disabled="!isDisabled"
    >
      <ElementButton
        v-if="showButton"
        ref="button"
        data-testid="stitchDropdownTrigger"
        type="text"
        :disabled="isDisabled"
        @click.stop="setDropdownVisibility(!isDropdownVisible)"
      >
        <div class="stitch-dropdown__trigger-container">
          <i
            v-if="iconType"
            ref="icon"
            :class="['icon', iconType]"
          />
          <slot name="trigger">
            <div
              v-if="title"
              ref="title"
            >
              {{ title }}
            </div>
          </slot>
        </div>
      </ElementButton>
    </StitchTooltip>

    <div
      ref="popper"
      class="stitch-dropdown__panel"
    >
      <ElementCascaderPanel
        v-if="isDropdownVisible"
        ref="panel"
        v-model="selectedItems"
        class="el-popper el-cascader__dropdown"
        :options="visibleDropdownItems"
        :props="{
          label: 'name',
          value: 'method',
          emitPath: false
        }"
        @change="triggerCallback"
      >
        <template #default="{ data }">
          <slot
            name="listItem"
            :data="data"
          >
            <StitchTooltip
              :ref="`${formatName(data.name)}-tooltip`"
              :message="data.disabledMessage"
              placement="right"
              :is-disabled="!data.disabled"
            >
              <span
                :data-testid="data.dataTestid"
                :class="['stitch-dropdown__item-name', ...getItemClasses(data)]"
              >{{ data.name }}</span>
            </StitchTooltip>
          </slot>
        </template>
      </ElementCascaderPanel>
    </div>
  </div>
</template>

<script>
import Popper from 'element-ui/src/utils/vue-popper'
import VueTypes from 'vue-types'
import _camelCase from 'lodash/camelCase'
import { DropdownItemShape } from '@/types'

export default {
  name: 'StitchDropdown',

  mixins: [Popper],

  props: {
    title: VueTypes.string,
    iconType: VueTypes.string,
    showButton: VueTypes.bool.def(true),
    showDropdown: VueTypes.bool.def(false),
    dropdownItems: VueTypes.arrayOf(DropdownItemShape),
    isDisabled: VueTypes.bool.def(false),
    disabledMessage: VueTypes.strings
  },

  data () {
    return {
      selectedItems: [],
      isDropdownVisible: false
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    hasItems () {
      return this.dropdownItems.length > 0
    },

    /**
     * @returns {Array}
     */
    visibleDropdownItems () {
      this.dropdownItems.map(item => {
        if (!item.method) {
          item.method = item.name
        }

        return this
      })

      // default to show:true if undefined
      return this.dropdownItems.filter(item =>
        item.show !== undefined ? item.show : true
      )
    }
  },

  watch: {
    /**
     * @param {boolean} value
     */
    showDropdown (value) {
      this.setDropdownVisibility(value)
    }
  },

  beforeCreate () {
    this.$options.propsData.appendToBody =
      this.$options.propsData.appendToBody || false
  },

  created () {
    this.createPopper()

    if (this.popperJS) {
      this.popperJS.state.scrollTarget.addEventListener(
        'scroll',
        this.hideDropdown
      )
    }
  },

  beforeDestroy () {
    if (this.popperJS) {
      this.popperJS.state.scrollTarget.removeEventListener(
        'scroll',
        this.hideDropdown
      )
    }
  },

  methods: {
    /**
     */
    hideDropdown () {
      this.setDropdownVisibility(false)
    },

    /**
     * @param   {DropdownItemShape} item
     *
     * @returns {Array}
     */
    getItemClasses (item) {
      if (item.styles && !item.disabled) {
        return item.styles.map(style => `stitch-dropdown--${style}`)
      }

      return []
    },

    /**
     * @param   {string} name
     *
     * @returns {string}
     */
    formatName (name) {
      return _camelCase(name)
    },

    /**
     * @param {boolean} visibility
     */
    async setDropdownVisibility (visibility) {
      this.isDropdownVisible = visibility

      if (this.isDropdownVisible) {
        await this.$nextTick()
        await this.updatePopper()
      } else {
        this.selectedItems = []
        this.$emit('visibility-change')
      }
    },

    /**
     * @param {Function} [method]
     */
    triggerCallback (method) {
      if (!method) {
        return
      }

      method()
      this.hideDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
$icon-size: spacing(2);

.icon {
  display: flex;
  width: $icon-size;
  height: $icon-size;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon--more {
  background-image: url('~@/assets/icons/icon-options.svg');
}

.stitch-dropdown__trigger-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.stitch-dropdown__item-name {
  display: inline;
}

.stitch-dropdown--danger {
  color: $red;
}
</style>

<template>
  <ElementFormItem
    :prop="inputKey"
    :rules="rules"
    :label="name"
    :error="customError"
    class="form-generator__content-item"
  >
    <StitchTooltip
      :message="disabledMessage"
      :is-disabled="!isDisabled"
    >
      <ElementInput
        v-model="inputData"
        class="form-generator__text-input"
        :placeholder="name"
        :disabled="isDisabled"
        :show-password="parameters.password"
        @input="setData"
      />
    </StitchTooltip>

    <InputNotes
      v-if="notes.length > 0"
      :notes="notes"
    />
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'
import InputNotes from './InputNotes'

export default {
  name: 'InputText',

  components: {
    InputNotes
  },

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: String,
      required: false,
      default: ''
    },
    parameters: {
      type: Object,
      required: true
    }
  },

  methods: {
    /**
     */
    initInput () {
      if (this.defaultValue === null) {
        this.inputData = null
      } else {
        this.inputData = this.defaultValue || ''
      }

      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';

.form-generator__text-input {
  min-width: $input-width;
}
</style>

export const FetchData = {
  data: () => ({
    isLoading: false,
    fetchDataCounter: 0
  }),

  methods: {
    /**
     * @param {Array}    items
     * @param {Function} fetchMethod
     * @param {boolean}  [forceUpdate=false]
     */
    async fetchData (items, fetchMethod, forceUpdate = false) {
      const loaded = items && items.length > 0

      if (forceUpdate || !loaded) {
        this.fetchDataCounter++
        const counter = this.fetchDataCounter
        this.isLoading = true

        await fetchMethod()

        if (counter === this.fetchDataCounter) {
          this.isLoading = false
        }
      }
    }
  }
}

import actions from '@/store/modules/invitation/actions.js'
import getters from '@/store/modules/invitation/getters.js'
import mutations from '@/store/modules/invitation/mutations.js'
import getDefaultState from '@/store/modules/invitation/state.js'

export default {
  actions,
  getters,
  mutations,
  state: getDefaultState()
}

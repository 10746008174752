<template>
  <ElementFormItem
    :prop="inputKey"
    :rules="rules"
    :error="customError"
    class="form-generator__content-item form-generator__content-image"
  >
    <ElementUpload
      drag
      list-type="text"
      action=""
      :auto-upload="false"
      :file-list="fileList"
      :on-change="onChange"
      :on-remove="onChange"
      :accept="acceptedExtensionsForInput"
      :multiple="canAcceptMultipleFiles"
      :show-file-list="false"
      :class="[
        'form-generator__upload-input',
        {
          'form-generator__limit-multiple-list':
            !canAcceptMultipleFiles && fileList.length > 1
        }
      ]"
    >
      <img
        v-if="thumbnailPreview"
        :src="thumbnailPreview"
        class="form-generator__upload-input-preview"
        crossorigin="use-credentials"
      >
      <div
        v-else
        class="el-upload__text"
      >
        Drop your {{ name }} here or
        <em>click to upload</em>
        <br>
        (
        {{ acceptedExtensionsToDisplay }}
        )
      </div>
    </ElementUpload>
    <template v-for="(files, extension) in filesGroupedByExtension">
      <div
        v-if="!isThumbnail"
        :key="extension"
      >
        <h4
          v-if="canAcceptMultipleFiles"
          class="form-generator__upload-list-section"
        >
          {{ extension }}
        </h4>
        <ElementUploadList
          ref="uploadList"
          class="form-generator__limit-multiple-list"
          :disabled="false"
          :files="files"
          :handle-preview="undefined"
          list-type="text"
          @remove="removeFile"
        />
      </div>
    </template>
  </ElementFormItem>
</template>

<script>
import _debounce from 'lodash/debounce'
import Utils from '@/services/utils'
import localeEn from 'element-ui/lib/locale/lang/en'
/*
TODO: After moving to Element Plus, this component should be changed for the
 respective slot only available there.
 Check https://gitlab.com/stitch3d/cit/-/issues/225
*/
import ElementUploadList from 'element-ui/packages/upload/src/upload-list'

export default {
  name: 'InputFile',

  components: {
    ElementUploadList
  },

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    inputKey: {
      type: String,
      required: true,
      default: 'file'
    },
    parameters: {
      type: Object,
      required: true
    },
    defaultValue: {
      type: Array,
      required: false,
      default: null
    }
  },

  data () {
    return {
      fileList: [],
      thumbnailPreview: null
    }
  },

  computed: {
    /**
     * @returns {number}
     */
    id () {
      return this.parameters.id
    },

    /**
     * @returns {boolean}
     */
    canAcceptMultipleFiles () {
      return this.parameters.multipleFiles
    },

    /**
     * @returns {string}
     */
    acceptedExtensionsForInput () {
      const extensions = this.parameters.extensions

      return extensions ? extensions.join(',') : ''
    },

    /**
     * @returns {string}
     */
    acceptedExtensionsToDisplay () {
      const extensions = [...this.parameters.extensions]

      const index = extensions.indexOf('.csv')

      if (index > -1) {
        extensions.splice(index, 1)
      }

      return extensions.join(', ')
    },

    /**
     * @returns {boolean}
     */
    acceptCSV () {
      return this.parameters.extensions.includes('.csv')
    },

    /**
     * @returns {boolean}
     */
    isThumbnail () {
      return this.parameters.id.includes('thumbnail')
    },

    /**
     * @returns {object}
     */
    preview () {
      return this.parameters.preview
    },

    /**
     * @returns {string}
     */
    name () {
      return this.parameters.name || 'file'
    },

    /**
     * @returns {object}
     */
    rules () {
      return {
        required: this.parameters.required,
        message: `Please choose ${this.parameters.name}`,
        trigger: 'change'
      }
    },

    /**
     * @returns {boolean}
     */
    editing () {
      return Boolean(this.defaultValue)
    },

    /**
     * @returns {object}
     */
    filesGroupedByExtension () {
      return this.fileList.reduce((acc, file) => {
        if (!acc[file.extension]) {
          acc[file.extension] = []
        }

        acc[file.extension].push(file)

        return acc
      }, {})
    }
  },

  created () {
    this.initInput()
  },

  mounted () {
    // ElementUploadList is falling back to chinese
    if (this.$refs.uploadList) {
      this.$refs.uploadList.forEach(item => {
        item.t = () => localeEn.el.upload.deleteTip
      })
    }
  },

  methods: {
    /**
     */
    initInput () {
      if (!this.defaultValue) {
        return
      }

      this.fileList = this.defaultValue.map(file => {
        let { id, name, description, extension, assets_url: raw } = file

        let url = ''

        if (typeof raw === 'string') {
          const assetsUrl = new URL(raw)
          const fileName = assetsUrl.pathname.split('/').pop()
          name = Utils.getFileNameWithoutExtension(fileName)
          extension = extension || Utils.getExtensionFromFileName(fileName)
          url = raw
        }

        return {
          id,
          name,
          description,
          raw,
          url,
          extension
        }
      })

      if (this.preview) {
        this.thumbnailPreview =
          this.fileList.length > 0 ? this.fileList[0].url : null
      }

      this.setData()
    },

    /**
     * @param {object}   file
     * @param {object[]} fileList
     */
    onChange: _debounce(
      /**
       * @param {File}     file
       * @param {FileList} fileList
       */
      function (file, fileList) {
        fileList.forEach(singleFile => {
          if (singleFile.id) {
            const singleFileName = singleFile.url || singleFile.raw
            singleFile.extension =
              singleFile.extension ||
              Utils.getExtensionFromFileName(singleFileName)
          } else {
            singleFile.extension =
              singleFile.extension ||
              Utils.getExtensionFromFileName(singleFile.name)
            singleFile.description =
              singleFile.description ||
              Utils.getDescriptionFromFileName(singleFile.name)
            singleFile.name = singleFile.description
          }
        })

        if (!this.editing) {
          // Normal flow for first upload form
          const extensionList = this.parameters.extensions
          fileList = fileList.filter(singleFile => {
            return extensionList.some(
              extension => `.${singleFile.extension}` === extension
            )
          })

          if (fileList.length === 0) {
            this.$message.error(`You need to have a ${this.name}`)
          }
        }

        if (this.preview) {
          this.thumbnailPreview = URL.createObjectURL(file.raw)
        }

        if (this.canAcceptMultipleFiles) {
          this.fileList = fileList
        } else {
          this.fileList = fileList.slice(-1)
        }

        this.setData()
      },
      50
    ),

    /**
     */
    setData () {
      const value = this.fileList
      value.type = this.parameters.type
      this.$emit('set-data', {
        key: this.inputKey,
        value,
        relatedField: this.parameters.relatedField
      })
    },

    /**
     * @param {object} uploaderFile
     */
    removeFile (uploaderFile) {
      const index = this.fileList.indexOf(uploaderFile)
      this.fileList.splice(index, 1)

      this.onChange(uploaderFile, this.fileList)
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/utils';

.form-generator__content-image {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0 auto;
}

.form-generator__upload-input {
  display: flex;
  justify-content: center;

  .el-upload-dragger {
    @include flex-center;

    flex-direction: column;
    background-color: $blue-alpha-20;
    border-color: $blue-light;
  }

  ul {
    width: $input-width;
  }
}

.form-generator__upload-input-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form-generator__upload-list-section {
  margin-top: spacing(1.25);
  font-weight: $font-weight-bold;
  text-transform: capitalize;
}

// To remove when backend handles the auto replacement of the vsp file when updating to a new file
.form-generator__limit-multiple-list {
  li:first-child {
    margin-top: 0;
  }
}
</style>

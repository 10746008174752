import VueTypes from 'vue-types'

/**
 * @type     {object}
 * @property {string}  [created_at]
 * @property {string}  [file]
 * @property {string}  [file_url]
 * @property {number}  id
 * @property {boolean} [is_thumbnail]
 * @property {number}  [optionId]
 * @property {string}  [updated_at]
 */
const ImageShape = VueTypes.shape({
  created_at: VueTypes.string,
  file: VueTypes.string,
  file_url: VueTypes.string,
  id: VueTypes.number.isRequired,
  is_thumbnail: VueTypes.bool,
  optionId: VueTypes.number,
  updated_at: VueTypes.string
})

/**
 * @type     {object}
 * @property {number}       id
 * @property {ImageShape[]} images
 * @property {ImageShape[]} slideshowImages
 * @property {boolean}      isConverted
 * @property {string}       name
 * @property {string}       thumbnail
 * @property {number}       versionId
 * @property {string}       zipUrl
 */
const OptionShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  images: VueTypes.arrayOf(ImageShape.loose),
  slideshowImages: VueTypes.arrayOf(ImageShape.loose),
  isConverted: VueTypes.bool.def(true),
  name: VueTypes.string.isRequired,
  thumbnail: VueTypes.string,
  versionId: VueTypes.number.isRequired,
  zipUrl: VueTypes.string
})

/**
 * @type     {object}
 * @property {number}       id
 * @property {ImageShape[]} images
 * @property {boolean}      isConverted
 * @property {string}       name
 * @property {string}       thumbnail
 * @property {number}       versionId
 * @property {string}       zipUrl
 */
const ViewShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  file: VueTypes.string.isRequired,
  fileUrl: VueTypes.string.isRequired,
  isThumbnail: VueTypes.bool,
  versionId: VueTypes.number.isRequired,
  viewName: VueTypes.string
})

const BlockOptionShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  images: VueTypes.arrayOf(ViewShape),
  isConverted: VueTypes.bool.def(true),
  name: VueTypes.string.isRequired,
  thumbnail: VueTypes.string,
  versionId: VueTypes.number.isRequired,
  zipUrl: VueTypes.string,
  slideshowImages: VueTypes.arrayOf(ImageShape.loose)
})

/**
 * @type     {object}
 * @property {string}        [createdAt]
 * @property {number}        id
 * @property {boolean}       [isConverted]
 * @property {boolean}       [isMain]
 * @property {(string|null)} [model]
 * @property {(string|null)} [modelUrl]
 * @property {string}        [name]
 * @property {OptionShape[]} [options]
 * @property {number}        [styleId]
 * @property {string}        [zipUrl]
 */
const VersionShape = VueTypes.shape({
  createdAt: VueTypes.string,
  // id is not required if fakeId provided
  id: VueTypes.number,
  isConverted: VueTypes.bool.def(true),
  isMain: VueTypes.bool,
  model: VueTypes.oneOfType([String, null]),
  modelUrl: VueTypes.oneOfType([String, null]),
  name: VueTypes.string,
  options: VueTypes.arrayOf(OptionShape.loose),
  styleId: VueTypes.number,
  zipUrl: VueTypes.string
})

/**
 * @type     {object}
 * @property {string}        [createdAt]
 * @property {number}        id
 * @property {boolean}       [isConverted]
 * @property {boolean}       [isMain]
 * @property {(string|null)} [model]
 * @property {(string|null)} [modelUrl]
 * @property {string}        [name]
 * @property {OptionShape[]} [options]
 * @property {number}        [blockId]
 * @property {string}        [zipUrl]
 */
const BlockVersionShape = VueTypes.shape({
  createdAt: VueTypes.string,
  // id is not required if fakeId provided
  id: VueTypes.number,
  isConverted: VueTypes.bool.def(true),
  isMain: VueTypes.bool,
  model: VueTypes.oneOfType([String, null]),
  modelUrl: VueTypes.oneOfType([String, null]),
  name: VueTypes.string,
  views: VueTypes.arrayOf(ViewShape.loose),
  blockId: VueTypes.number,
  zipUrl: VueTypes.string
})

export {
  OptionShape,
  VersionShape,
  ViewShape,
  BlockOptionShape,
  BlockVersionShape
}

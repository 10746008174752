export const SORT_OPTION = {
  NEWEST_FIRST: 'newest_first',
  OLDEST_FIRST: 'oldest_first',
  NAME: 'name',
  LAST_MODIFIED: 'last_modified',
  FIRST_MODIFIED: 'first_modified'
}

export const SORT_QUERY_STRING = {
  SORT_BY_NAME: 'name',
  SORT_BY_CREATED_AT: 'created_at',
  SORT_BY_UPDATED_AT: 'updated_at',
  ORDER_BY_ASC: 'ASC',
  ORDER_BY_DESC: 'DESC'
}

export const SORT_QUERY = {
  [SORT_OPTION.NEWEST_FIRST]: {
    sortBy: SORT_QUERY_STRING.SORT_BY_CREATED_AT,
    order: SORT_QUERY_STRING.ORDER_BY_DESC
  },
  [SORT_OPTION.OLDEST_FIRST]: {
    sortBy: SORT_QUERY_STRING.SORT_BY_CREATED_AT,
    order: SORT_QUERY_STRING.ORDER_BY_ASC
  },
  [SORT_OPTION.NAME]: {
    sortBy: SORT_QUERY_STRING.SORT_BY_NAME,
    order: SORT_QUERY_STRING.ORDER_BY_ASC
  },
  [SORT_OPTION.LAST_MODIFIED]: {
    sortBy: SORT_QUERY_STRING.SORT_BY_UPDATED_AT,
    order: SORT_QUERY_STRING.ORDER_BY_DESC
  },
  [SORT_OPTION.FIRST_MODIFIED]: {
    sortBy: SORT_QUERY_STRING.SORT_BY_UPDATED_AT,
    order: SORT_QUERY_STRING.ORDER_BY_ASC
  }
}

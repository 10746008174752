import Utils from '@/services/utils'

/**
 * @param   {object} payload
 *
 * @returns {object}
 */
const copyFileDictToMainObject = payload => {
  if (!payload) {
    return null
  }

  return {
    ...payload,
    ...payload.filesDict
  }
}

/**
 * @param   {object[]} currentStepFields
 * @param   {object[]} formData
 * @param   {boolean}  isCreation
 *
 * @returns {object[]}
 */
const parseFieldsForRelatedData = (currentStepFields, formData, isCreation) => {
  // Filter fields that are not available on creation or edition
  const activeCurrentStepFields = currentStepFields.filter(field => {
    if (isCreation && field.availableOnCreation === false) {
      return false
    }

    if (isCreation === false && field.availableOnEdit === false) {
      return false
    }

    return true
  })

  // Generate more fields for the related data
  const processedStepFields = activeCurrentStepFields.reduce(
    (accumulator, field) => {
      if (!field.relatedField) {
        accumulator.push(field)

        return accumulator
      }

      const relatedDataStep = formData.find(stepData => {
        return stepData[field.relatedField.id]
      })

      if (!relatedDataStep) {
        accumulator.push(field)

        return accumulator
      }

      const relatedField = relatedDataStep[field.relatedField.id]
      const fieldsToAdd = relatedField.map((relatedFieldData, index) => {
        // temporary id for field validation (can't have the validation on previous step)
        // The data is removed in the processFormData function
        return {
          ...field,
          name: relatedFieldData[field.relatedField.propertyAffected],
          id: `temporary-${field.id}-${index}`,
          defaultValue: relatedFieldData[field.relatedField.propertyAffected],
          relatedField: {
            ...field.relatedField,
            index
          }
        }
      })

      accumulator = [...accumulator, ...fieldsToAdd]

      return accumulator
    },
    []
  )

  return processedStepFields
}

/**
 * Validates the form, then calls the submitPartialMethod
 * on the backend with the current data.
 * If there is no submitPartialMethod, the form goes to the next step
 *
 * @callback successCallback
 *
 * @param   {object}            payload
 * @param   {object}            payload.formConfiguration
 * @param   {object}            payload.currentStepMetaData
 * @param   {Store}             payload.store
 * @param   {object}            payload.processedItemData
 * @param   {boolean}           [payload.valid=false]
 * @param   {successCallback[]} [payload.asyncSuccessCallbacks=[]]
 *
 * @returns {Error | object}                                       {error | updatedData}
 */
const validateStep = async ({
  formConfiguration,
  currentStepMetaData,
  store,
  processedItemData,
  valid = false,
  asyncSuccessCallbacks = []
}) => {
  const results = {}

  if (valid) {
    if (currentStepMetaData.submitPartial) {
      try {
        if (currentStepMetaData.async) {
          store.dispatch(
            currentStepMetaData.submitPartialMethod,
            processedItemData
          )

          asyncSuccessCallbacks.forEach(callback => {
            callback()
          })
        } else {
          results.updatedData = await store.dispatch(
            currentStepMetaData.submitPartialMethod,
            processedItemData
          )
        }

        return results
      } catch (error) {
        if (error.response) {
          results.error = Utils.processError(
            error.response.data,
            formConfiguration
          )

          return results
        } else {
          throw error
        }
      }
    }
  }

  return results
}

/**
 * @param   {object}         payload
 * @param   {boolean | null} [payload.forceFormCompletion=null]
 * @param   {string | null}  [payload.stepIdToGo=null]
 * @param   {number | null}  [payload.stepIdToGoIndex=null]
 * @param   {number}         payload.currentStepIndex
 * @param   {object[]}       payload.validSteps
 *
 * @returns {boolean}
 */
const hasReachedFormEnd = ({
  forceFormCompletion = null,
  stepIdToGo = null,
  stepIdToGoIndex = null,
  currentStepIndex,
  validSteps
}) => {
  if (
    forceFormCompletion === true ||
    currentStepIndex >= validSteps.length - 1
  ) {
    return true
  }

  // if the next step is not editable (style file upload when editing)
  if (stepIdToGo && stepIdToGoIndex === -1) {
    return true
  }

  return false
}

/**
 * @param   {object}        payload
 * @param   {number}        payload.currentStepIndex
 * @param   {object[]}      payload.formData
 * @param   {object[]}      payload.originalFormData
 * @param   {object}        payload.defaultData
 * @param   {number}        payload.currentFieldId
 * @param   {object | null} payload.defaultValue
 * @param   {*}             payload.forcedValue
 *
 * @returns {*}
 */
const getDefaultValue = ({
  currentStepIndex,
  formData,
  originalFormData,
  defaultData,
  currentFieldId,
  defaultValue,
  forcedValue
}) => {
  if (formData[currentStepIndex][currentFieldId]) {
    return formData[currentStepIndex][currentFieldId]
  }

  if (forcedValue !== undefined) {
    return forcedValue
  }

  // To handle 0 as a value
  if (
    originalFormData[currentStepIndex][currentFieldId] !== undefined &&
    originalFormData[currentStepIndex][currentFieldId] !== null
  ) {
    return originalFormData[currentStepIndex][currentFieldId]
  }

  if (defaultData && defaultData[currentFieldId] !== undefined) {
    return defaultData[currentFieldId]
  }

  if (!defaultValue) {
    return null
  }

  if (!defaultValue.id) {
    return defaultValue
  }

  let foundDefaultValue = null

  formData.forEach(step => {
    const foundFieldKey = Object.keys(step).find(key =>
      key.includes(defaultValue.id)
    )

    if (foundFieldKey) {
      if (Array.isArray(step[foundFieldKey])) {
        // Always take the first item for the default value if multiple
        // i.e. multiple file upload -> name of item
        foundDefaultValue = step[foundFieldKey][0][defaultValue.property]
      } else {
        foundDefaultValue = step[foundFieldKey][defaultValue.property]
      }
    }
  })

  return foundDefaultValue
}

const formMethods = {
  parseFieldsForRelatedData,
  validateStep,
  hasReachedFormEnd
}
const dataMethods = {
  copyFileDictToMainObject,
  getDefaultValue
}

const FormServices = {
  ...dataMethods,
  ...formMethods
}

export default FormServices

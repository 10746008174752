var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainFileDropArea",staticClass:"detail-version-list",on:{"drop":function($event){$event.preventDefault();return _vm.handleMainDrop($event)},"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.dragLeave.apply(null, arguments)}}},[_c('div',{staticClass:"detail-version-list__add-version"},[_c('ElementButton',{attrs:{"type":"text"},on:{"click":function($event){return _vm.addNewVersion()}}},[_vm._v(" Add New Version ")])],1),_c('div',{staticClass:"detail-version-list__versions"},_vm._l((_vm.versions),function(version){return _c('ElementTooltip',{key:version.id,ref:"versionsTooltips",refInFor:true,attrs:{"effect":"dark","placement":"bottom","transition":"none","enterable":false,"disabled":_vm.getRenderStatusTooltipOptions(version).disabled}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.getRenderStatusTooltipOptions(version)
            .tooltipMessage),function(message,index){return _c('p',{key:index,ref:"versionTooltip",refInFor:true},[_vm._v(" "+_vm._s(message)+" ")])}),0),_c('div',{ref:("version-" + (version.id)),refInFor:true,class:[
          'detail-version-list__version',
          {
            'detail-version-list__version--active':
              _vm.activeVersion && version.id === _vm.activeVersion.id
          }
        ],on:{"click":function($event){return _vm.handleClick(version)}}},[_c('DetailThumbnailLoader',{attrs:{"loading-state":_vm.getThumbnailLoadingState(version),"src":_vm.buildVersionThumbnailUrl(version)}}),_c('div',{staticClass:"detail-version-list__version-contributor"},[_c('p',{staticClass:"detail-version-list__version-name"},[_vm._v(" "+_vm._s(version.name)+" ")]),(version.contributor && version.contributor.email)?_c('p',{staticClass:"detail-version-list__version-email"},[_vm._v(" "+_vm._s(version.contributor.email)+" ")]):_vm._e(),(version.createdAt)?_c('p',{staticClass:"detail-version-list__version-date"},[_vm._v(" "+_vm._s(_vm.formatDateShort(version.createdAt))+" . Rendered with "+_vm._s(_vm.RENDER_ENGINE_TYPE[version.renderEngine])+" ")]):_vm._e()]),_c('ElementTooltip',{attrs:{"placement":"bottom","content":_vm.tooltipMessage()}},[(version.isMain && !version.isUploading)?_c('div',{class:[
              'detail-version-list__version-main-check',
              'el-icon-circle-check'
            ]}):_vm._e()]),_c('ElementTooltip',{attrs:{"placement":"bottom","content":_vm.rerenderJobMessage()}},[(_vm.isJobReadyToBeRerendered(version))?_c('div',{class:[
              'detail-version-list__version-main-rerender',
              'el-icon-refresh-left'
            ],on:{"click":function($event){return _vm.rerenderConfirmation(version.id, version.name)}}}):_vm._e()]),(!version.isUploading)?_c('StitchDropdown',{attrs:{"placement":_vm.DROPDOWN_PLACEMENT.BOTTOM_END,"dropdown-items":_vm.getDropdownItems(version),"icon-type":_vm.iconType,"append-to-body":false}}):_vm._e()],1)])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }
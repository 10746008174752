<template>
  <div
    ref="mainListDropZone"
    class="library-items"
    @drop.prevent="handleFileDrop($event)"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
  >
    <!-- Need an if condition to rerender the component when new list -->
    <!-- Or else the infinite scroll is not reseted -->
    <div
      v-if="libraryItemsCount"
      class="library-items__count"
      data-testid="itemsCount"
    >
      {{ libraryItemsCount }}
      {{ libraryItemsCount === 1 ? 'result' : 'results' }}
    </div>

    <div
      v-if="items.length > 0"
      ref="itemListScrollable"
      v-infinite-scroll="scrollHitBottom"
      class="library-items__scroll"
      infinite-scroll-distance="200"
    >
      <div class="library-items__content">
        <LibraryCardItem
          v-for="(item, index) in processedItems"
          :key="index"
          :processed-item="item"
          @delete-item="onDeleteItem"
        />
      </div>
      <StitchLoader v-if="loading" />
    </div>
    <!-- Need 2 loaders 1 in the list that is used when it's displayed -->
    <!-- And 1 when the list is empty (before feeding the list) -->
    <StitchLoader v-if="items.length === 0 && loading" />
    <ElementAlert
      v-if="items.length === 0 && !loading"
      :title="
        libraryType !== LIBRARY_TYPE.JOB
          ? `There is no ${libraryType} matching your request`
          : 'No Jobs'
      "
      type="info"
      center
      :closable="false"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'

import { LIBRARY_TYPE } from '@/constants/libraryType'
import { mapGetters, mapActions } from 'vuex'

import { UPLOAD_STATUS } from '@/constants/loadingStatus'
import Utils from '@/services/utils'
import VueTypes from 'vue-types'
import { ItemShape } from '@/types'
import LibraryCardItem from './components/LibraryCardItem'

export default {
  name: 'LibraryItems',

  components: {
    LibraryCardItem
  },

  props: {
    items: VueTypes.arrayOf(ItemShape.loose),
    libraryType: VueTypes.string.def(LIBRARY_TYPE.BLOCK),
    downloadCallback: VueTypes.func.def(() => {}),
    loading: VueTypes.bool
  },

  data () {
    return {
      LIBRARY_TYPE
    }
  },

  computed: {
    ...mapGetters([
      'getItemUploadStatuses',
      'getJobStatuses',
      'getHasItemsReachedEnd',
      'getHasJobsReachedEnd',
      'getHasToScrollToTop',
      'getPagingItems'
    ]),

    /**
     * @returns {boolean}
     */
    hasReachedEnd () {
      if (this.libraryType === LIBRARY_TYPE.JOB) {
        return this.getHasJobsReachedEnd
      } else {
        return this.getHasItemsReachedEnd()
      }
    },

    /**
     * @returns {Array}
     */
    statuses () {
      if (this.libraryType === LIBRARY_TYPE.JOB) {
        return this.getJobStatuses
      } else {
        return this.getItemUploadStatuses
      }
    },

    /**
     * @returns {Array}
     */
    processedItems () {
      const processedItems = this.items.map(item => {
        const hasModel3D =
          item.filesDict &&
          item.filesDict.model3D &&
          item.filesDict.model3D.length > 0

        return {
          ...item,
          status: this.statuses[item.id] || null,
          hasModel3D: hasModel3D,
          downloadCallback: this.downloadCallback || null
        }
      })

      let index = processedItems.length - 1
      const pendingArray = []
      while (index >= 0) {
        if (processedItems[index].status === UPLOAD_STATUS.PENDING) {
          const item = processedItems.splice(index, 1)
          pendingArray.push(...item)
        }

        index--
      }

      return [...pendingArray, ...processedItems]
    },

    /**
     * @returns {boolean}
     */
    hasToScrollToTop () {
      return this.getHasToScrollToTop()
    },

    /**
     * @returns {number}
     */
    libraryItemsCount () {
      return this.getPagingItems().itemsCount
    }
  },

  watch: {
    /**
     * @param {boolean} newValue
     * @param {boolean} oldValue
     */
    hasToScrollToTop (newValue, oldValue) {
      if (newValue === true) {
        this.$nextTick().then(() => {
          // needed when there is no item on the list
          this.scrollToTop()
        })
      }
    }
  },

  methods: {
    ...mapActions(['deleteJob', 'deleteItem', 'resetItemListScrollTop']),

    /**
     * @param {Event} event
     */
    handleFileDrop (event) {
      this.dragLeave()

      const isFile = event.dataTransfer.types.includes('Files')

      if (this.libraryType === LIBRARY_TYPE.STYLE) {
        if (isFile && event.dataTransfer.files) {
          this.$emit('files-dropped', event.dataTransfer.files)
        }
      }
    },

    /**
     * @param   {object} fileList
     *
     * @returns {Array}
     */
    generateFileList (fileList) {
      return fileList.map(item => ({
        name: item.name,
        extension: Utils.getExtensionFromFileName(item.name),
        description: Utils.getDescriptionFromFileName(item.name),
        size: item.size,
        raw: item
      }))
    },

    /**
     */
    dragOver () {
      const mainImageDropZone = this.$refs.mainListDropZone
      mainImageDropZone.classList.add('dragging')
    },

    /**
     */
    dragLeave () {
      const mainImageDropZone = this.$refs.mainListDropZone
      mainImageDropZone.classList.remove('dragging')
    },

    /**
     * @param {object} payload
     */
    async onDeleteItem (payload) {
      const { id, name, libraryType } = payload

      if (libraryType === LIBRARY_TYPE.JOB) {
        await this.deleteJob(id)
      } else {
        await this.deleteItem(payload)
      }

      this.$message({
        showClose: true,
        message: `${name} has been deleted`
      })
    },

    /**
     */
    scrollHitBottom: _debounce(
      /**
       */
      function () {
        if (!this.hasReachedEnd) {
          this.$emit('scroll-hit-bottom')
        }
      },
      400
    ),

    /**
     */
    scrollToTop () {
      if (this.$refs.itemListScrollable) {
        this.$refs.itemListScrollable.scrollTop = 0
      }

      this.resetItemListScrollTop()
    }
  }
}
</script>

<style lang="scss" scoped>
$list-height: calc(100% - #{$content-header-height});

.library-items {
  width: 100%;
  height: $list-height;
}

.library-items__scroll {
  max-height: 100%;
  overflow: auto;
}

.library-items__scroll::-webkit-scrollbar {
  display: none;
}

.library-items__content {
  display: grid;
  // For rendering inside plugin because Chrome v58 doesn't accept colum-gap and row-gap properties
  grid-gap: spacing(2);
  grid-template-columns: repeat(auto-fill, minmax(spacing(22), 1fr));
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  padding-bottom: spacing(12.5);
}

.library-items__count {
  @include text-body;

  margin: 0 0 spacing(4) spacing(1/4);
  color: $grey;
}

.dragging {
  background-color: $blue-light;
}
</style>

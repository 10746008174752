import { render, staticRenderFns } from "./InputTags.vue?vue&type=template&id=86ad1f12&scoped=true&"
import script from "./InputTags.vue?vue&type=script&lang=js&"
export * from "./InputTags.vue?vue&type=script&lang=js&"
import style0 from "./InputTags.vue?vue&type=style&index=0&id=86ad1f12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86ad1f12",
  null
  
)

export default component.exports
import { loadImage } from '@/services/utils'

const COLOR_RUN_LENGTH_MAX = 8
const COLOR_RUN_PLACEHOLDER_URL = require('../assets/images/img-placeholder.svg')

/**
 * @param   {string[]} imageUrls
 *
 * @returns {string[]}
 */
export const getColorRunFormatted = imageUrls => {
  const colorRun = [...imageUrls]

  if (colorRun.length === 0) {
    colorRun.push(COLOR_RUN_PLACEHOLDER_URL)
  } else if (colorRun.length > COLOR_RUN_LENGTH_MAX) {
    colorRun.length = COLOR_RUN_LENGTH_MAX
  }

  return colorRun
}

/**
 * @param   {object[]}      images
 * @param   {object}        containerSize
 * @param   {boolean}       isContainerSizeFixed
 *
 * @returns {object | null}
 */
export const getColorRunImageParameters = (
  images,
  containerSize,
  isContainerSizeFixed
) => {
  const imageSizes = images.map(image => ({
    width: image.width,
    height: image.height
  }))
  const imageSizeMax = getImageSizeMax(imageSizes)

  if (imageSizeMax && imageSizeMax.width) {
    return calculateColorRunImageParameters(
      containerSize,
      imageSizeMax,
      imageSizes,
      isContainerSizeFixed
    )
  }

  return null
}

/**
 * @param   {object[]} sizes
 *
 * @returns {object}
 */
export const getImageSizeMax = sizes => {
  return sizes.reduce((sizeMax, size) => {
    const oldRatio = sizeMax.width ? sizeMax.width / sizeMax.height : null
    const newRatio = size.width / size.height

    if (oldRatio && oldRatio > newRatio) {
      return sizeMax
    } else {
      return size
    }
  }, {})
}

/**
 * @param   {object}   containerSize
 * @param   {number}   containerSize.width
 * @param   {number}   containerSize.height
 * @param   {object}   imageSize
 * @param   {number}   imageSize.width
 * @param   {number}   imageSize.height
 * @param   {object[]} imageSizes
 *
 * @returns {object}
 */
export const calculateColorRunImageParameters = (
  { width: containerWidth, height: containerHeight },
  { width: imageWidthMax, height: imageHeightMax },
  imageSizes
) => {
  const colorRunLength = imageSizes.length
  const ratio = imageWidthMax / imageHeightMax
  const offsetPercentage = 0.5

  if (imageHeightMax > containerHeight) {
    imageWidthMax = imageWidthMax * (containerHeight / imageHeightMax)
  }

  let offsetPerImage = imageWidthMax * offsetPercentage

  if (colorRunLength === 1) {
    offsetPerImage = 0
  }

  imageHeightMax = imageWidthMax / ratio

  const imageSizesScaled = imageSizes.map(imageSize => ({
    width: (imageSize.width / imageSize.height) * imageHeightMax,
    height: imageHeightMax
  }))

  return {
    offsetPerImage,
    imageSizes: imageSizesScaled
  }
}

/**
 * @param   {string[]}        imageUrls
 * @param   {AbortController} abortController
 *
 * @returns {Promise[]}
 */
export const getImagePromises = (imageUrls, abortController) => {
  const imagePromises = imageUrls.map(url => loadImage(url, abortController))

  return Promise.all(imagePromises)
}

export default {
  getColorRunFormatted,
  getColorRunImageParameters,
  getImagePromises
}

import store from '@/store/store'
import { USER_ROLE } from '@/constants/roleType'

/**
 * @returns {boolean}
 */
export const canUserAccessUserManagement = () => {
  const user = store.getters.getCognitoUserData

  return user.is_hub_admin || user.can_manage_users
}

/**
 * @returns {boolean}
 */
export const isVendorUser = () => {
  const user = store.getters.getCognitoUserData

  return user.role === USER_ROLE.VENDOR.name
}

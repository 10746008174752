import TrackingInterface from './TrackingInterface'

/**
 * @param {Vue}    Vue
 * @param {object} ids
 * @param {object} config
 */
const install = (Vue, ids = {}, config = {}) => {
  Vue.prototype.$tracking = Vue.tracking = new TrackingInterface(ids, config)
}

export default {
  install
}

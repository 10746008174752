// ////////////////////////////////////////////////////
// Mutations (ROOT)
// ////////////////////////////////////////////////////

/**
 * @param {Store.state} state
 * @param {Route}       route
 */
const setRoute = (state, route) => {
  state.route = route
}

/**
 * @param {Store.state} state
 * @param {object}      cognitoUserData
 * @param {number}      cognitoUserData.id
 * @param {string}      cognitoUserData.username
 * @param {string}      cognitoUserData.email
 * @param {string}      cognitoUserData.api_key
 * @param {string}      cognitoUserData.permissions
 * @param {Array}       cognitoUserData.teams
 */
const setCognitoUserData = (state, cognitoUserData) => {
  state.cognitoUserData = cognitoUserData
}

/**
 * @param {Store.state} state
 * @param {boolean}     isNotFound
 */
const setIsNotFound = (state, isNotFound) => {
  state.isNotFound = isNotFound
}

/**
 * @param {Store.state} state
 * @param {TeamShape}   team
 */
const setActiveTeam = (state, team) => {
  state.activeTeam = team
}

/**
 * @param {Store.state} state
 * @param {object}      workspace
 */
const setActiveWorkspace = (state, workspace) => {
  state.activeWorkspace = workspace
  localStorage.setItem('activeWorkspace', workspace.code)
}

/**
 *
 * @param {Store.state} state
 * @param {boolean}     isDarkMode
 */
const setIsDarkMode = (state, isDarkMode) => {
  state.isDarkMode = isDarkMode
}

const mutations = {
  setRoute,
  setCognitoUserData,
  setIsNotFound,
  setActiveTeam,
  setActiveWorkspace,
  setIsDarkMode
}

export default mutations

import VueTypes from 'vue-types'

export const InputMixin = {
  props: {
    inputKey: {
      type: String,
      required: true
    },
    parameters: {
      type: Object,
      required: true
    },
    isDisabled: VueTypes.bool.def(false)
  },

  data () {
    return {
      inputData: null
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    name () {
      return this.parameters.name || 'input label'
    },

    /**
     * @returns {object}
     */
    rules () {
      return {
        required: this.parameters.required,
        message: `Please select ${this.name}`,
        trigger: 'change'
      }
    },

    /**
     * @returns {Array}
     */
    notes () {
      return this.parameters.notes || []
    },

    /**
     * @returns {string}
     */
    disabledMessage () {
      return this.parameters.disabledMessage
    }
  },

  created () {
    this.initInput()
  },

  methods: {
    /**
     */
    setData () {
      this.$emit('set-data', {
        key: this.inputKey,
        value: this.inputData,
        relatedField: this.parameters.relatedField,
        trigger: this.parameters.trigger
      })
    }
  }
}

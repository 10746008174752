<template>
  <div class="style-comments-header">
    <h2 ref="commentsHeaderTitle">
      Style Comments
    </h2>
    <div
      ref="commentsHeaderSubtitle"
      class="style-comments-header__subtitle"
    >
      Drop a comment on this style.
    </div>
    <div class="style-comments-header__filters">
      <StyleCommentsFilter class="style-comments-header__comments-filter" />
    </div>
  </div>
</template>

<script>
import StyleCommentsFilter from './StyleCommentsFilter'

export default {
  name: 'StyleCommentsHeader',

  components: {
    StyleCommentsFilter
  }
}
</script>

<style lang="scss" scoped>
.style-comments-header {
  display: flex;
  flex-direction: column;
  padding: spacing(4) spacing(4) spacing(1) spacing(4);
}

.style-comments-header__filters {
  margin-top: spacing(1);
}

.style-comments-header__comments-filter {
  max-width: spacing(21);
  margin-right: spacing(1);
}

.style-comments-header__subtitle {
  @include text-label;

  padding-bottom: spacing(1);
}
</style>

<template>
  <ElementSelect
    ref="commentsFilter"
    :value="activeFilter"
    placeholder="Filter by..."
    size="small"
    @change="changeFilter"
  >
    <ElementOption
      v-for="(filter, key) in filteringOptions"
      :key="key"
      :label="filter.label"
      :value="key"
    />
  </ElementSelect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EVENT_FILTER_TYPE } from '@/constants/filterType'

export default {
  name: 'StyleCommentsFilter',

  data () {
    return {
      filteringOptions: {
        [EVENT_FILTER_TYPE.NONE]: {
          label: 'See all comments'
        },
        [EVENT_FILTER_TYPE.OWNER]: {
          label: 'Only my own comments'
        },
        [EVENT_FILTER_TYPE.CREATORS]: {
          label: "Only creators' comments"
        },
        [EVENT_FILTER_TYPE.VIEWERS]: {
          label: "Only viewers' comments"
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      activeFilter: 'getStyleEventsFilter'
    })
  },

  methods: {
    ...mapActions(['fetchStyleEvents']),

    /**
     * @param {string} filter
     */
    changeFilter (filter) {
      this.fetchStyleEvents({ filterBy: filter })
    }
  }
}
</script>

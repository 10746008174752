import RenderFarmMasterAPI from '@/services/renderFarmMasterAPI'
import { SORT_QUERY } from '@/constants/sortOption'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'

// ////////////////////////////////////////////////////
// Actions  (JOB)
// ////////////////////////////////////////////////////

/**
 * @param   {Store}         context
 * @param   {Store.commit}  context.commit
 * @param   {Store.getters} context.getters
 *
 * @returns {object[]}
 */
const fetchFilteredJobs = async ({ commit, getters }) => {
  const search = getters.getJobSearch
  const paging = getters.getPagingJobs
  const page = paging.currentPage
  const limit = paging.itemsLimit
  const sorting = getters.getSortingJobs
  const sortingParameters = SORT_QUERY[sorting]
  const jobs = await RenderFarmMasterAPI.fetchAllJobs({
    search,
    page,
    limit,
    sortingParameters
  })

  if (jobs && jobs.length === 0) {
    commit('setHasJobsReachedEnd', true)
  } else if (jobs !== null) {
    commit('setJobsUploadStatus', jobs)
    commit('setJobs', jobs)

    // Always need to be sorted by date desc on first call (to get the latest ID)
    let latestJobId = getters.getLatestJobId
    jobs.forEach(job => {
      if (job.id > latestJobId) {
        latestJobId = job.id
      }
    })
    commit('setLatestJobId', latestJobId)
  }

  return jobs
}

/**
 * @param   {Store}        context
 * @param   {Store.commit} context.commit
 * @param   {string}       jobId
 *
 * @returns {object}
 */
const fetchJobDetail = async ({ commit }, jobId) => {
  commit('resetJobDetail')
  const job = await RenderFarmMasterAPI.fetchJobDetail(jobId)
  commit('setJobDetail', job)

  return job
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {number}       jobId
 */
const deleteJob = async ({ commit, dispatch }, jobId) => {
  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.RENDER,
    action: TRACKER_EVENTS.DELETED,
    item: jobId,
    libraryType: LIBRARY_TYPE.JOB
  })

  await RenderFarmMasterAPI.deleteJob(jobId)
  commit('deleteJob', jobId)
}

/**
 * @param   {Store}        context
 * @param   {Store.commit} context.commit
 * @param   {number}       jobId
 *
 * @returns {object}                      job
 */
const updateJobStatus = async ({ commit }, jobId) => {
  const job = await RenderFarmMasterAPI.fetchJobDetail(jobId)
  commit('setJobStatus', job)
  commit('updateJob', job)

  return job
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 */
const fetchNewJobs = async ({ commit, getters, dispatch }) => {
  const search = getters.getJobSearch
  let latestJobId = getters.getLatestJobId
  const newJobs = await RenderFarmMasterAPI.fetchNewJobs(latestJobId, search)
  commit('prependNewJobs', newJobs)

  newJobs.forEach(job => {
    if (job.id > latestJobId) {
      latestJobId = job.id

      dispatch('trackEvent', {
        object: TRACKER_OBJECTS.RENDER,
        action: TRACKER_EVENTS.ADDED,
        item: job,
        value: job.id,
        libraryType: LIBRARY_TYPE.JOB
      })
    }
  })
  commit('setLatestJobId', latestJobId)
}

/**
 * @param   {Store}        context
 * @param   {Store.commit} context.commit
 * @param   {number}       jobId
 *
 * @returns {string}
 */
const getRendersDownloadURL = async ({ commit }, jobId) => {
  const rendersDownload = await RenderFarmMasterAPI.getRendersDownloadURL(jobId)

  return rendersDownload.assets_file
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 */
const forceJobStatus = ({ commit }, payload) => {
  commit('forceJobStatus', payload)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const incrementPagingJobs = ({ commit }) => {
  commit('incrementPagingJobs')
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {string}       sorting
 */
const setSortingJobs = ({ commit }, { sorting }) => {
  commit('setSortingJobs', { sorting })
  commit('resetJobs')
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const resetJobs = ({ commit }) => {
  commit('resetJobs')
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {string}       searchQuery
 */
const setJobSearch = ({ commit }, searchQuery) => {
  commit('setJobSearch', searchQuery)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const clearJobSearch = ({ commit }) => {
  commit('setJobSearch', '')
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {boolean}       [payload.hasToScrollToTop=false]
 * @param {string}        payload.libraryType
 */
const resetItemListScrollTop = (
  { commit, getters },
  { hasToScrollToTop = false, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  commit('setHasToScrollToTop', { hasToScrollToTop, libraryType })
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////
const actions = {
  fetchFilteredJobs,
  fetchJobDetail,
  deleteJob,
  updateJobStatus,
  fetchNewJobs,
  getRendersDownloadURL,
  forceJobStatus,
  incrementPagingJobs,
  setSortingJobs,
  resetJobs,
  setJobSearch,
  clearJobSearch,
  resetItemListScrollTop
}

export default actions

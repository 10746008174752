/**
 * @returns {object}
 */
const getDefaultState = () => ({
  limit: 10,
  // Manage Users
  allUsers: [],
  users: [],
  usersCount: 0,
  // Manage Teams
  teams: [],
  usersByTeam: [],
  usersByTeamCount: 0,
  mentionableUsers: [],
  // Manage Vendors
  vendors: [],
  activeVendor: null,
  usersByVendor: [],
  usersByVendorCount: 0,
  registrations: [],
  registrationsCount: 0
})

export default getDefaultState

<template>
  <ElementFormItem
    :prop="inputKey"
    :label="name"
    :error="customError"
    class="form-generator__content-item form-generator__content-switch"
  >
    <ElementSwitch
      v-model="inputData"
      :active-color="hexBlue"
      :inactive-color="hexGrey"
      class="form-generator__switch"
      @change="setData"
    />
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'

export default {
  name: 'InputSwitch',

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      hexBlue: '#409eff',
      hexGrey: '#e4e7ed'
    }
  },

  methods: {
    /**
     */
    initInput () {
      this.inputData = this.defaultValue || false
      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';

.form-generator__content-switch {
  display: flex;

  /deep/ .el-form-item__content {
    margin-left: spacing(1);
  }
}
</style>

<template>
  <div
    v-if="maintenanceWindow"
    class="maintenance-banner"
  >
    Please note that this app will be offline for planned maintenance on
    {{ maintenanceWindow }}
  </div>
</template>

<script>
export default {
  name: 'MaintenanceBanner',

  props: {
    src: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      maintenanceWindow: null
    }
  },

  /**
   */
  async created () {
    if (this.src) {
      try {
        const response = await fetch(this.src)
        const configuration = await response.json()

        this.maintenanceWindow = configuration.maintenance_window
      } catch (error) {
        // No maintenance planned.
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$banner-height: 40px;
$banner-background-color: #f56c6c;
$banner-text-color: #fff;

.maintenance-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $banner-height;
  color: $banner-text-color;
  background-color: $banner-background-color;
}
</style>

<template>
  <ElementFormItem
    :prop="inputKey"
    :label="name"
    :error="customError"
    class="form-generator__content-item form-generator__content-tag-editor"
  >
    <StitchTagsEditor
      :value="tagStrings"
      :suggestions="tagSuggestionStrings"
      @add="assignTagToItem"
      @remove="unassignTagFromItem"
    />
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'

export default {
  name: 'InputTags',

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: [Array],
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    tagStrings () {
      return this.inputData.map(this.textTags)
    },

    /**
     * @returns {Array}
     */
    tagSuggestionStrings () {
      return this.options.map(this.textTags)
    }
  },

  methods: {
    /**
     */
    initInput () {
      this.inputData = JSON.parse(JSON.stringify(this.defaultValue)) || []

      this.setData()
    },

    /**
     * @param   {object} tag
     *
     * @returns {string}
     */
    textTags (tag) {
      return tag.text
    },

    /**
     * @param {string} text
     */
    assignTagToItem (text) {
      this.inputData.push({ text })

      this.setData()
    },

    /**
     * @param {string} text
     */
    unassignTagFromItem (text) {
      const index = this.inputData.findIndex(tag => tag.text === text)
      this.inputData.splice(index, 1)

      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';
</style>

import { HTTP, prefixWorkspaceUrls } from '@/services/axios'
import serverAPI from '@/services/serverAPI/index'

/**
 * @param   {object}          payload
 * @param   {number}          payload.itemId
 * @param   {string}          payload.fileType
 * @param   {string}          payload.extension
 * @param   {string}          payload.file
 * @param   {string}          payload.description
 * @param   {string}          payload.libraryType
 * @param   {string}          payload.activeWorkspace
 *
 * @returns {Promise<object>}
 */
const addFile = async ({
  itemId,
  fileType,
  extension,
  file,
  description,
  libraryType,
  activeWorkspace
}) => {
  if (fileType === 'model3D') {
    // Targeting different endpoints here because u3ma is getting converted
    if (extension === 'u3ma') {
      fileType = 'u3ma'
    }

    if (
      ['vsp', 'bw', 'obj', 'fbx'].some(
        validExtension => validExtension === extension
      )
    ) {
      fileType = 'vsp'
    }

    if (
      ['jpeg', 'jpg', 'png', 'ai', 'psd'].some(
        validExtension => validExtension === extension
      )
    ) {
      fileType = '2D'
    }
  }

  const fileForm = new FormData()
  fileForm.append(fileType, file)

  const url = `/${itemId}/files/${fileType}/?description=${encodeURIComponent(
    description
  )}`

  return await serverAPI.requestFileURL(
    'post',
    prefixWorkspaceUrls({
      url,
      libraryType,
      activeWorkspaceCode: activeWorkspace
    }),
    fileForm
  )
}

/**
 * @param   {string}          libraryEndpoint
 * @param   {number}          fileId
 * @param   {string}          [description]
 * @param   {string}          activeWorkspace
 *
 * @returns {Promise<object>}
 */
const updateFileDescription = async (
  libraryEndpoint,
  fileId,
  description,
  activeWorkspace
) => {
  const url = `-files/${fileId}`

  return await serverAPI.requestFileURL(
    'patch',
    prefixWorkspaceUrls({
      url,
      libraryEndpoint,
      activeWorkspaceCode: activeWorkspace
    }),
    {
      description
    }
  )
}

/**
 * @param   {string}          libraryEndpoint
 * @param   {number}          fileId
 * @param   {string}          activeWorkspace
 *
 * @returns {Promise<object>}
 */
const deleteFile = async (libraryEndpoint, fileId, activeWorkspace) => {
  const url = `-files/${fileId}`

  return await serverAPI.requestFileURL(
    'delete',
    prefixWorkspaceUrls({
      url,
      libraryEndpoint,
      activeWorkspaceCode: activeWorkspace
    })
  )
}

/**
 * @param   {string}                      method
 * @param   {string}                      url
 * @param   {object}                      config
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestFileURL = async (method, url, config) => {
  const response = await HTTP[method](url, config)

  return response.data
}

const fileAPI = {
  addFile,
  updateFileDescription,
  deleteFile,
  requestFileURL
}

export default fileAPI

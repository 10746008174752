import { HTTP } from '@/services/axios'

/**
 * @param   {number}   groupId
 * @param   {number}   roleId
 * @param   {string}   search
 *
 * @returns {object[]}
 */
const fetchInvitations = async (groupId, roleId, search) => {
  const response = await HTTP.get('/invitations', {
    params: {
      group_id: groupId,
      role_id: roleId,
      search
    }
  })

  return response.data
}

/**
 * @param   {object[]} invitations
 *
 * @returns {object}
 */
const addInvitationBulk = async invitations => {
  const requestPayload = invitations.map(({ email, groupId, roleId }) => ({
    to_email: email,
    group_id: groupId,
    role_id: roleId
  }))
  const response = await HTTP.post('/invitations/add_bulk', requestPayload)

  return response.data
}

/**
 * @param   {string} email
 * @param   {number} groupId
 * @param   {number} roleId
 *
 * @returns {object}
 */
const addInvitation = async (email, groupId, roleId) => {
  const response = await HTTP.post('/invitations', {
    to_email: email,
    group_id: groupId,
    role_id: roleId
  })

  return response.data
}

/**
 * @param   {number} invitationId
 * @param   {number} roleId
 *
 * @returns {object}
 */
const updateInvitation = async (invitationId, roleId) => {
  const response = await HTTP.patch(`/invitations/${invitationId}`, {
    role_id: roleId
  })

  return response.data
}

/**
 * @param   {number}       invitationId
 *
 * @returns {AxiosPromise}
 */
const deleteInvitation = async invitationId => {
  return await HTTP.delete(`/invitations/${invitationId}`)
}

/**
 * @returns {Promise<Array>}
 */
const fetchRoles = async () => {
  const response = await HTTP.get('/roles')

  return response.data
}

const invitationAPI = {
  fetchRoles,
  addInvitation,
  fetchInvitations,
  updateInvitation,
  deleteInvitation,
  addInvitationBulk
}

export default invitationAPI

<template>
  <div class="the-notifications">
    <div class="the-notifications__workspace">
      <div
        v-for="(notification, index) in notifications"
        :key="index"
        class="the-notifications__wrapper"
      >
        <NotificationElement :notification="notification" />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationElement from './components/NotificationElement.vue'
import VueTypes from 'vue-types'

export default {
  name: 'Notifications',

  components: {
    NotificationElement
  },

  props: {
    notifications: VueTypes.array
  }
}
</script>

<style lang="scss" scoped>
.the-notifications__wrapper {
  min-height: spacing(10);
}
</style>

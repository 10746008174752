<script>
import VueTypes from 'vue-types'
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'

export default {
  name: 'InputPreset',

  mixins: [InputMixin],

  props: {
    defaultValue: VueTypes.oneOfType([String, Number])
  },

  methods: {
    /**
     */
    initInput () {
      this.inputData = this.defaultValue || null
      this.setData()
    }
  },

  render: () => null
}
</script>

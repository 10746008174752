import { HTTP, prefixWorkspaceUrls } from '@/services/axios'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import serverAPI from '@/services/serverAPI/index'

/**
 * @returns {Promise<object>}
 */
const fetchSeasons = async () => {
  const response = await HTTP.get('seasons/')

  return response.data
}

/**
 * @returns {Promise<object>}
 */
const fetchStylePrices = async () => {
  const response = await HTTP.get('prices/')

  return response.data
}

/**
 * @param   {string}                   libraryType
 * @param   {string}                   activeWorkspace
 *
 * @returns {Promise<object> | object}
 */
const fetchFilters = async (libraryType, activeWorkspace) => {
  if (libraryType === LIBRARY_TYPE.STITCH) {
    return {}
  }

  const url = prefixWorkspaceUrls({
    url: '/filters/',
    libraryType,
    activeWorkspaceCode: activeWorkspace
  })

  try {
    return await serverAPI.requestFiltersURL(url)
  } catch (error) {
    // If error, the error will be caught in the error callback
    // Returning an empty array to prevent error related to the ui
    console.warn(error)

    return {}
  }
}

/**
 * @param   {string}                      url
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestFiltersURL = async url => {
  const response = await HTTP.get(url)

  return response.data
}

const styleFilterAPI = {
  fetchSeasons,
  fetchStylePrices,
  fetchFilters,
  requestFiltersURL
}

export default styleFilterAPI

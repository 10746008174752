import 'abort-controller/polyfill'
import StitchAssetBrowser from './components/StitchAssetBrowser.vue'
import AssetTypeUtils from './services/assetTypeUtils'

export default {
  /**
   * @param {Vue}    Vue
   * @param {object} payload
   * @param {object} payload.assetTypes
   */
  install (Vue, { assetTypes }) {
    if (assetTypes) {
      Object.entries(assetTypes).forEach(([type, options]) => {
        AssetTypeUtils.initializeAssetType(type, options)
      })
    }

    Vue.component(StitchAssetBrowser.name, StitchAssetBrowser)
  }
}

import pluginConfig from './config'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { PLATFORM_TYPE } from '@/constants/platform'
import store from '@/store/store'

class TrackingBase {
  /**
   * @param {string} id
   * @param {object} config
   */
  constructor (id, config) {
    this.settings = {
      ...pluginConfig,
      ...config
    }

    this.id = id
    this.env = process.env.VUE_APP_ENVIRONMENT

    if (this.isEnabled()) {
      this.enable()
    }
  }

  /**
   * @param {string} message
   * @param {...*}   otherArguments
   */
  log (message, ...otherArguments) {
    if (this.settings.isDebugEnabled) {
      console.warn(`Tracker [${this.id}]:`, message, ...otherArguments)
    }
  }

  /**
   * @returns {boolean}
   */
  isEnabled () {
    return Boolean(this.settings.isEnabled && this.id)
  }

  /**
   */
  enable () {
    this.log('Enabling script', { id: this.id, env: this.env })
    this.loadScript()

    this.settings.isEnabled = true
  }

  /**
   */
  getAnalytics () {}

  /**
   */
  loadScript () {}

  /**
   * @param {string | null} [payload={}]
   * @param {string | null} [payload.event='event-custom']
   * @param {string | null} [payload.category=null]
   * @param {string | null} [payload.action=null]
   * @param {string | null} [payload.label=null]
   * @param {string | null} [payload.value=null]
   * @param {string | null} [payload.item=null]
   * @param {...*}          [payload.rest]
   */
  trackEvent ({
    event = 'eventCustom',
    object,
    action = null,
    category = null,
    label = null,
    value = null,
    item = null,
    ...rest
  } = {}) {
    if (this.isEnabled()) {
      this.log('Dispatching event', {
        event,
        object,
        action,
        category,
        label,
        value,
        item,
        ...rest
      })
    }
  }

  /**
   * @param   {object | null} [item=null]
   *
   * @returns {object}
   */
  getItemAdditionalInfo (item = null) {
    if (!item) {
      return {}
    }

    const divisions = item.division || item.divisions || {}
    const file =
      item.libraryType === LIBRARY_TYPE.JOB
        ? item.filesDict.thumbnail[0].file
        : item.filesDict && item.filesDict.model3D && item.filesDict.model3D[0]

    return {
      id: item.id,
      type: item.libraryType,
      name: item.name || item.title || 'no name',
      subTitle: item.subTitle,
      plmCode: item.plmCode,
      divisions,
      productGroup: item.productGroup,
      fabricMaterialSubtype: item.fabricMaterialSubtype,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      sustainabilityRating: item.sustainabilityRating,
      file,
      isConverted: item.isConverted,
      platform: this.getPlatform(),
      env: this.env
    }
  }

  /**
   * @returns {string}
   */
  getPlatform () {
    if (store.getters.getBrowzwearAPI) {
      return PLATFORM_TYPE.BROWZWEAR
    }

    return PLATFORM_TYPE.WEB
  }

  /**
   * @param {string | number} userIdentifier
   * @param {UserShape}       user
   */
  identifyUser (userIdentifier, user) {
    if (this.isEnabled()) {
      this.log('Identifying user', {
        userIdentifier,
        user
      })
    }
  }
}

export default TrackingBase

import FormGenerator from './components/FormGenerator.vue'

export default {
  /**
   * @param {Vue} Vue
   */
  install (Vue) {
    Vue.component(FormGenerator.name, FormGenerator)
  }
}

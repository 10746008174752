<template>
  <div class="option-image-slider">
    <template v-for="(sliderButton, position) in sliderButtons">
      <ElementButton
        v-if="hasScroll"
        :key="position"
        :class="`option-image-slider__button option-image-slider__button-${position}`"
        :disabled="!sliderButton.enabled"
        :icon="`el-icon-arrow-${position}`"
        :circle="true"
        size="mini"
        @click="slide(sliderButton.direction)"
      />
    </template>
    <div
      v-if="images.length > 0"
      ref="imageListRoot"
      :class="[
        {
          'option-image-slider__list': !disabled
        }
      ]"
    >
      <ul
        ref="imagesContainer"
        :class="[
          'option-image-slider__images',
          { 'option-image-slider__images--slider': !disabled }
        ]"
      >
        <li
          v-for="(image, imageIndex) in images"
          :key="imageIndex"
          ref="imageElement"
          class="option-image-slider__image"
        >
          <slot
            name="slide"
            :image="image"
            :imageIndex="imageIndex"
          >
            <img :src="image">
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  name: 'ViewImageSlider',

  props: {
    images: VueTypes.array,
    disabled: VueTypes.bool.def(false)
  },

  data () {
    return {
      sliderButtons: {
        left: {
          direction: -1,
          enabled: false
        },
        right: {
          direction: 1,
          enabled: false
        }
      },
      isWiderThanContainer: false,
      currentSlide: 0,
      resizeObserver: null
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    hasScroll () {
      return !this.disabled && this.isWiderThanContainer
    }
  },

  watch: {
    /**
     */
    images () {
      if (!this.disabled) {
        this.initializeSliders()
      }
    }
  },

  mounted () {
    if (!this.disabled) {
      this.initializeSliders()
    }
  },

  beforeDestroy () {
    this.unobserveChanges()
  },

  methods: {
    /**
     */
    initializeSliders () {
      this.unobserveChanges()
      this.observeChanges()
      this.currentSlide = 0

      if (this.$refs.imageListRoot) {
        this.slide(0)
      }
    },

    /**
     */
    updateSliderVisibility () {
      const slider = this.$refs.imageListRoot
      this.isWiderThanContainer = slider.scrollWidth > slider.offsetWidth
    },

    /**
     */
    observeChanges () {
      this.resizeObserver = new ResizeObserver(() => {
        this.updateSliderVisibility()
      })

      if (this.$refs.imageListRoot) {
        this.resizeObserver.observe(this.$refs.imageListRoot)
      }
    },

    /**
     */
    unobserveChanges () {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
      }
    },

    /**
     * @param {number} direction
     */
    slide (direction) {
      const slider = this.$refs.imageListRoot
      const imagesContainer = this.$refs.imagesContainer
      const optionImages = this.$refs.imageElement

      if (!optionImages) {
        return
      }

      const nextSlideIndex = this.currentSlide + direction

      if (nextSlideIndex >= 0 && nextSlideIndex < optionImages.length) {
        this.currentSlide += direction
      }

      slider.scrollLeft = optionImages[this.currentSlide].offsetLeft

      const imagesContainerBoundingRect =
        imagesContainer.getBoundingClientRect()
      const sliderBoundingRect = slider.getBoundingClientRect()

      // checks if any side of the scroll is at the end and disables that button
      for (const [key, sliderButton] of Object.entries(this.sliderButtons)) {
        const reachedBoundaries =
          key === 'left'
            ? imagesContainerBoundingRect[key] >= sliderBoundingRect[key]
            : imagesContainerBoundingRect[key] <= sliderBoundingRect[key]
        sliderButton.enabled = !reachedBoundaries
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$image-size: spacing(11);
$slider-height: $image-size + spacing(1);
$button-alignment: calc(50% + 6px);
$button-position: 2px;
$button-zindex: 1;
$image-padding: 2px;

.option-image-slider__images {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: 0;
  padding: spacing(1) 0 0;
  column-gap: spacing(1);
  list-style: none;
  row-gap: spacing(1);

  &--slider {
    position: absolute;
    flex-wrap: nowrap;
  }
}

.option-image-slider__image {
  position: relative;
  width: $image-size;
  height: $image-size;
  padding: $image-padding;
  background: $white;
  border: $border-divider;
  border-radius: spacing(1);
  box-shadow: none;
  cursor: pointer;
  transition: box-shadow $transition;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 1;
    transition: opacity $transition;
  }
}

.option-image-slider__list {
  position: relative;
  width: 100%;
  height: $slider-height;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.option-image-slider__button {
  position: absolute;
  top: $button-alignment;
  z-index: $button-zindex;

  &-left {
    left: $button-position;
  }

  &-right {
    right: $button-position;
  }
}
</style>

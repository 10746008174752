import { render, staticRenderFns } from "./LibraryCardItem.vue?vue&type=template&id=240b1108&scoped=true&"
import script from "./LibraryCardItem.vue?vue&type=script&lang=js&"
export * from "./LibraryCardItem.vue?vue&type=script&lang=js&"
import style0 from "./LibraryCardItem.vue?vue&type=style&index=0&id=240b1108&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "240b1108",
  null
  
)

export default component.exports
<template>
  <div>
    <div
      v-if="group"
      class="header-access-manager"
    >
      <h2 class="header-access-manager__title">
        View Members
      </h2>
      <div class="header-access-manager__group">
        <h3>
          Members that can access
          <strong>{{ group.name }}:</strong>
        </h3>
      </div>
      <div class="header-access-manager__access-list">
        <div
          v-for="member in members"
          :key="member.email"
          class="user-access-entry"
        >
          <span class="user-access-entry__email">{{ member.email }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="header-access-manager header-access-manager--no-access"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  name: 'HeaderAccessManager',

  props: {
    group: VueTypes.object,
    members: VueTypes.array
  }
}
</script>

<style lang="scss" scoped>
$max-width-email: 300px;

.header-access-manager {
  display: flex;
  flex-direction: column;
  min-width: spacing(50);
  height: 100%;
  padding: 0 spacing(1);
  background-color: $white;
}

.header-access-manager__title {
  margin-bottom: spacing(2);
  text-align: center;
}

.header-access-manager__group {
  margin-bottom: spacing(2);

  /deep/ .el-dropdown {
    font-size: inherit;
  }
}

.header-access-manager__access-list {
  @include scroll-y-smooth;

  max-height: 100%;
  border-top: $border-divider;
}

.user-access-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(1) 0;
  border-bottom: $border-divider;
}

.user-access-entry__email {
  @include text-ellipsis;

  max-width: $max-width-email;
  padding-left: spacing(2);
}
</style>

const AssetTypes = {
  JOB: 'job',
  STYLE: 'style'
}

const assetTypeConfiguration = {}

/**
 * @returns {string[]}
 */
const getAssetTypes = () => Object.keys(assetTypeConfiguration)

/**
 * @param   {string} assetType
 *
 * @returns {object}
 */
const getAssetTypeConfiguration = assetType => assetTypeConfiguration[assetType]

/**
 * @param {string} assetType
 * @param {object} options
 */
const initializeAssetType = (assetType, options) => {
  if (!Object.values(AssetTypes).includes(assetType)) {
    console.error(`Unexpected type ${assetType}.`)

    return
  }

  if (options.apiBaseUrl && options.loginUrl) {
    assetTypeConfiguration[assetType] = {
      apiBaseUrl: processUrl(options.apiBaseUrl),
      loginUrl: processUrl(options.loginUrl),
      menuItemLabel: options.menuItemLabel
    }
  } else {
    console.error(
      `Asset type "${assetType}" cannot be initialized.`,
      'You need to provide a base url for the server API and a url to log into it.'
    )
  }
}

/**
 * @param   {string} url
 *
 * @returns {string}
 */
const processUrl = url => {
  if (url.slice(-1) === '/') {
    return url.slice(0, -1)
  } else {
    return url
  }
}

const AssetTypeUtils = {
  AssetTypes,
  initializeAssetType,
  getAssetTypes,
  getAssetTypeConfiguration
}

export default AssetTypeUtils

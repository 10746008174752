import { LIBRARY_TYPE } from '@/constants/libraryType'
import blockUploadFormConfiguration from '@/stubs/blockUploadFormConfiguration.json'
import fabricUploadFormConfiguration from '@/stubs/fabricUploadFormConfiguration.json'
import stitchUploadFormConfiguration from '@/stubs/stitchUploadFormConfiguration.json'
import trimUploadFormConfiguration from '@/stubs/trimUploadFormConfiguration.json'
import { getConfig as getStyleUploadFormConfiguration } from '@/stubs/styleUploadFormConfiguration'
import { FORM_NAME } from '@/constants/formName'
import versionUploadFormConfiguration from '@/stubs/versionUploadFormConfiguration.json'
import blockVersionUploadFormConfiguration from '@/stubs/blockVersionUploadFormConfiguration.json'
import versionRenameFormConfiguration from '@/stubs/versionRenameFormConfiguration.json'
import blockVersionRenameFormConfiguration from '@/stubs/blockVersionRenameFormConfiguration.json'
import optionFormConfiguration from '@/stubs/optionFormConfiguration.json'
import statusChangeConfirmationFormConfiguration from '@/stubs/statusChangeConfirmationFormConfiguration.json'

/**
 * "version" and "versionName" aren't LibraryTypes
 */
const formReferences = {
  [LIBRARY_TYPE.BLOCK]: blockUploadFormConfiguration,
  [LIBRARY_TYPE.FABRIC]: fabricUploadFormConfiguration,
  [LIBRARY_TYPE.STITCH]: stitchUploadFormConfiguration,
  [LIBRARY_TYPE.TRIM]: trimUploadFormConfiguration,
  [LIBRARY_TYPE.STYLE]: getStyleUploadFormConfiguration,
  [FORM_NAME.VERSION_CREATE]: versionUploadFormConfiguration,
  [FORM_NAME.BLOCK_VERSION_CREATE]: blockVersionUploadFormConfiguration,
  [FORM_NAME.VERSION_RENAME]: versionRenameFormConfiguration,
  [FORM_NAME.OPTION_CREATE]: optionFormConfiguration,
  [FORM_NAME.BLOCK_VERSION_RENAME]: blockVersionRenameFormConfiguration,
  [FORM_NAME.STATUS_CHANGE_CONFIRMATION]:
    statusChangeConfirmationFormConfiguration
}
/**
 * @param   {string} formReference
 *
 * @returns {object}
 */
export const getFormConfig = formReference => {
  const formConfig = formReferences[formReference]

  if (typeof formConfig === 'function') {
    return formConfig()
  }

  return formConfig || null
}

export default {
  getFormConfig
}

<template>
  <ElementFormItem
    :prop="inputKey"
    :rules="rules"
    :label="name"
    :error="customError"
    class="form-generator__content-item"
  >
    <ElementCheckboxGroup
      v-model="inputData"
      class="form-generator__checkbox-group"
    >
      <ElementCheckbox
        v-for="(option, keyOption) in options"
        :key="keyOption"
        :label="option.id"
        :name="option.name"
        @change="setData"
      >
        {{ option.name }}
      </ElementCheckbox>
    </ElementCheckboxGroup>
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'

export default {
  name: 'InputCheckbox',

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: Array,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  methods: {
    /**
     */
    initInput () {
      this.inputData = this.defaultValue || []
      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';

$input-checkbox-column-width: 32%;
$input-checkbox-group-width: $input-width * 2;

.form-generator__checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, $input-checkbox-column-width);
  width: $input-checkbox-group-width;

  /deep/ .el-checkbox__label {
    font-weight: $font-weight-regular;
    font-family: $primary-font;
  }
}
</style>

<template>
  <div
    :class="{
      'zoom-image__image-wrapper': true,
      'zoom-image__event-blocker': isPointerEventBlocked
    }"
    @click="togglePointerEventBlock"
  >
    <zoom-on-hover
      ref="zoomElement"
      :img-normal="imgNormal"
      :img-zoom="imgZoom || imgNormal"
      :scale="scale"
      :disabled="disabled"
      @loaded="emitImageLoaded"
      @resized="emitImageResized"
    />
  </div>
</template>

<script>
export default {
  name: 'StitchZoom',

  props: {
    imgNormal: {
      type: String,
      default: null,
      required: false
    },
    imgZoom: {
      type: String,
      default: null,
      required: false
    },
    scale: {
      type: Number,
      default: () => 1.5,
      required: false
    },
    disabled: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },

  data () {
    return {
      isPointerEventBlocked: true
    }
  },

  methods: {
    /**
     */
    emitImageLoaded () {
      this.$emit('loaded')
    },

    /**
     * @param {object} dimensions
     */
    emitImageResized (dimensions) {
      this.$emit('resized', dimensions)
    },

    /**
     * Changes block status on pointerEvents (defined on CSS through .zoom-image__event-blocker).
     *
     * @param {PointerEvent} event
     */
    togglePointerEventBlock (event) {
      this.emulatePointerMoveEvent(event)
      this.isPointerEventBlocked = !this.isPointerEventBlocked
    },

    /**
     * Emulates pointerMove event after click, because image doesn't appear if pointer stands still.
     * zoom-on-hover implementation - https://github.com/Rundik/vue-zoom-on-hover/blob/master/src/ZoomOnHover.vue.
     *
     * @param {PointerEvent} event
     */
    emulatePointerMoveEvent (event) {
      const zoomElement = this.$refs.zoomElement.$el
      const eventDataClone = {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: event.clientX,
        clientY: event.clientY,
        screenX: event.screenX,
        screenY: event.screenY,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
        pageX: event.pageX,
        pageY: event.pageY
      }

      // Click on image resets zoom (emulate 'pointerout'),
      // Click on wrapper zooms image (emulate 'pointerover').
      const eventType =
        event.target.tagName === 'IMG' ? 'pointerout' : 'pointerover'
      const triggeredEvent = new PointerEvent(eventType, eventDataClone)

      zoomElement.dispatchEvent(triggeredEvent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/utils';

.zoom-image__image-wrapper {
  width: 100%;
  cursor: zoom-out;

  /deep/ .zoom-on-hover {
    height: 100%;
  }

  /deep/ .normal {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.zoom-image__event-blocker {
  cursor: zoom-in;

  /deep/ .zoom-on-hover {
    pointer-events: none;
  }
}
</style>

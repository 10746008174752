export const LIBRARY_TYPE = Object.freeze({
  BLOCK: 'block',
  FABRIC: 'fabric',
  STITCH: 'stitch',
  TRIM: 'trim',
  JOB: 'job',
  STYLE: 'style',
  OPTION: 'option'
})

export const LIBRARY_FILTERABLE_TYPE = Object.freeze({
  BLOCK: 'block',
  FABRIC: 'fabric',
  TRIM: 'trim',
  STYLE: 'style'
})

export const LIBRARY_ENDPOINT = Object.freeze({
  workspaces: 'workspaces',
  [LIBRARY_TYPE.BLOCK]: 'blocks',
  [LIBRARY_TYPE.FABRIC]: 'fabrics',
  [LIBRARY_TYPE.STITCH]: 'stitches',
  [LIBRARY_TYPE.TRIM]: 'trims',
  [LIBRARY_TYPE.JOB]: 'jobs',
  [LIBRARY_TYPE.STYLE]: 'styles',
  [LIBRARY_TYPE.OPTION]: 'options'
})

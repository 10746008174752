<template>
  <ElementFormItem
    :prop="inputKey"
    :rules="rules"
    :label="name"
    :error="customError"
    class="form-generator__content-item"
  >
    <StitchTooltip
      :message="disabledMessage"
      :is-disabled="!isDisabled"
    >
      <ElementSelect
        v-model="inputData"
        :placeholder="name"
        :popper-append-to-body="false"
        :disabled="isDisabled || options.length === 0"
        class="form-generator__select-input"
        filterable
        clearable
        @change="setData"
      >
        <ElementOption
          v-for="(option, keyOption) in options"
          :key="keyOption"
          :label="option.name"
          :value="option.id"
          data-testid="selectItem"
        />
      </ElementSelect>
    </StitchTooltip>

    <InputNotes
      v-if="notes.length > 0"
      :notes="notes"
    />
  </ElementFormItem>
</template>

<script>
// props, data, computed props, created, setData
import { InputMixin } from '../mixins/InputMixin.js'
import InputNotes from './InputNotes'

export default {
  name: 'InputSelect',

  components: {
    InputNotes
  },

  mixins: [InputMixin],

  props: {
    customError: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: [Number, String],
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  methods: {
    /**
     */
    initInput () {
      this.inputData =
        this.defaultValue !== undefined ? this.defaultValue : null // To handle 0 as a value
      this.setData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/utils';

.form-generator__select-input {
  width: 100%;
  min-width: $input-width;
}
</style>

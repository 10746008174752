/**
 * @returns {object}
 */
export const IMAGE_RESOLUTION = Object.freeze({
  THUMBNAIL: '300x300',
  PREVIEW_LIBRARY_ITEM: '480x480',
  PREVIEW_JOB: '800x600',
  PREVIEW_OPTION: '5000x5000'
})

export const IMAGE_EXTENSIONS = Object.freeze([
  'jpg',
  'jpeg',
  'png',
  'tiff',
  'webp'
])

export const IMAGE_VIEW_ANGLE = Object.freeze({
  VIEW360: 'view360'
})

/**
 * @param {object}   state
 * @param {object[]} invitations
 */
const setInvitations = (state, invitations) => {
  state.invitations = invitations
}

/**
 * @param {object} state
 * @param {object} invitation
 */
const addInvitation = (state, invitation) => {
  state.invitations.push(invitation)
}

/**
 * @param {object} state
 * @param {object} invitation
 */
const updateInvitation = (state, invitation) => {
  const index = state.invitations.findIndex(
    singleInvitation => singleInvitation.id === invitation.id
  )

  if (index !== -1) {
    const newInvitation = {
      ...state.invitations[index],
      ...invitation
    }

    state.invitations.splice(index, 1, newInvitation)
  }
}

/**
 * @param {object} state
 * @param {number} invitationId
 */
const deleteInvitation = (state, invitationId) => {
  const index = state.invitations.findIndex(
    invitation => invitation.id === invitationId
  )

  state.invitations.splice(index, 1)
}

/**
 * @param {object}                state
 * @param {PermissionRoleShape[]} roles
 */
const setRoles = (state, roles) => {
  state.roles = roles
}

const mutations = {
  setInvitations,
  addInvitation,
  updateInvitation,
  deleteInvitation,
  setRoles
}

export default mutations

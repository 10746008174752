/* Entry point used by consumers when importing the Design System */
import ZoomOnHover from 'vue-zoom-on-hover'

import StitchDialog from './elements/StitchDialog.vue'
import StitchZoom from './elements/StitchZoom.vue'
import StitchTagsEditor from './elements/StitchTagsEditor.vue'

// Plugins
// import ElementUI from './plugins/ElementUI'
import StitchAssetBrowser from './plugins/StitchAssetBrowser'

const components = [StitchDialog, StitchZoom, StitchTagsEditor]

const install = function (Vue) {
  Vue.use(ZoomOnHover)
  // Vue.use(ElementUI)

  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export { StitchDialog, StitchZoom, StitchAssetBrowser, StitchTagsEditor }

export default {
  install
}

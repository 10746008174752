import actionsItems from '@/store/modules/item/actionsItems.js'
import actionsFiles from '@/store/modules/item/actionsFiles.js'
import actionsFilters from '@/store/modules/item/actionsFilters.js'
import actionsStyles from '@/store/modules/item/actionsStyles.js'

import getters from '@/store/modules/item/getters.js'
import mutations from '@/store/modules/item/mutations.js'
import getDefaultState from '@/store/modules/item/state.js'

const actions = {
  ...actionsItems,
  ...actionsFiles,
  ...actionsStyles,
  ...actionsFilters
}

export default {
  actions,
  getters,
  mutations,
  state: getDefaultState()
}

export const KEYBOARD_KEY = Object.freeze({
  BACKSPACE: ['Backspace'],
  TAB: ['Tab'],
  ENTER: ['Enter'],
  SHIFT: ['Shift'],
  CTRL: ['Control'],
  ALT: ['Alt'],
  ESC: ['Escape'],
  SPACEBAR: ['Spacebar', ' '],
  DELETE: ['Backspace', 'Delete'],
  LEFT: ['ArrowLeft', 'Left'],
  RIGHT: ['ArrowRight', 'Right']
})

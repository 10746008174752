<template>
  <div class="option-list">
    <div
      ref="optionList"
      class="option-list__options"
    >
      <div
        v-if="showError"
        class="render-status"
      >
        <h2>{{ errorMessage.title }}</h2>
        <p>{{ errorMessage.instruction }}</p>
        <p>
          Need more help? Check out our
          <a
            :href="helpCenterLink"
            target="_blank"
          >help center</a>.
        </p>
      </div>
      <template v-else>
        <div
          v-if="isUploadingOrPending"
          ref="loader"
          class="option-item option-item__option--rendering"
        >
          <ViewRenderSteps :version="version" />
        </div>
        <BlockOptionItem
          v-else
          :id="option.id"
          :key="option.id"
          :can-edit="canEdit"
          :can-manage-options="canManageOptions"
          :option="option"
          :version="version"
          class="option-list__option"
          @update-image="handleUpdateImage"
          @delete-version="handleDeleteVersion"
          @delete-image="handleDeleteImage"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  JOB_STATUS,
  RENDER_JOB_FAILED_MESSAGE
} from '@/constants/loadingStatus'

import VueTypes from 'vue-types'
import { BlockVersionShape } from '@/types'
import BlockOptionItem from './components/ViewItem'
import ViewRenderSteps from './components/ViewRenderSteps'

export default {
  name: 'ViewList',

  components: {
    BlockOptionItem,
    ViewRenderSteps
  },

  props: {
    activeVersion: VueTypes.objectOf(BlockVersionShape).loose,
    option: VueTypes.object,
    canEdit: VueTypes.bool.def(false)
  },

  emits: ['delete-version', 'update-view-image', 'delete-view-image'],

  data () {
    return {
      manageOptionsDisabledMessages: {
        ADD: [
          'This version comes from a .BW file. Add a colorway',
          'on the 3D file and upload it as a new version.'
        ]
      },
      errorMessages: {
        NO_OPTIONS: 'Please Add New Colorway'
      },
      isSorting: false,
      helpCenterLink:
        'https://hub-support.freshdesk.com/support/solutions/articles/101000446535-browzwear-file-fails-to-upload'
    }
  },

  computed: {
    ...mapGetters(['getVersionById']),

    /**
     * @returns {object}
     */
    version () {
      const version = this.getVersionById(this.activeVersion.id)

      if (this.activeVersion.isUploading || !version) {
        return this.activeVersion
      }

      return version
    },

    /**
     * @returns {boolean}
     */
    isVersionEmpty () {
      if (this.activeVersion.views) {
        return this.activeVersion.views.length === 0
      }

      return false
    },

    /**
     * @returns {boolean}
     */
    showError () {
      return (
        this.version.autorenderStatus === JOB_STATUS.FAILED ||
        (this.isVersionEmpty && !this.isUploadingOrPending)
      )
    },

    /**
     * @returns {boolean}
     */
    canManageOptions () {
      return (
        this.version.model === null ||
        (this.version.model && !this.version.model.endsWith('.bw'))
      )
    },

    /**
     * @returns {object}
     */
    errorMessage () {
      const renderErrorMessage = this.version.autorenderStatusMessage
      switch (renderErrorMessage) {
        case RENDER_JOB_FAILED_MESSAGE.SNAPSHOT_ERROR.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.SNAPSHOT_ERROR
        case RENDER_JOB_FAILED_MESSAGE.NO_SNAPSHOTS.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.NO_SNAPSHOTS
        case RENDER_JOB_FAILED_MESSAGE.NO_AVATAR.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.NO_AVATAR
        default:
          return RENDER_JOB_FAILED_MESSAGE.DEFAULT
      }
    },

    /**
     * @returns {boolean}
     */
    isUploadingOrPending () {
      const { autorenderStatus, isUploading } = this.version

      return (
        isUploading ||
        autorenderStatus === JOB_STATUS.STARTED ||
        autorenderStatus === JOB_STATUS.PENDING
      )
    }
  },

  methods: {
    ...mapActions(['updateOption']),

    /**
     * @param {object} payload
     */
    handleUpdateImage (payload) {
      this.$emit('update-view-image', payload)
    },

    /**
     * @param {object} payload
     */
    handleDeleteVersion (payload) {
      this.$emit('delete-version', payload)
    },

    /**
     * @param {object} payload
     */
    handleDeleteImage (payload) {
      this.$emit('delete-view-image', payload)
    },

    /**
     * @param {Event} event
     */
    onSortDrop (event) {
      if (event.oldIndex !== event.newIndex) {
        const option = this.options[event.oldIndex]
        const payload = {
          styleId: this.activeVersion.styleId,
          versionId: option.versionId,
          optionId: option.id,
          index: event.newIndex
        }

        this.updateOption(payload)
        this.isSorting = false
      }
    },

    /**
     */
    disableDragDropUpload () {
      this.isSorting = true
    }
  }
}
</script>

<style lang="scss" scoped>
$border-radius-options: $border-radius-s * 2;
$render-status-icon-font-size: 38px;

.option-list {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.option-list__add-option,
.option-list__options {
  padding: 0 spacing(2);
}

.option-list__add-option {
  width: fit-content;
}

.option-list__options {
  @include scroll-y-smooth;

  position: relative;
  height: 100%;
  max-height: 100%;

  .sortable-drag {
    background-color: $white;
  }
}

.option-list__option {
  position: relative;
  margin-bottom: spacing(1);
  padding: spacing(2);
  border: $border-divider;
  border-radius: $border-radius-options;
}

.option-item__dragged {
  height: spacing(12);
  visibility: hidden;
}

.option-item__option--rendering {
  display: flex;
  align-items: center;
}

.render-status {
  @include position-absolute;

  width: 100%;
  margin: spacing(1);
  padding: spacing(1);
  color: $grey-dark;
  text-align: center;
  word-break: break-word;

  > * {
    margin-bottom: spacing(1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $blue;
  }
}
</style>

<template>
  <div
    v-if="errorStatus"
    class="library-detail-error"
  >
    <template v-if="errorStatus === 404">
      <h2 class="library-detail-error__message">
        Sorry... we could not find the {{ activeLibrary }} you are looking for.
      </h2>
    </template>
    <template v-else>
      <h2 class="library-detail-error__message">
        Something went wrong: Check with the developers for more info.
      </h2>
    </template>
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  name: 'LibraryDetailError',

  props: {
    errorStatus: VueTypes.number,
    activeLibrary: VueTypes.string
  }
}
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  width: 100%;
}

.library-detail-error__message {
  @include flex-center(column);

  width: 100%;
  padding: spacing(2);
  word-break: break-word;
}
</style>

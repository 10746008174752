var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"option-image-slider"},[_vm._l((_vm.sliderButtons),function(sliderButton,position){return [(_vm.hasScroll)?_c('ElementButton',{key:position,class:("option-image-slider__button option-image-slider__button-" + position),attrs:{"disabled":!sliderButton.enabled,"icon":("el-icon-arrow-" + position),"circle":true,"size":"mini"},on:{"click":function($event){return _vm.slide(sliderButton.direction)}}}):_vm._e()]}),(_vm.images.length > 0)?_c('div',{ref:"imageListRoot",class:[
      {
        'option-image-slider__list': !_vm.disabled
      }
    ]},[_c('ul',{ref:"imagesContainer",class:[
        'option-image-slider__images',
        { 'option-image-slider__images--slider': !_vm.disabled }
      ]},_vm._l((_vm.images),function(image,imageIndex){return _c('li',{key:imageIndex,ref:"imageElement",refInFor:true,staticClass:"option-image-slider__image"},[_vm._t("slide",function(){return [_c('img',{attrs:{"src":image}})]},{"image":image,"imageIndex":imageIndex})],2)}),0)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
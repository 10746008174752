<template>
  <div class="form-generator__field-notes">
    <p
      v-for="(note, index) in notes"
      :key="index"
    >
      {{ note }}
    </p>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
export default {
  name: 'InputNotes',

  props: {
    notes: VueTypes.arrayOf(String)
  }
}
</script>

<style lang="scss">
.form-generator__field-notes {
  @include text-label;

  color: $grey;
}
</style>

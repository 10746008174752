import VueTypes from 'vue-types'
import { STYLE_TYPE } from '@/constants/dropdownOptions'

/**
 * @namespace Stitch
 */

/**
 * @type     {object}
 * @property {string}              name
 * @property {(string | Function)} [method]
 * @property {boolean}             [show]
 * @property {object[]}            [children]
 * @property {boolean}             [disabled]
 * @property {string[]}            [styles]
 * @property {string[]}            [disabledMessage]
 * @property {boolean}             [divided=false]
 */
const DropdownItemShape = VueTypes.shape({
  dataTestid: VueTypes.string,
  name: VueTypes.string.isRequired,
  method: VueTypes.oneOfType([String, Function]),
  show: VueTypes.bool,
  children: VueTypes.arrayOf(VueTypes.object),
  disabled: VueTypes.bool,
  styles: VueTypes.arrayOf(VueTypes.oneOf(Object.values(STYLE_TYPE))),
  disabledMessage: VueTypes.arrayOf(VueTypes.string),
  divided: VueTypes.bool.def(false),
  // Workspace Switch
  code: VueTypes.string,
  admin: VueTypes.bool,
  image: VueTypes.string,
  thumbnail: VueTypes.string
})

/**
 * @type     {object}
 * @property {string} [name]
 * @property {string} [extension]
 * @property {string} [description]
 * @property {number} [size]
 * @property {File}   raw
 */
const FileShape = VueTypes.shape({
  name: VueTypes.string,
  extension: VueTypes.string,
  description: VueTypes.string,
  size: VueTypes.number,
  raw: VueTypes.instanceOf(File).isRequired
})

/**
 * @typedef  {object} Coordinate
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef  {object} Size
 * @property {number} width
 * @property {number} height
 */

/**
 * @typedef {object} ToolbarItemConfigurationItem
 * @property {string}  icon
 * @property {boolean} isDefault
 * @property {string}  tooltip
 */

/**
 * @typedef {Object<string, ToolbarItemConfigurationItem>} ToolbarItemConfiguration
 */

/**
 * @typedef  {object}                   ToolbarItem
 * @property {ToolbarItemConfiguration} configuration
 * @property {number}                   group
 * @property {string}                   icon
 * @property {string}                   id
 * @property {string}                   panelType
 * @property {boolean}                  showAlways
 * @property {string}                   tooltip
 */

/**
 * @typedef {Object<string, {name: string, value: string}>} FilterName
 */

/**
 * @typedef {Object<string, {name: string, type: string}>} FilterPLMCode
 */

/**
 * @typedef  {object} Filter
 * @property {string} created_at
 * @property {number} id
 * @property {string} name
 * @property {string} updated_at
 * @memberof Stitch
 */

/**
 * @typedef  {object} TagFilter
 * @property {string} created_at
 * @property {number} id
 * @property {string} name
 * @property {string} updated_at
 * @property {number} group_id
 * @property {number} season_id
 * @property {string} text
 */

export { DropdownItemShape, FileShape }

<script>
export default {
  name: 'Feature',

  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    canAccessFeature () {
      return this.$parent.$featureFlagsManager.canAccessFeature(
        this.name,
        this.options
      )
    }
  },

  render () {
    if (this.canAccessFeature) {
      return this.$slots.default
    } else {
      return null
    }
  }
}
</script>

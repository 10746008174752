// ////////////////////////////////////////////////////
// Index (JOB)
// ////////////////////////////////////////////////////

import actions from '@/store/modules/job/actions.js'
import getters from '@/store/modules/job/getters.js'
import mutations from '@/store/modules/job/mutations.js'
import getDefaultState from '@/store/modules/job/state.js'

export default {
  actions,
  getters,
  mutations,
  state: getDefaultState()
}

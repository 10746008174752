<template>
  <div class="stitch-image-viewer">
    <StitchLoader
      v-if="isLoading"
      size="medium"
      class="stitch-image-viewer__loader"
    />
    <img
      v-if="shouldShowImageThumbnail"
      decoding="async"
      :src="imageThumbnailUrl"
      alt="image-thumbnail-view"
      class="stitch-image-viewer__thumbnail"
    >
    <div
      ref="imageViewer"
      class="f-panzoom stitch-image-viewer__full"
    >
      <img
        loading="lazy"
        class="f-panzoom__content"
        decoding="async"
        :src="imageUrl"
        alt="image-full-view"
      >
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Panzoom } from '@fancyapps/ui/dist/panzoom/panzoom.umd.js'

import '@fancyapps/ui/dist/panzoom/panzoom.css'

export default {
  name: 'StitchImageViewer',

  props: {
    imageUrl: VueTypes.string.isRequired,
    imageThumbnailUrl: VueTypes.string.def(null)
  },

  data () {
    return {
      isLoading: Boolean(this.imageUrl)
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    shouldShowImageThumbnail () {
      return this.imageThumbnailUrl && this.isLoading
    }
  },

  async mounted () {
    // Wait for render
    await this.$nextTick()
    // eslint-disable-next-line no-new -- Panzoom has to be invoked with new to init the zoom
    new Panzoom(this.$refs.imageViewer, {
      click: 'toggleZoom',
      spinner: false,
      on: {
        afterLoad: () => {
          this.isLoading = false
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
$stitch-image-viewer-loader-z-index: 2;
$stitch-image-viewer-image-z-index: 1;

.stitch-image-viewer {
  @include flex-center;

  position: relative;
  width: 100%;
  height: 100%;
}

.stitch-image-viewer__loader {
  position: absolute;
  z-index: $stitch-image-viewer-loader-z-index;
  width: 100%;
  height: 100%;
}

.stitch-image-viewer__thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}

.stitch-image-viewer__full {
  position: absolute;
  z-index: $stitch-image-viewer-image-z-index;
  width: 100%;
  height: 100%;
  background: transparent;
}
</style>

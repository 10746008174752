import VueTypes from 'vue-types'
import { LIBRARY_TYPE } from '@/constants/libraryType'

/**
 * @type     {object}
 * @property {number}          id
 * @property {string}          [name]
 * @property {string}          [subtitle]
 * @property {string}          [libraryType]
 * @property {(string | null)} [createdAt]
 * @property {(string | null)} [updatedAt]
 * @property {boolean}         [isConverted=true]
 */
const ItemShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  name: VueTypes.string,
  subtitle: VueTypes.string,
  libraryType: VueTypes.oneOf([
    LIBRARY_TYPE.JOB,
    LIBRARY_TYPE.TRIM,
    LIBRARY_TYPE.BLOCK,
    LIBRARY_TYPE.STYLE,
    LIBRARY_TYPE.OPTION,
    LIBRARY_TYPE.FABRIC,
    LIBRARY_TYPE.STITCH
  ]),
  createdAt: VueTypes.oneOfType([String, null]),
  updatedAt: VueTypes.oneOfType([String, null]),
  isConverted: VueTypes.bool.def(true)
})

export { ItemShape }

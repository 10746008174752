<template>
  <StitchDialog
    class="detail-block"
    fullscreen
    visible
    @close="emitClose"
  >
    <template v-if="isLoadingItem">
      <StitchLoader />
    </template>
    <template v-else-if="item">
      <DetailHeader
        slot="title"
        :item="item"
        @edit-item="openEditBlockForm"
        @delete-item="deleteBlock"
      />
      <div class="detail-block__body">
        <template v-if="versions.length === 0">
          <div class="detail-block__body-status">
            <h2>This block doesn't have any versions.</h2>
            <p>
              <span v-if="item.filesDict.model3D.length > 0">
                <a
                  href="#"
                  @click="downloadAsset(item)"
                >download the block file</a>
                <span v-if="!isVendorUser">or</span>
              </span>
              <a
                v-if="!isVendorUser"
                href="#"
                @click="openVersionCreateForm"
              >add new version</a>
            </p>
          </div>
        </template>
        <DetailVersionList
          v-else
          :active-version="activeVersion"
          :can-edit="!isVendorUser"
          :versions="versions"
          @add-new-version="openVersionCreateForm"
          @select-version="handleSelectVersion"
          @rename-version="openVersionRenameForm"
          @delete-version="handleDeleteVersion"
          @set-main-version="setMainVersion"
          @trigger-download="triggerDownloadFile"
          @poll-versions-render-status="handleVersionPolling"
        />

        <!-- Update item form -->
        <FormGenerator
          ref="editBlockForm"
          :form-configuration="blockFormConfiguration"
          :options="formFilters"
          :original-data="formOriginalData"
          :show-form-generator="showEditBlockForm"
          @form-close="closeEditBlockForm"
          @form-complete="completeEditBlockForm"
          @execute-trigger="executeTrigger"
        />

        <!-- Create block version form -->
        <FormGenerator
          ref="createVersionForm"
          :additional-data="additionalVersionFormData"
          :default-data="defaultVersionFormData"
          :form-configuration="versionCreateFormConfiguration"
          :options="formFilters"
          :show-form-generator="showVersionCreateForm"
          @form-close="closeVersionCreateForm"
          @form-complete="completeVersionCreateForm"
          @execute-trigger="executeTrigger"
        />

        <!-- Rename style version form -->
        <FormGenerator
          ref="renameVersionForm"
          :additional-data="additionalVersionFormData"
          :form-configuration="versionRenameFormConfiguration"
          :original-data="defaultVersionFormData"
          :show-form-generator="showVersionRenameForm"
          @form-close="closeVersionRenameForm"
          @form-complete="completeVersionRenameForm"
        />

        <ViewList
          v-if="activeVersion"
          :active-version="activeVersion"
          :can-edit="!isVendorUser"
          :option="option"
          @update-view-image="handleUpdateViewImage"
          @delete-view-image="handleDeleteViewImage"
        />
      </div>
    </template>
    <LibraryDetailError
      v-if="errorStatus"
      :active-library="libraryType"
      :error-status="errorStatus"
    />
  </StitchDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { convertObjectKeysToCamelCase } from '@/services/utils'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import DetailHeader from '../DetailHeader'
import LibraryDetailError from '../LibraryDetailError'
import DetailVersionList from './components/DetailVersionList'
import ViewList from './view/ViewList'

// triggerDownload
import { DataUtils } from '@/mixins/utils.js'
import VueTypes from 'vue-types'
import { isVendorUser } from '@/services/permissions'
import { getFormConfig } from '@/services/formUtils'
import { FORM_NAME } from '@/constants/formName'
import { FILTER_TYPE } from '@/constants/filterType'
import FeatureFlags from '@/services/featureFlags'
import { IMAGE_VIEW_ANGLE } from '@/constants/image'
import placeholder360 from '@/assets/images/placeholder360.png'
import { BrowzwearPlugin } from '@/mixins/browzwearPlugin'

export default {
  name: 'DetailBlock',

  components: {
    LibraryDetailError,
    DetailVersionList,
    ViewList,
    DetailHeader
  },

  mixins: [DataUtils, BrowzwearPlugin],

  props: {
    itemId: VueTypes.number.isRequired
  },

  emits: ['close'],

  data () {
    return {
      activeVersion: null,
      isLoadingItem: false,
      showEditBlockForm: false,
      showVersionRenameForm: false,
      defaultDataForUpdate: null,
      defaultVersionFormData: null,
      showVersionCreateForm: false,
      formStepBlock: {
        DETAILS: 'details'
      },
      errorStatus: null,
      isVendorUser: isVendorUser()
    }
  },

  computed: {
    ...mapGetters([
      'getItemDetail',
      'getAvailableFilters',
      'getViewsByVersionId',
      'getMainVersionFromActiveBlock',
      'getVersionsUploadInProgress'
    ]),

    /**
     * @returns {object}
     */
    item () {
      const item = this.getItemDetail()

      return item
    },

    /**
     * @returns {boolean}
     */
    canAccessRenderEngineChoice () {
      return FeatureFlags.canTeamAccessRenderEngineChoice()
    },

    /**
     * @returns {object} OriginalData
     */
    formOriginalData () {
      // this is meant to normalize attributes for the FormGenerator
      const { attributes, ...formOriginalData } = {
        ...this.item,
        ...this.item.attributes
      }

      return formOriginalData
    },

    /**
     * @returns {Array}
     */
    formFilters () {
      if (this.canAccessRenderEngineChoice) {
        return convertObjectKeysToCamelCase({
          ...FILTER_TYPE.RENDER_ENGINE_FILTERS,
          ...this.getAvailableFilters()
        })
      }

      return convertObjectKeysToCamelCase({
        ...this.getAvailableFilters()
      })
    },

    /**
     * @returns {object}
     */
    blockFormConfiguration () {
      return getFormConfig(this.libraryType)
    },

    /**
     * @returns {object}
     */
    versionRenameFormConfiguration () {
      return getFormConfig(FORM_NAME.BLOCK_VERSION_RENAME)
    },

    /**
     * @returns {Array}
     */
    pendingVersions () {
      return this.getVersionsUploadInProgress[this.itemId]
    },

    /**
     * @returns {Array}
     */
    versions () {
      if (!this.getItemDetail()) {
        // early return if getItemDetail is empty (when fetchItemDetail within created() hasn't been resolved yet)
        return
      }

      const versions = [...this.getItemDetail().versions]

      if (this.pendingVersions) {
        this.pendingVersions.forEach(pendingVersion => {
          if (pendingVersion.isMain) {
            return versions.unshift(pendingVersion)
          }

          return versions.push(pendingVersion)
        })
      }

      return versions
    },

    /**
     * @returns {object}
     */
    mainVersion () {
      return this.getMainVersionFromActiveBlock
    },

    /**
     * @returns {Array}
     */
    views () {
      if (this.activeVersion) {
        const { id } = this.activeVersion
        const views = this.getViewsByVersionId(id)

        // if there are any slideshow images,
        // add a placeholder for the slideshow
        if (this.activeVersion.slideshowImages.length > 0) {
          const hasView360Placeholder = views.some(
            img => img.viewName === IMAGE_VIEW_ANGLE.VIEW360
          )

          // create an option's image-like object for 360 placeholder
          if (!hasView360Placeholder) {
            const view360Placeholder = {
              id: Math.ceil(Math.random() * 1000000),
              isThumbnail: false,
              viewName: IMAGE_VIEW_ANGLE.VIEW360,
              fileUrl: placeholder360
            }
            views.unshift(view360Placeholder)
          }
        }

        return views
      }

      return []
    },

    /**
     * @returns {object}
     */
    option () {
      if (this.views) {
        return this.getBlockOptionDetails(this.activeVersion, this.views)
      }

      return {}
    },

    /**
     * @returns {string}
     */
    libraryType () {
      return LIBRARY_TYPE.BLOCK
    },

    /**
     * @returns {object}
     */
    additionalVersionFormData () {
      return {
        divisionId: this.item.divisionId,
        blockId: this.itemId,
        versionId:
          (this.defaultVersionFormData && this.defaultVersionFormData.id) ||
          undefined
      }
    },

    /**
     * @returns {object}
     */
    versionCreateFormConfiguration () {
      const config = getFormConfig(FORM_NAME.BLOCK_VERSION_CREATE)
      config.steps[0].fields[1].visible = this.canAccessRenderEngineChoice

      return config
    }
  },

  async created () {
    if (this.itemId !== null) {
      this.isLoadingItem = true

      try {
        await this.fetchItemDetail({
          itemId: this.itemId,
          libraryType: this.libraryType
        })
      } catch (error) {
        if (error.response) {
          this.errorStatus = error.response.status
        } else {
          throw error
        }
      }

      if (this.mainVersion) {
        this.activeVersion = this.mainVersion
      }

      this.isLoadingItem = false
    }
  },

  beforeDestroy () {
    this.clearItemDetail({ libraryType: this.libraryType })
  },

  methods: {
    ...mapActions([
      'fetchItemDetail',
      'clearItemDetail',
      'deleteItem',
      'deleteBlockVersion',
      'setBlockMainVersion',
      'fetchVersionRenderStatus',
      'updateViewImage',
      'deleteBlockViewImage'
    ]),

    /**
     * @param {string} versionsId
     */
    async handleVersionPolling (versionsId) {
      await this.fetchVersionRenderStatus(versionsId)
    },

    /**
     */
    emitClose () {
      this.$emit('close')
    },

    /**
     * @param {object} payload
     */
    handleSelectVersion (payload) {
      const { version } = payload

      this.activeVersion = version
    },

    /**
     * @param {object} payload
     */
    setMainVersion (payload) {
      this.setBlockMainVersion(payload)
    },

    /**
     * @param {object} payload
     */
    triggerDownloadFile (payload) {
      this.triggerDownload(payload.url)
    },

    /**
     */
    openEditBlockForm () {
      this.showEditBlockForm = true
    },

    /**
     */
    closeEditBlockForm () {
      this.defaultDataForUpdate = null
      this.showEditBlockForm = false
    },

    /**
     */
    closeVersionRenameForm () {
      this.defaultVersionFormData = null
      this.showVersionRenameForm = false
    },

    /**
     * @param {number} success
     */
    completeVersionRenameForm (success) {
      if (success) {
        this.closeVersionRenameForm()
      }
    },

    /**
     * @param {boolean} success
     */
    completeEditBlockForm (success) {
      if (success) {
        this.closeEditBlockForm()
      }
    },

    /**
     * @param {object} payload
     */
    openVersionCreateForm (payload) {
      if (payload) {
        this.defaultVersionFormData = {
          ...this.defaultVersionFormData,
          ...payload
        }
      }

      this.showVersionCreateForm = true
    },

    /**
     */
    closeVersionCreateForm () {
      this.defaultVersionFormData = null
      this.showVersionCreateForm = false
    },

    /**
     * @param {string} triggerMethodName
     * @param {object} triggerData
     */
    async executeTrigger (triggerMethodName, triggerData) {
      await this[triggerMethodName](triggerData)
    },

    /**
     * @param {boolean} success
     */
    completeVersionCreateForm (success) {
      if (success) {
        this.closeVersionCreateForm()
      }
    },

    /**
     * @param {object} payload
     */
    async deleteBlock (payload) {
      await this.deleteItem(payload)

      this.emitClose()

      this.$message({
        showClose: true,
        message: `${payload.name} has been deleted`
      })
    },

    /**
     * @param {object} payload
     */
    openVersionRenameForm (payload) {
      this.defaultVersionFormData = {
        ...payload
      }
      this.showVersionRenameForm = true
    },

    /**
     * @param {object} payload
     */
    async handleDeleteVersion (payload) {
      const { blockId, versionId, versionName } = payload

      await this.deleteBlockVersion({
        blockId,
        versionId
      })

      this.$message({
        showClose: true,
        message: `${versionName} has been deleted`
      })
    },

    /**
     * @param {object} payload
     */
    handleUpdateViewImage (payload) {
      this.updateViewImage(payload)
    },

    /**
     * @param {object} item
     */
    downloadAsset (item) {
      if (this.browzwearAPI) {
        this.sendDataToBrowzwear(item, 0)
      } else {
        this.triggerDownload(item.filesDict.model3D[0].assets_url)
      }
    },

    /**
     * @param {object} payload
     */
    handleDeleteViewImage (payload) {
      this.deleteBlockViewImage(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-block {
  /deep/ .el-dialog {
    max-width: spacing(190);
    margin: auto;
    padding: 0;
  }

  /deep/ .el-dialog__body {
    overflow-y: hidden;
  }
}

.detail-block__body {
  display: flex;
  width: 100%;
  border-top: $border-divider;
}

.detail-block__body-status {
  @include position-absolute;

  width: 100%;
  margin: spacing(1);
  padding: spacing(1);
  color: $grey-dark;
  text-align: center;
  word-break: break-word;

  > * {
    margin-bottom: spacing(1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $blue;
  }

  p::first-letter {
    text-transform: capitalize;
  }
}
</style>

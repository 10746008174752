import { HTTP } from '@/services/axios'
import { downloadExcelFile } from '@/services/utils'
/**
 * @returns {object}
 */
const whoami = async () => {
  const response = await HTTP.get('/auth/whoami')

  return response.data
}

/**
 * @returns {object[]}
 */
const fetchAllUsers = async () => {
  const response = await HTTP.get('/manage/users')

  return response.data
}

/**
 * @param   {number}   page
 * @param   {number}   limit
 * @param   {string}   query
 * @param   {string}   sorting
 *
 * @returns {object[]}
 */
const fetchUsers = async (page = 1, limit = 10, query = '', sorting) => {
  // For page 1 we have offset 0, then for page 2 is offset 10, page 3 is 20, etc.
  const offset = page === 1 ? 0 : (page - 1) * limit
  const response = await HTTP.get(
    `/manage/users?search=${query}&ordering=${sorting}`,
    {
      params: { limit, offset }
    }
  )

  return response.data
}

/**
 * @returns {object[]}
 */
const fetchTeams = async () => {
  const response = await HTTP.get('/manage/teams')

  return response.data
}

/**
 * @returns {object[]}
 */
const fetchVendors = async () => {
  const response = await HTTP.get('/manage/vendors')

  return response.data
}

/**
 * @param   {number}   vendorId
 *
 * @returns {object[]}
 */
const fetchVendorById = async vendorId => {
  const response = await HTTP.get(`/manage/vendors/${vendorId}`)

  return response.data
}

/**
 * @param   {number}   teamId
 *
 * @returns {object[]}
 */
const fetchAssignableVendorsByTeam = async teamId => {
  const response = await HTTP.get(`/assignable-vendors/${teamId}`)

  return response.data
}

/**
 * @param   {number}   teamId
 *
 * @returns {object[]}
 */
const fetchMentionableUsers = async teamId => {
  const response = await HTTP.get(`/authentication/mentionable-users/${teamId}`)

  return response.data
}

/**
 * @param   {number}   teamId
 * @param   {number}   page
 * @param   {number}   limit
 * @param   {string}   query
 * @param   {string}   sorting
 *
 * @returns {object[]}
 */
const fetchUsersByTeam = async (
  teamId,
  page = 1,
  limit = 10,
  query = '',
  sorting
) => {
  // For page 1 we have offset 0, then for page 2 is offset 10, page 3 is 20, etc.
  const offset = page === 1 ? 0 : (page - 1) * limit
  const response = await HTTP.get(
    `/manage/teams/${teamId}/users?search=${query}&ordering=${sorting}`,
    {
      params: { limit, offset }
    }
  )

  return response.data
}

/**
 * @param   {number}   vendorId
 * @param   {number}   page
 * @param   {number}   limit
 * @param   {string}   query
 * @param   {string}   sorting
 *
 * @returns {object[]}
 */
const fetchUsersByVendor = async (
  vendorId,
  page = 1,
  limit = 10,
  query = '',
  sorting
) => {
  const offset = page === 1 ? 0 : (page - 1) * limit
  const response = await HTTP.get(
    `/manage/vendors/${vendorId}/users?search=${query}&ordering=${sorting}`,
    {
      params: { limit, offset }
    }
  )

  return response.data
}

/**
 * @param   {number}   page
 * @param   {number}   limit
 * @param   {string}   query
 * @param   {string}   sorting
 *
 * @returns {object[]}
 */
const fetchRegistrations = async (
  page = 1,
  limit = 10,
  query = '',
  sorting
) => {
  // For page 1 we have offset 0, then for page 2 is offset 10, page 3 is 20, etc.
  const offset = page === 1 ? 0 : (page - 1) * limit
  const response = await HTTP.get(
    `/manage/vendors/registrations?search=${query}&ordering=${sorting}`,
    {
      params: { limit, offset }
    }
  )

  return response.data
}

/**
 * @param   {number}  userId
 * @param   {string}  email
 * @param   {boolean} isActive
 * @param   {number}  roleId
 *
 * @returns {object}
 */
const updateUser = async (userId, email, isActive, roleId) => {
  const response = await HTTP.patch(`/manage/users/${userId}`, {
    email: email,
    is_active: isActive,
    role_id: roleId
  })

  return response.data
}

/**
 * @param   {number} vendorId
 * @param   {Array}  teamIds
 *
 * @returns {object}
 */
const updateVendorTeams = async (vendorId, teamIds) => {
  const response = await HTTP.patch(`/manage/vendors/${vendorId}/update-team`, {
    teams: teamIds
  })

  return response.data
}

/**
 * @param   {number} userId
 * @param   {Array}  teamIds
 *
 * @returns {object}
 */
const changeUserTeams = async (userId, teamIds) => {
  const response = await HTTP.post(`/manage/users/${userId}/change_teams`, {
    team_ids: teamIds
  })

  return response.data
}

/**
 * @param   {string} vendorEmail
 * @param   {number} vendorCompanyId
 *
 * @returns {object}
 */
const assignVendorUserToCompany = async (vendorEmail, vendorCompanyId) => {
  const response = await HTTP.post(
    `/manage/vendors/${vendorCompanyId}/users/`,
    {
      email: vendorEmail
    }
  )

  return response.data
}

/**
 * @param   {number}       teamId
 * @param   {number}       userId
 * @param   {boolean}      canManageUsers
 *
 * @returns {AxiosPromise}
 */
const updateMembershipForUser = async (teamId, userId, canManageUsers) => {
  const response = await HTTP.patch(`/manage/teams/${teamId}/users/${userId}`, {
    can_manage_users: canManageUsers
  })

  return response.data
}

/**
 * @param   {string} email
 * @param   {number} roleId
 * @param   {Array}  teamIds
 *
 * @returns {object}
 */
const addUser = async (email, roleId, teamIds) => {
  const response = await HTTP.post('/manage/users/', {
    email: email,
    role_id: roleId,
    team_ids: teamIds
  })

  return response.data
}

/**
 * @param   {string} email
 * @param   {Array}  teamId
 *
 * @returns {object}
 */
const inviteUser = async (email, teamId) => {
  const response = await HTTP.post(`/manage/teams/${teamId}/users/`, {
    email: email
  })

  return response.data
}

/**
 * @param   {number}       userId
 *
 * @returns {AxiosPromise}
 */
const deleteUser = async userId => {
  return await HTTP.delete(`/manage/users/${userId}`)
}

/**
 * @param   {number}       teamId
 * @param   {number}       userId
 *
 * @returns {AxiosPromise}
 */
const deleteUserFromTeam = async (teamId, userId) => {
  return await HTTP.delete(`/manage/teams/${teamId}/users/${userId}`)
}

/**
 * @param   {number}       vendorId
 * @param   {number}       userId
 *
 * @returns {AxiosPromise}
 */
const deleteUserFromVendor = async (vendorId, userId) => {
  return await HTTP.delete(`/manage/vendors/${vendorId}/users/${userId}`)
}

/**
 *
 */
const exportUsers = async () => {
  const response = await HTTP.get('/manage/users/export/', {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob'
  })

  downloadExcelFile(response)
}

/**
 * @param {number} teamId
 * @param {string} teamName
 */
const exportTeamUsers = async (teamId, teamName) => {
  const response = await HTTP.get(`/manage/teams/${teamId}/users/export/`, {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob'
  })

  downloadExcelFile(response, teamName)
}

const userAPI = {
  whoami,
  addUser,
  updateUser,
  fetchAllUsers,
  fetchUsers,
  fetchUsersByTeam,
  fetchUsersByVendor,
  fetchRegistrations,
  fetchAssignableVendorsByTeam,
  deleteUser,
  fetchTeams,
  fetchVendors,
  fetchVendorById,
  fetchMentionableUsers,
  deleteUserFromTeam,
  deleteUserFromVendor,
  inviteUser,
  changeUserTeams,
  exportUsers,
  exportTeamUsers,
  updateMembershipForUser,
  updateVendorTeams,
  assignVendorUserToCompany
}

export default userAPI

import FeatureFlags from '@/services/featureFlags'

export const FiltersPersistency = {
  methods: {
    /**
     * @param {object} filter
     */
    setAppliedFilterToLocalStorage (filter) {
      const item = localStorage.getItem(`stitch:dh:${filter.libraryType}`)
      const filters = (item && JSON.parse(item)) || {}

      if (
        isNullOrUndefined(filter.value) ||
        (filter.value &&
          Array.isArray(filter.value) &&
          filter.value.length === 0)
      ) {
        delete filters[filter.filterGroupName]
      } else {
        if (Array.isArray(filter.value) === false) {
          filters[filter.filterGroupName] = [filter.value]
        } else {
          filters[filter.filterGroupName] = filter.value
        }
      }

      localStorage.setItem(
        `stitch:dh:${filter.libraryType}`,
        JSON.stringify(filters)
      )

      /**
       * @param   {*}       valueToTest
       *
       * @returns {boolean}
       */
      function isNullOrUndefined (valueToTest) {
        return valueToTest === null || valueToTest === undefined
      }
    },

    /**
     * @param   {string} libraryType
     *
     * @returns {object}
     */
    getAppliedFiltersToLocalStorage (libraryType) {
      const item = localStorage.getItem(`stitch:dh:${libraryType}`)

      return (item && JSON.parse(item)) || {}
    },

    /**
     * @param {string} libraryType
     */
    resetAppliedFiltersToLocalStorage (libraryType) {
      localStorage.removeItem(`stitch:dh:${libraryType}`)
    },

    /**
     * @param {object} payload
     */
    buildUrl (payload) {
      const newQueryUrl = JSON.parse(JSON.stringify(this.$route.query))
      const filterGroupName = payload.filterGroupName
      const value = payload.value

      if (filterGroupName === 'search') {
        if (value) {
          newQueryUrl[filterGroupName] = value
        } else {
          delete newQueryUrl.search
        }
      } else {
        if (isNotNullNOrUndefined(value)) {
          if (Array.isArray(value)) {
            if (value.length !== 0) {
              newQueryUrl[filterGroupName] = value.join(',')
            } else {
              delete newQueryUrl[filterGroupName]
            }
          } else {
            newQueryUrl[filterGroupName] = value
          }
        } else {
          delete newQueryUrl[filterGroupName]
        }
      }

      this.$router.replace(
        {
          query: newQueryUrl
        },
        () => {}
      )

      /**
       * @param   {*}       value
       *
       * @returns {boolean}
       */
      function isNotNullNOrUndefined (value) {
        return value !== null && value !== undefined
      }
    },

    /**
     * @returns {object}
     */
    parseUrl () {
      const urlParameters = {}

      const queryUrl = JSON.parse(JSON.stringify(this.$route.query))

      for (const key in queryUrl) {
        if (key === 'search') {
          urlParameters[key] = queryUrl[key]
        } else {
          if (queryUrl[key] && queryUrl[key].length !== 0) {
            const urlParameter = queryUrl[key].split(',').map(value => {
              try {
                return JSON.parse(value)
              } catch (error) {
                return value
              }
            })

            if (urlParameter.length !== 0) {
              urlParameters[key] = urlParameter
            }
          }
        }
      }

      return urlParameters
    },

    /**
     */
    resetUrl () {
      /* eslint-disable camelcase -- Not all variables need to be in camel case in this situation. Some are supposed to be in snake case instead */
      const { search, group_id, division_id } = this.$route.query
      let query = { search, group_id, division_id }
      /* eslint-enable -- Enable camel case check again */

      if (FeatureFlags.canUserAccessWorkspaces()) {
        query = { search }
      }

      this.$router.replace({ query }, () => {})
    },

    /**
     * @param {object} payload
     */
    setPersistentFilters (payload) {
      this.setAppliedFilterToLocalStorage({
        libraryType: payload.libraryType,
        filterGroupName: payload.filterGroupName,
        value: payload.value
      })

      this.buildUrl({
        filterGroupName: payload.filterGroupName,
        value: payload.value
      })
    },

    /**
     * @param   {string} libraryType
     *
     * @returns {object}
     */
    getPersistentFilters (libraryType) {
      const localStorageFilters =
        this.getAppliedFiltersToLocalStorage(libraryType)

      const urlFilters = this.parseUrl()

      if (Object.keys(urlFilters).length > 0) {
        return urlFilters
      } else {
        return localStorageFilters
      }
    },

    /**
     * @param {string} libraryType
     */
    resetPersistentFilters (libraryType) {
      this.resetAppliedFiltersToLocalStorage(libraryType)

      this.resetUrl()
    }
  }
}

import TheHeader from '@/components/layout/TheHeader'
import LibraryItems from '@/components/library/LibraryItems'
import StitchIcon from '@/components/common/StitchIcon'
import DetailBlock from '@/components/library/DetailBlock'
import DetailStyle from '@/components/library/DetailStyle'
import StitchLoader from '@/components/common/StitchLoader'
import TheAsideRight from '@/components/layout/TheAsideRight'
import StyleComments from '@/components/style/StyleComments'
import StitchTooltip from '@/components/common/StitchTooltip'
import StitchColorRun from '@/components/common/StitchColorRun'
import StitchDropdown from '@/components/common/StitchDropdown'
import StitchImageSlider from '@/components/common/StitchImageSlider'

const components = [
  TheHeader,
  StitchIcon,
  DetailBlock,
  DetailStyle,
  LibraryItems,
  StitchLoader,
  StitchTooltip,
  TheAsideRight,
  StyleComments,
  StitchColorRun,
  StitchDropdown,
  StitchImageSlider
]

/**
 * @param {Vue} Vue
 */
const install = Vue => {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install
}

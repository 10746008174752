import TrackingBase from './TrackingBase'
import Mixpanel from 'mixpanel-browser'

class TrackingMixpanel extends TrackingBase {
  /**
   * @returns {object}
   */
  getAnalytics () {
    return window.mixpanel
  }

  /**
   */
  loadScript () {
    if (window.mixpanel) {
      this.log('Mixpanel script already loaded.')
    } else {
      Mixpanel.init(this.id)
      window.mixpanel = Mixpanel
    }
  }

  /**
   * @param {string | null} [payload={}]
   * @param {string | null} [payload.event='event-custom']
   * @param {string | null} [payload.object=null]
   * @param {string | null} [payload.category=null]
   * @param {string | null} [payload.action=null]
   * @param {string | null} [payload.label=null]
   * @param {string | null} [payload.value=null]
   * @param {string | null} [payload.item=null]
   * @param {...*}          [payload.rest]
   */
  trackEvent ({
    event = 'Unnamed Event',
    object = null,
    category = null,
    action = null,
    label = null,
    value = null,
    item = null,
    ...rest
  } = {}) {
    const fixedItem = this.getItemAdditionalInfo(item)

    super.trackEvent({
      event,
      object,
      category,
      action,
      label,
      value,
      item,
      fixedItem,
      ...rest
    })

    if (this.isEnabled()) {
      this.getAnalytics().track(event, {
        category,
        action,
        label,
        value,
        item: fixedItem,
        'actual-item': item,
        ...rest
      })
    }
  }

  /**
   * @param {string | number} userIdentifier
   * @param {UserShape}       user
   */
  identifyUser (userIdentifier, user) {
    const { email, id } = user
    const analytics = this.getAnalytics()

    super.identifyUser(userIdentifier, user)

    if (this.isEnabled() && email && id && userIdentifier && analytics) {
      analytics.identify(email)
    }
  }
}

export default TrackingMixpanel

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getNotificationsSettingsByType = state => type =>
  state.notificationsSettings[type]

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getNotificationsSettings = state => state.notificationsSettings

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getNotifications = state => state.notifications

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getUnreadNotificationsCount = state => state.unreadNotificationsCount

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getNotificationsCount = state => state.notificationsCount

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getCurrentNotificationsPage = state => state.currentNotificationsPage

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getNotificationsBadgeVisibility = state =>
  state.notificationsBadgeVisibility

export default {
  getNotificationsSettingsByType,
  getNotificationsSettings,
  getNotifications,
  getUnreadNotificationsCount,
  getNotificationsCount,
  getCurrentNotificationsPage,
  getNotificationsBadgeVisibility
}

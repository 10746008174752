import AssetTypeUtils from './assetTypeUtils'

let controller = new AbortController()
let signal = controller.signal

const fetchParameters = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Stitch-Client': 'Stitch-Asset-Browser'
  },
  credentials: 'include'
}

let previousPage = -1

const AssetTypes = AssetTypeUtils.AssetTypes

/**
 * @param   {string} assetType
 *
 * @returns {string}
 */
const getApiBaseUrl = assetType =>
  AssetTypeUtils.getAssetTypeConfiguration(assetType).apiBaseUrl

/**
 * @param   {string} assetType
 * @param   {string} search
 * @param   {number} limit
 * @param   {number} page
 *
 * @returns {object}
 */
const fetchFilteredAssets = (assetType, search, limit, page) => {
  switch (assetType) {
    case AssetTypes.JOB:
      return fetchFilteredJobs(search, limit, page)
    case AssetTypes.STYLE:
      return fetchFilteredStyles(search, limit, page)
    default:
      console.error(`Unexpected type ${assetType}.`)

      return null
  }
}

/**
 * @param   {string} assetType
 * @param   {number} id
 *
 * @returns {object}
 */
const fetchAsset = (assetType, id) => {
  switch (assetType) {
    case AssetTypes.JOB:
      return fetchJob(id)
    case AssetTypes.STYLE:
      return fetchStyle(id)
    default:
      console.error(`Unexpected type ${assetType}.`)

      return null
  }
}

/**
 * @param   {string}   name
 * @param   {number}   limit
 * @param   {number}   page
 *
 * @returns {object[]}
 */
const fetchFilteredJobs = async (name, limit, page) => {
  const url = new URL(`${getApiBaseUrl(AssetTypes.JOB)}/jobs/?preview`)

  const params = {
    type: 'dh-blend-file-task,render-fbx-zip,dh-render-vsp-user,dh-render-plugin-vray',
    personal: true,
    name,
    page,
    limit
  }

  const data = await fetchFilteredAssetsHelper(url, params)

  if (data && data.results) {
    return data.results
  } else {
    return data
  }
}

/**
 * @param   {number} id
 *
 * @returns {object}
 */
const fetchJob = id => {
  const url = new URL(
    `${getApiBaseUrl(AssetTypes.JOB)}/jobs/${id}/?preview&include-task-files`
  )

  return fetchAssetHelper(url)
}

/**
 * @param   {string} search
 * @param   {number} limit
 * @param   {number} page
 *
 * @returns {object}
 */
const fetchFilteredStyles = async (search, limit, page) => {
  const url = new URL(`${getApiBaseUrl(AssetTypes.STYLE)}/styles/`)

  const params = {
    search,
    page,
    limit
  }

  const data = await fetchFilteredAssetsHelper(url, params)

  if (data && data.results) {
    return data.results
  } else {
    return data
  }
}

/**
 * @param   {number} id
 *
 * @returns {object}
 */
const fetchStyle = id => {
  const url = new URL(`${getApiBaseUrl(AssetTypes.STYLE)}/styles/${id}`)

  return fetchAssetHelper(url)
}

/**
 * @param   {URL}      url
 * @param   {object}   params
 * @param   {number}   params.page
 * @param   {number}   params.limit
 *
 * @returns {object[]}
 */
const fetchFilteredAssetsHelper = async (
  url,
  { page, limit, ...otherParams }
) => {
  if (page <= previousPage) {
    controller.abort()
    controller = new AbortController()
    signal = controller.signal
  }

  previousPage = page
  otherParams.limit = limit
  otherParams.offset = (page - 1) * limit

  Object.entries(otherParams).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  )

  try {
    const response = await fetch(url, { ...fetchParameters, signal })

    if (response.status === 403) {
      return false
    }

    return response.json()
  } catch (error) {
    console.warn(error)

    return null
  }
}

/**
 * @param   {URL}    url
 *
 * @returns {object}
 */
const fetchAssetHelper = async url => {
  const response = await fetch(url, fetchParameters)

  return response.json()
}

const serverAPI = {
  fetchFilteredAssets,
  fetchAsset
}

export default serverAPI

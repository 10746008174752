<template>
  <div
    ref="colorRunContainer"
    :class="['stitch-color-run', { 'stitch-color-run--loading': isLoading }]"
  >
    <StitchTooltip
      :message="suggestionMessage"
      :is-disabled="tooltipDisabled"
    >
      <StitchLoader v-if="isLoading" />

      <!-- CrossOrigin needs to be before the src or else, an error pops up when drawing on canvas -->
      <!-- Similar to this : https://github.com/facebook/react/issues/14035 -->
      <!-- https://stackoverflow.com/questions/23123237/drawing-images-to-canvas-with-img-crossorigin-anonymous-doesnt-work -->
      <img
        v-for="(image, imageIndex) in colorRun"
        :key="imageIndex"
        ref="colorRunImage"
        crossorigin="use-credentials"
        :src="image"
        :alt="getCurrentColorwayTitle(imageIndex)"
        :draggable="false"
        :class="[
          'stitch-color-run__image',
          { 'stitch-color-run__image--clickable': hasOriginalImages }
        ]"
        @click="handleClick(imageIndex)"
      >
    </StitchTooltip>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import ColorRunUtils from '@/services/colorRunUtils'
import Sentry from '@/services/sentry'

export default {
  name: 'StitchColorRun',

  props: {
    images: VueTypes.array.isRequired.def([]),
    tooltipDisabled: VueTypes.bool.def(false)
  },

  data () {
    return {
      isLoading: false,
      imageParameters: null,
      suggestionMessage: [
        'The color-run can be changed by',
        "swapping the colorways' order 👌"
      ],
      abortController: new AbortController()
    }
  },

  computed: {
    /**
     * @returns {object}
     */
    colorRun () {
      return ColorRunUtils.getColorRunFormatted(this.images)
    },

    /**
     * @returns {number}
     */
    colorRunLength () {
      return this.colorRun.length
    },

    /**
     * @returns {boolean}
     */
    hasOriginalImages () {
      return this.images.length > 0
    }
  },

  watch: {
    /**
     * @param {object} newValue
     * @param {object} oldValue
     */
    colorRun (newValue, oldValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.imageParameters = null
        this.setImagesPosition()
      }
    }
  },

  mounted () {
    Sentry.addBreadcrumb(
      'Mounting, are the parameters null?',
      this.imageParameters === null
    )
    this.setImagesPosition()
  },

  beforeDestroy () {
    if (this.isLoading) {
      this.abortController.abort()
    }
  },

  methods: {
    /**
     */
    async setImagesPosition () {
      this.isLoading = true

      await this.$nextTick()

      this.$refs.colorRunContainer.style.scale = null
      this.$refs.colorRunContainer.style.overflow = null
      this.$refs.colorRunContainer.style.left = null

      const containerSize = this.$refs.colorRunContainer.getBoundingClientRect()
      let images

      try {
        images = await ColorRunUtils.getImagePromises(
          this.colorRun,
          this.abortController
        )
      } catch (error) {
        this.isLoading = false

        return
      }

      if (!this.imageParameters) {
        this.imageParameters = ColorRunUtils.getColorRunImageParameters(
          images,
          containerSize
        )
      }

      if (this.imageParameters) {
        const { offsetPerImage, imageSizes } = this.imageParameters
        let lastImageLeft

        if (!offsetPerImage && imageSizes.length > 1) {
          Sentry.addBreadcrumb(
            `offsetPerImage: ${offsetPerImage}. It happened with ${imageSizes.length} images.`
          )
        }

        this.$refs.colorRunImage.forEach((image, index) => {
          if (!imageSizes[index]) {
            Sentry.addBreadcrumb(
              'Element array length',
              this.$refs.colorRunImage
            )
            Sentry.addBreadcrumb('Image size array', imageSizes)
            Sentry.addBreadcrumb('Loop index', index)
          }

          const { width, height } = imageSizes[index]
          lastImageLeft = index * offsetPerImage

          image.style.left = `${lastImageLeft}px`
          image.style.top = null
          image.style.width = `${width}px`
          image.style.height = `${height}px`
          image.style.zIndex = `${this.colorRunLength - index}`

          if (index === this.colorRunLength - 1) {
            const colorRunWidth = lastImageLeft + width

            if (colorRunWidth > containerSize.width) {
              const scale = containerSize.width / colorRunWidth
              this.$refs.colorRunContainer.style.overflow = 'visible'
              this.$refs.colorRunContainer.style.left = `${
                -1 * ((colorRunWidth - containerSize.width) / 2) * scale
              }px`

              this.$refs.colorRunContainer.style.scale = `${scale}`
            }

            this.$refs.colorRunContainer.style.width = `${colorRunWidth}px`
          }
        })
      }

      this.isLoading = false
    },

    /**
     * @param   {number} imageIndex
     *
     * @returns {string}
     */
    getCurrentColorwayTitle (imageIndex) {
      return `color run: ${this.colorRunLength - imageIndex}`
    },

    /**
     * @param {number} imageIndex
     */
    handleClick (imageIndex) {
      if (this.hasOriginalImages) {
        this.$emit('image-click', imageIndex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stitch-color-run {
  position: relative;
  z-index: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.stitch-color-run__image {
  position: absolute;
  top: $position-center;
  transform: translateY(-50%);
}

.stitch-color-run__image--clickable {
  cursor: pointer;
}

.stitch-color-run--loading {
  @include flex-center;

  min-width: spacing(30);

  .stitch-color-run__image {
    visibility: hidden;
  }
}
</style>

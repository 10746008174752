<template>
  <ElementPopover
    :value="showSuggestions"
    width="200"
    trigger="manual"
    placement="bottom"
  >
    <div
      slot="reference"
      class="tag-editor el-input__inner"
      @click="showEditor"
    >
      <div class="tag-editor__tags">
        <div
          v-for="(tag, tagIndex) in value"
          :key="tagIndex"
          class="tag-editor__tag-wrapper"
        >
          <ElementTag
            closable
            type="info"
            size="mini"
            class="tag-editor__tag"
            @close="removeTag(tag)"
          >
            <span class="tag-editor__tag-content">{{ tag }}</span>
          </ElementTag>
        </div>
      </div>

      <input
        v-show="isEditorActive"
        ref="inputField"
        v-model.trim="userInput"
        v-click-outside="closeEditor"
        class="tag-editor__input"
        size="mini"
        @keydown.delete="handleKeydownDelete"
        @keydown.enter="handleKeydownEnter"
      >
      <ElementButton
        v-show="!isEditorActive"
        class="tag-editor__button"
        size="small"
        @click="showEditor"
      />
    </div>

    <ul class="tag-editor__suggestions el-dropdown-menu">
      <li
        v-for="(suggestion, suggestionIndex) in suggestionsFiltered"
        :key="suggestionIndex"
        class="tag-editor__suggestion el-dropdown-menu__item"
        @click="addTag(suggestion)"
      >
        {{ suggestion }}
      </li>
      <li
        v-if="hasInput && !isUserInputInSuggestions"
        class="tag-editor__suggestion el-dropdown-menu__item"
        @click="addTag(userInput)"
      >
        <strong>Add '{{ userInput }}'</strong>
      </li>
    </ul>
  </ElementPopover>
</template>

<script>
import ClickOutside from 'element-ui/src/utils/clickoutside'

export default {
  name: 'StitchTagsEditor',

  directives: { ClickOutside },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    suggestions: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    userInput: '',
    isEditorActive: false
  }),

  computed: {
    /**
     * @returns {boolean}
     */
    showSuggestions () {
      if (this.hasInput || this.hasSuggestions) {
        return this.isEditorActive
      } else {
        return false
      }
    },

    /**
     * @returns {boolean}
     */
    hasSuggestions () {
      return this.suggestionsFiltered.length > 0
    },

    /**
     * @returns {boolean}
     */
    hasInput () {
      return this.userInput !== ''
    },

    /**
     * @returns {boolean}
     */
    suggestionsFiltered () {
      return this.suggestions.filter(suggestion => {
        if (this.value.includes(suggestion)) {
          return false
        } else {
          return this.hasInput ? suggestion.includes(this.userInput) : true
        }
      })
    },

    /**
     * @returns {boolean}
     */
    isUserInputInSuggestions () {
      const suggestion = this.suggestions.find(
        suggestion => suggestion === this.userInput
      )

      if (suggestion) {
        return true
      } else {
        return false
      }
    },

    /**
     * @returns {boolean}
     */
    isTagNew () {
      return this.hasInput && !this.isUserInputInSuggestions
    }
  },

  watch: {
    /**
     */
    value () {
      this.resetState()
    }
  },

  methods: {
    /**
     */
    closeEditor () {
      this.isEditorActive = false
    },

    /**
     */
    resetState () {
      this.blurInputField()
      this.isEditorActive = false
      this.userInput = ''

      this.$nextTick().then(() => {
        this.focusInputField()
      })
    },

    /**
     */
    blurInputField () {
      this.$refs.inputField.blur()
    },

    /**
     */
    focusInputField () {
      this.$refs.inputField.focus()
    },

    /**
     */
    handleKeydownDelete () {
      const tagCount = this.value.length

      if (!this.hasInput && tagCount > 0) {
        this.removeTag(this.value[tagCount - 1])

        this.$nextTick().then(() => {
          this.showEditor()
        })
      }
    },

    /**
     */
    handleKeydownEnter () {
      if (this.isTagNew || this.isUserInputInSuggestions) {
        this.addTag(this.userInput)

        this.$nextTick().then(() => {
          this.showEditor()
        })
      }
    },

    /**
     * @param {string | number} tag
     */
    addTag (tag) {
      this.$emit('add', tag)

      this.$nextTick().then(() => {
        this.showEditor()
      })
    },

    /**
     * @param {string | number} tag
     */
    removeTag (tag) {
      this.$emit('remove', tag)

      this.$nextTick().then(() => {
        this.isEditorActive = true
        this.focusInputField()
      })
    },

    /**
     */
    showEditor () {
      this.isEditorActive = true

      this.$nextTick().then(() => {
        this.focusInputField()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/utils';

$min-height-editor: 40px;
$max-height-editor: 300px;
$width-suggestions: 200px;
$max-height-suggestions: 300px;
$gap-tags: $spacer-unit / 2;
$tag-editor-button-height: 34px;

.tag-editor {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: $min-height-editor;
  max-height: $max-height-editor;
  padding: $gap-tags;
  overflow: auto;
}

.tag-editor__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.tag-editor__tag-wrapper {
  max-width: 100%;
  border: $gap-tags solid transparent;
}

.tag-editor__tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag-editor__tag-content {
  @include text-ellipsis(90%);
}

.tag-editor__input {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  padding: $gap-tags;
}

.tag-editor__button {
  width: 100%;
  height: $tag-editor-button-height;
  border: 0;
  cursor: text;

  &:hover {
    background: transparent;
  }
}

.tag-editor__button--hidden,
.tag-editor__input--hidden {
  display: none;
}

.tag-editor__suggestions {
  width: $width-suggestions;
  max-height: $max-height-suggestions;
  margin: 0;
  overflow: auto;
}

.tag-editor__suggestion {
  @include text-ellipsis;
}
</style>

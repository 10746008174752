import { SORT_OPTION } from '@/constants/sortOption'
// ////////////////////////////////////////////////////
// State  (JOB)
// ////////////////////////////////////////////////////

/**
 * @returns {object}
 */
const getDefaultState = () => ({
  jobs: [],
  jobSearch: '',
  jobDetail: null,
  jobStatuses: {},
  jobSorting: SORT_OPTION.NEWEST_FIRST,
  latestJobId: 0,
  pagingJobs: {
    currentPage: 1,
    itemsLimit: 10
  },
  hasJobsReachedEnd: false
})

export default getDefaultState

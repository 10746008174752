/**
 * @param   {object[]} invitations
 *
 * @returns {object[]}
 */
const getInvitations = ({ invitations }) => invitations

/**
 * @param   {PermissionRoleShape[]} roles
 *
 * @returns {PermissionRoleShape[]}
 */
const getRoles = ({ roles }) => roles

const getters = {
  getInvitations,
  getRoles
}

export default getters

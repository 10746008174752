/**
 * @param   {object}   style
 *
 * @returns {object[]}
 */
const getStyleImages = style => {
  const mainVersion = style.versions.find(version => version.is_main === true)

  if (mainVersion) {
    const images = mainVersion.options.flatMap(option => option.images)

    return images.map(image => ({
      displayUrl: image.file_url,
      importUrl: image.file
    }))
  } else {
    return []
  }
}

export default {
  from: {
    /**
     * @param   {object} job
     *
     * @returns {object}
     */
    job (job) {
      const jobProcessed = {
        id: job.id,
        name: job.name,
        previewImageUrl:
          job.preview.length > 0 ? job.preview[0].assets_file : null
      }

      if (job.files && job.files.render) {
        jobProcessed.images = job.files.render.map(render => ({
          displayUrl: render.assets_file,
          importUrl: render.file
        }))
      }

      return jobProcessed
    },

    /**
     * @param   {object} style
     *
     * @returns {object}
     */
    style (style) {
      const styleProcessed = {
        id: style.id,
        name: style.name,
        previewImageUrl: style.thumbnail,
        plmCode: style.plm_code
      }

      if (style.group) {
        styleProcessed.group = style.group.name
      }

      if (style.season) {
        styleProcessed.season = style.season.name
      }

      if (style.price) {
        styleProcessed.price = style.price.name
      }

      if (style.pattern) {
        styleProcessed.pattern = style.pattern.name
      }

      if (style.versions) {
        styleProcessed.images = getStyleImages(style)
      }

      return styleProcessed
    }
  }
}

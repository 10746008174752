<template>
  <ElementTooltip
    ref="tooltip"
    effect="dark"
    :disabled="isDisabled || messageProcessed.length === 0"
    :placement="placement"
    :open-delay="400"
    :enterable="false"
  >
    <div
      slot="content"
      class="stitch-tooltip__content"
    >
      <p
        v-for="(text, index) in messageProcessed"
        :key="index"
      >
        {{ text }}
      </p>
    </div>
    <div>
      <slot />
    </div>
  </ElementTooltip>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  name: 'StitchTooltip',

  props: {
    message: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.arrayOf(VueTypes.string)
    ]).def([]),
    isDisabled: VueTypes.bool.def(false),
    placement: VueTypes.string.def('top')
  },

  computed: {
    /**
     * @returns {Array}
     */
    messageProcessed () {
      return [this.message].flat()
    }
  }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import FeatureFlags from '@/services/featureFlags'
import { canUserAccessUserManagement } from '@/services/permissions'

import { LIBRARY_TYPE } from '@/constants/libraryType'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  routes: [
    /* ROOT */
    {
      path: '/',
      name: 'DesignHUB',
      component: () => import('@/views/Root')
    },
    /* LIBRARY */
    {
      path: '/library',
      redirect: {
        name: 'LibraryBlocks'
      },
      component: () => import('@/views/Root'),
      children: [
        /* BLOCKS */
        {
          path: 'blocks',
          name: 'LibraryBlocks',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.BLOCK
          }
        },
        /* TRIMS */
        {
          path: 'trims',
          name: 'LibraryTrims',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.TRIM
          }
        },
        /* FABRICS */
        {
          path: 'fabrics',
          name: 'LibraryFabrics',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.FABRIC
          }
        },
        /* STITCHES */
        {
          path: 'stitches',
          name: 'LibraryStitchTypes',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.STITCH
          }
        },
        /* STYLES */
        {
          path: '/styles',
          name: 'LibraryStylesTypes',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.STYLE
          }
        }
      ]
    },
    /* JOBS / RENDERS */
    {
      path: '/jobs',
      component: () => import('@/views/Root'),
      children: [
        {
          path: '',
          name: 'RendersLibrary',
          component: () => import('@/views/library/RendersLibrary')
        }
      ]
    },
    /* STITCH Plugin */
    {
      path: '/plugin',
      component: () => import('@/views/Root'),
      children: [
        {
          path: '',
          name: 'PluginSetup',
          component: () => import('@/views/PluginSetup'),
          meta: {
            check: FeatureFlags.canUserAccessStitchPlugin
          }
        }
      ]
    },
    {
      path: '/admin-tools',
      component: () => import('@/views/Root'),
      children: [
        {
          path: '',
          name: 'AdminTools',
          component: () => import('@/views/AdminTools.vue'),
          meta: {
            check: canUserAccessUserManagement(),
            title: 'Admin Tools'
          }
        }
      ]
    },
    {
      path: '/settings',
      component: () => import('@/views/Root'),
      children: [
        {
          path: '',
          name: 'Settings',
          component: () => import('@/views/Settings.vue'),
          meta: {
            title: 'Settings'
          }
        }
      ]
    },
    /*
      Browzwear integration / PLUGIN
    */
    {
      path: '/bwplugin',
      name: 'BrowzwearPlugin',
      redirect: {
        name: 'BrowzwearPluginFabrics'
      },
      component: () => import('@/views/Plugins'),
      children: [
        /* STITCHES */
        {
          path: 'stitches',
          // eslint-disable-next-line no-secrets/no-secrets -- we need to be consistent with the naming here despite the string entropy
          name: 'BrowzwearPluginStitches',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.STITCH
          }
        },
        /* TRIMS */
        {
          path: 'trims',
          name: 'BrowzwearPluginTrims',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.TRIM
          }
        },
        /* BLOCKS */
        {
          path: 'blocks',
          name: 'BrowzwearPluginBlocks',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.BLOCK
          }
        },
        /* FABRICS */
        {
          path: 'fabrics',
          name: 'BrowzwearPluginFabrics',
          component: () => import('@/views/library/ItemsLibrary'),
          meta: {
            libraryType: LIBRARY_TYPE.FABRIC
          }
        }
      ]
    },
    /* DESIGN SYSTEM */
    {
      path: '/ds',
      name: 'DesignSystem',
      component: () => import('@/views/DesignSystem')
    },
    /* Companies Selection page for external vendors. */
    {
      path: '/company-selection',
      name: 'CompanySelection',
      component: () => import('@/views/CompanySelection')
    },
    /* Workspace Selection page. */
    {
      path: '/my-workspaces',
      name: 'TheWorkspaces',
      component: () => import('@/views/TheWorkspaces'),
      meta: {
        check: FeatureFlags.canUserAccessWorkspaces
      }
    },
    /*
    This route path is to handle the 404 Page Not Found status code
    */
    {
      path: '*',
      component: () => import('@/views/404')
    }
  ]
})

export default router

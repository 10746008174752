import _first from 'lodash/first'
import { IMAGE_RESOLUTION } from '@/constants/image'

export const DataUtils = {
  methods: {
    /**
     * @param   {object}  item
     *
     * @returns {boolean}
     */
    hasFiles (item) {
      return item && item.filesDict
    },

    /**
     * @param   {object}          item
     * @param   {string}          resolution
     *
     * @returns {string | object}
     */
    getThumbnail (item, resolution) {
      if (item && item.thumbnail) {
        return this.s3trim(item.thumbnail, resolution)
      } else if (this.hasFiles(item) && item.filesDict.thumbnail) {
        const first = _first(item.filesDict.thumbnail)
        const url =
          first &&
          (first.assets_url ||
            first.downloadUrl ||
            first.download_url ||
            first.file)

        if (!url) {
          return null
        }

        if (!resolution) {
          return url
        }

        return this.s3trim(url, resolution)
      } else {
        return null
      }
    },

    /**
     * @param   {object}   item
     * @param   {string}   resolution
     *
     * @returns {string[]}
     */
    getMultipleThumbnail (
      item,
      resolution = IMAGE_RESOLUTION.PREVIEW_LIBRARY_ITEM
    ) {
      if (this.hasFiles(item) && item.filesDict.thumbnail) {
        return item.filesDict.thumbnail.map(thumbnail => {
          return this.s3trim(
            thumbnail.assets_file || thumbnail.downloadUrl,
            resolution
          )
        })
      } else {
        return []
      }
    },

    /**
     * @param   {string} url
     * @param   {string} resolution
     *
     * @returns {string}
     */
    s3trim (url, resolution = null) {
      if (!url) {
        return ''
      }

      let parsedUrl
      try {
        parsedUrl = new URL(url)
      } catch (error) {
        return url
      }

      const format = this.$webpSupport ? 'webp' : 'png'

      // to be able to use a local backend
      if (
        process.env.VUE_APP_API_BASE_URL &&
        process.env.VUE_APP_API_BASE_URL.includes('localhost')
      ) {
        return url
      }

      if (parsedUrl.hostname.startsWith('img.')) {
        // new imgproxy-based URLs
        const oldParts = parsedUrl.pathname.substring(1).split('/')
        // first component is the signature, we can ignore it
        oldParts.shift()

        // Docs for the URL format:
        // https://docs.imgproxy.net/generating_the_url_advanced
        const newParts = [
          // new signature - this is an anti-DoS feature
          // we use 'insecure' since we could only sign
          // the URL if we generated it on the backend, since
          // the signing key must be protected to be useful.
          // Instead, we just rely on Cloudflare signed cookies to
          // prevent non-logged users from accessing the proxy
          'insecure',
          't:0', // trim image
          'sh:0.5', // sharpen it
          `f:${format}` // set format
        ]

        if (resolution !== null) {
          newParts.push(`rs:fit:${resolution.replace('x', ':')}:t:f`)
        }

        parsedUrl.pathname = `/${[...newParts, ...oldParts].join('/')}`
      } else {
        const parameters = ['trim', 'fit-in']
        const filters = ['sharpen(0.3,0.3,true)', `format(${format})`]

        const urlParameters = [
          ...parameters,
          ...(resolution !== null ? [resolution] : []),
          'filters:' + filters.join(':')
        ].join('/')

        parsedUrl.pathname = `/${urlParameters}${parsedUrl.pathname}`
        // removing response-content-disposition qs that changes standard image behaviour (you can't see it on a browser window)
        const params = new URLSearchParams(parsedUrl.search)
        params.delete('response-content-disposition')
        parsedUrl.search = params.toString()
      }

      return parsedUrl.href
    },

    /**
     * @param {string} downloadUrl
     */
    triggerDownload (downloadUrl) {
      const fakeLink = document.createElement('A')
      fakeLink.href = downloadUrl
      fakeLink.download = downloadUrl.substr(downloadUrl.lastIndexOf('/') + 1)
      document.body.appendChild(fakeLink)
      fakeLink.click()
      document.body.removeChild(fakeLink)
    },

    /**
     * @param   {string}  stringToTestAsURL
     * @param   {boolean} [httpHttpsOnly=false]
     *
     * @returns {boolean}
     */
    isValidUrl (stringToTestAsURL, httpHttpsOnly = false) {
      let url

      try {
        url = new URL(stringToTestAsURL)
      } catch (error) {
        return false
      }

      if (httpHttpsOnly) {
        return url.protocol === 'http:' || url.protocol === 'https:'
      }

      return true
    },

    /**
     * @returns {number}
     */
    getParamId () {
      return parseInt(this.$route.params.id)
    },

    /**
     * @param   {object} version
     * @param   {Array}  views
     *
     * @returns {object}
     */
    getBlockOptionDetails (version, views) {
      const option = {
        id: version.id,
        images: views,
        name: version.name,
        versionId: version.id,
        zipUrl: version.zipUrl,
        slideshowImages: version.slideshowImages
      }

      return option
    },

    /**
     * @param   {ItemShape} item
     * @param   {string}    resolution
     *
     * @returns {string[]}
     */
    getColorRunResized (item, resolution) {
      return item.colorRun.map(image => this.s3trim(image, resolution))
    }
  }
}

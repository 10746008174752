<template>
  <div
    :class="[
      'stitch-asset-browser',
      {
        'stitch-asset-browser--asset-type-selector': !activeAssetType
      },
      {
        'stitch-asset-browser--no-assets':
          activeAssetType && !hasAssetsToDisplay
      }
    ]"
  >
    <div v-if="!isAssetTypeSelected">
      <div
        v-if="assetTypes.length > 0"
        class="stitch-asset-browser__content"
      >
        <div class="stitch-asset-browser__list">
          <div
            v-for="type in assetTypes"
            :key="type"
            class="stitch-asset-browser__list-item"
            @click="selectAssetType(type)"
          >
            <div class="stitch-asset-browser__list-label">
              {{ getMenuItemLabel(type) }}
            </div>
            <div class="stitch-asset-browser__asset-icon">
              <i class="el-icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="stitch-asset-browser__message">
          No asset type could be loaded. Check your configuration.
        </p>
      </div>
    </div>
    <div
      v-else-if="activeAssetId === null"
      class="stitch-asset-browser__assets"
    >
      <div
        v-if="!hasOneAssetType && !hasValidForcedAssetType"
        class="stitch-asset-browser__header stitch-asset-browser__header--buttons"
      >
        <div
          class="stitch-asset-browser__header-item"
          @click="onAssetsBackClick"
        >
          <i class="el-icon-arrow-left" /> Back
        </div>
      </div>
      <div class="stitch-asset-browser__header">
        <!-- SEARCH -->
        <ElementInput
          ref="searchInput"
          v-model="searchInput"
          placeholder="Search"
          size="small"
          clearable
          class="stitch-asset-browser__search"
          @input="fetchFromSearch"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          />
        </ElementInput>
      </div>

      <div
        v-infinite-scroll="scrollHitBottom"
        infinite-scroll-distance="200"
        :infinite-scroll-disabled="hasReachedEnd"
        style="overflow: auto;"
      >
        <div class="stitch-asset-browser__content">
          <div
            v-if="hasAssetsToDisplay"
            class="stitch-asset-browser__list"
          >
            <div
              v-for="asset in assets"
              :id="asset.id"
              :key="`asset-${searchInput}-${asset.id}`"
              class="stitch-asset-browser__list-item"
              @click="onAssetClick(asset.id)"
            >
              <div class="stitch-asset-browser__preview-image">
                <img
                  :src="trimImage(asset.previewImageUrl)"
                  :alt="`${asset.name} preview`"
                >
              </div>
              <div class="stitch-asset-browser__list-label">
                <p class="stitch-asset-browser__list-label-name">
                  {{ asset.name }}
                </p>
                <p
                  v-if="asset.plmCode"
                  class="stitch-asset-browser__list-label-plmcode"
                >
                  {{ asset.plmCode }}
                </p>
              </div>
              <div
                v-if="isOutputTypeImages"
                class="stitch-asset-browser__asset-icon"
              >
                <i class="el-icon-arrow-right" />
              </div>
              <div
                v-else-if="asset.id === assetLoadedId"
                class="stitch-asset-browser__loader"
              >
                <i class="el-icon-loading" />
              </div>
            </div>
            <!-- loader -->
            <div
              v-if="isLoadingAssets"
              class="stitch-asset-browser__list-item stitch-asset-browser__loader"
            >
              <i class="el-icon-loading" />
            </div>
          </div>
          <div
            v-else-if="isLoadingAssets"
            class="stitch-asset-browser__loader"
          >
            <i class="el-icon-loading" />
          </div>
          <div v-else-if="searchInput !== ''">
            <p class="stitch-asset-browser__message">
              No results match your search
            </p>
          </div>
          <div v-else-if="!isUserLoggedIn">
            <p class="stitch-asset-browser__message">
              Click
              <a
                :href="`${loginUrl}`"
                target="_blank"
              > here </a>
              to log in and import your images
            </p>
          </div>
          <div v-else>
            <p class="stitch-asset-browser__message">
              No result could be returned
            </p>
          </div>
        </div>
        <!---->
      </div>
    </div>

    <!-- IMAGE SCREEN -->
    <div
      v-if="activeAssetId !== null"
      class="stitch-asset-browser__images"
    >
      <div
        class="stitch-asset-browser__header stitch-asset-browser__header--buttons"
      >
        <div
          class="stitch-asset-browser__header-item"
          @click="onImagesBackClick"
        >
          <i class="el-icon-arrow-left" /> Back
        </div>
        <div
          v-if="hasImagesToDisplay && selectedImages.length === 0"
          class="stitch-asset-browser__header-item"
          @click="onSelectAllClick(activeAsset)"
        >
          Select all
        </div>
        <div
          v-else
          class="stitch-asset-browser__header-item"
          @click="onDoneClick"
        >
          Done
        </div>
      </div>
      <div class="stitch-asset-browser__content">
        <div
          v-if="isLoadingImages"
          class="stitch-asset-browser__loader"
        >
          <i class="el-icon-loading" />
        </div>
        <div
          v-else-if="hasImagesToDisplay"
          class="stitch-asset-browser__images-list"
        >
          <div
            v-for="(image, index) in activeAsset.images"
            :key="index"
            :class="[
              'stitch-asset-browser__images-list-item',
              {
                'stitch-asset-browser__images-list-item--selected':
                  selectedImages.includes(image.importUrl)
              }
            ]"
            @click="onImageClick(image.importUrl)"
          >
            <img
              :src="trimImage(image.displayUrl)"
              alt="preview image"
            >
          </div>
        </div>
        <div v-else>
          <p class="stitch-asset-browser__message">
            No image could be found for the selected item
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'

import serverAPI from '../services/serverAPI'
import assetConverter from '../services/assetConverter'
import AssetTypeUtils from '../services/assetTypeUtils'

const OUTPUT_TYPES = {
  ASSET: 'asset',
  IMAGES: 'images'
}

export default {
  name: 'StitchAssetBrowser',

  props: {
    assetType: {
      type: String,
      required: false,
      default: null
    },

    outputType: {
      type: String,
      required: false,
      default: OUTPUT_TYPES.IMAGES,
      validator: value => Object.values(OUTPUT_TYPES).includes(value)
    }
  },

  data: () => ({
    selectedImages: [],
    isLoadingAssets: false,
    isLoadingImages: false,
    isUserLoggedIn: false,
    imageResolution: '300x300',
    activeAssetType: null,
    activeAssetId: null,
    assetLoadedId: null,

    // Asset store
    assets: [],
    searchInput: '',
    pagingAssets: {
      currentPage: 1,
      itemsLimit: 10
    },
    hasReachedEnd: false
  }),

  computed: {
    /**
     * @returns {boolean}
     */
    hasAssetsToDisplay () {
      return this.assets.length > 0
    },

    /**
     * @returns {boolean}
     */
    hasImagesToDisplay () {
      return this.activeAsset.images && this.activeAsset.images.length > 0
    },

    /**
     * @returns {Array}
     */
    assetTypes () {
      return AssetTypeUtils.getAssetTypes()
    },

    /**
     * @returns {object}
     */
    activeAsset () {
      return this.getAssetById(this.activeAssetId)
    },

    /**
     * @returns {boolean}
     */
    hasOneAssetType () {
      return this.assetTypes.length === 1
    },

    /**
     * @returns {boolean}
     */
    hasValidForcedAssetType () {
      return this.assetType && this.assetTypes.includes(this.assetType)
    },

    /**
     * @returns {boolean}
     */
    isAssetTypeSelected () {
      return this.activeAssetType !== null
    },

    /**
     * @returns {object}
     */
    selectedAssetConverter () {
      return assetConverter.from[this.activeAssetType]
    },

    /**
     * @returns {string}
     */
    loginUrl () {
      return AssetTypeUtils.getAssetTypeConfiguration(this.activeAssetType)
        .loginUrl
    },

    /**
     * @returns {boolean}
     */
    isOutputTypeImages () {
      return this.outputType === OUTPUT_TYPES.IMAGES
    }
  },

  created () {
    if (this.hasOneAssetType) {
      this.selectAssetType(this.assetTypes[0])
    }

    if (this.hasValidForcedAssetType) {
      this.selectAssetType(this.assetType)
    }
  },

  methods: {
    /**
     * @param   {string} assetType
     *
     * @returns {string}
     */
    getMenuItemLabel (assetType) {
      return AssetTypeUtils.getAssetTypeConfiguration(assetType).menuItemLabel
    },

    /**
     */
    async loadAssets () {
      this.isLoadingAssets = true
      await this.fetchFilteredAssets()
      this.isLoadingAssets = false
    },

    /**
     */
    async fetchFilteredAssets () {
      const search = this.searchInput
      const limit = this.pagingAssets.itemsLimit
      const page = this.pagingAssets.currentPage

      const assets = await serverAPI.fetchFilteredAssets(
        this.activeAssetType,
        search,
        limit,
        page
      )

      if (assets === false) {
        this.isUserLoggedIn = false

        return
      } else {
        this.isUserLoggedIn = true
      }

      // Request canceled or user clicked 'back' before fetchFilteredAssets() finishes
      if (assets === null || !this.isAssetTypeSelected) {
        return
      }

      if (assets.length === 0) {
        this.hasReachedEnd = true

        return
      }

      const offset = (page - 1) * limit
      const assetsConverted = assets.map(asset =>
        this.selectedAssetConverter(asset)
      )
      this.assets.splice(offset, offset + limit, ...assetsConverted)
    },

    /**
     */
    fetchFromSearch: _debounce(
      /**
       */
      function () {
        this.resetAssets()
        this.loadAssets()
      },
      500
    ),

    /**
     */
    resetAssets () {
      this.hasReachedEnd = false
      this.pagingAssets.currentPage = 1
      this.assets = []
    },

    /**
     * @param   {number} assetId
     *
     * @returns {object}
     */
    async fetchAsset (assetId) {
      const asset = await serverAPI.fetchAsset(this.activeAssetType, assetId)
      const oldAssetIndex = this.assets.findIndex(asset => asset.id === assetId)
      const assetConverted = this.selectedAssetConverter(asset)

      this.assets.splice(oldAssetIndex, 1, assetConverted)

      return assetConverted
    },

    /**
     * @param {string} assetType
     */
    selectAssetType (assetType) {
      this.activeAssetType = assetType
      this.loadAssets()
    },

    /**
     * @param {number} assetId
     */
    async onAssetClick (assetId) {
      if (this.isOutputTypeImages) {
        this.isLoadingImages = true
        this.activeAssetId = assetId
      } else {
        this.assetLoadedId = assetId
      }

      const asset = await this.fetchAssetIfNeeded(assetId)

      if (this.isOutputTypeImages) {
        this.isLoadingImages = false
      } else {
        this.assetLoadedId = null
        this.$emit('select-asset', asset)
      }
    },

    /**
     * @param   {number} assetId
     *
     * @returns {object}
     */
    async fetchAssetIfNeeded (assetId) {
      let asset = this.getAssetById(assetId)

      if (!asset || asset.images === undefined) {
        asset = await this.fetchAsset(assetId)
      }

      return asset
    },

    /**
     * @param   {number} assetId
     *
     * @returns {object}
     */
    getAssetById (assetId) {
      return this.assets.find(asset => asset.id === this.activeAssetId)
    },

    /**
     * @param {string} imageUrl
     */
    onImageClick (imageUrl) {
      const index = this.selectedImages.findIndex(
        selectedImageUrl => selectedImageUrl === imageUrl
      )

      if (index !== -1) {
        this.selectedImages.splice(index, 1)
      } else {
        this.selectedImages.push(imageUrl)
      }
    },

    /**
     */
    onAssetsBackClick () {
      this.activeAssetType = null
      this.resetAssets()
    },

    /**
     */
    onImagesBackClick () {
      this.activeAssetId = null
      this.selectedImages = []
    },

    /**
     * @param {number} assetId
     */
    onSelectAllClick (assetId) {
      this.selectedImages = [
        ...this.activeAsset.images.map(image => image.importUrl)
      ]
    },

    /**
     */
    onDoneClick () {
      this.$emit('select-files', this.selectedImages)
    },

    // HELPERS

    /**
     * @param   {string} url
     *
     * @returns {string}
     */
    trimImage (url) {
      if (!url) {
        return require('../static/img-placeholder.svg')
      }

      const parsedUrl = new URL(url)
      const format = this.$webpSupport ? 'webp' : 'png'

      if (parsedUrl.hostname.startsWith('img.')) {
        // new imgproxy-based URLs
        const oldParts = parsedUrl.pathname.substring(1).split('/')
        // first component is the signature, we can ignore it
        oldParts.shift()

        const newParts = [
          'insecure', // new signature
          't:0', // trim image
          `rs:fit:${this.imageResolution.replace('x', ':')}:t:f`, // fit-in
          `f:${format}` // set format
        ]

        parsedUrl.pathname = `/${[...newParts, ...oldParts].join('/')}`

        return parsedUrl.href
      } else {
        const imageFilters = `/trim/fit-in/${this.imageResolution}/filters:format(${format})`

        return url.replace(
          parsedUrl.pathname,
          `${imageFilters}${parsedUrl.pathname}`
        )
      }
    },

    /**
     */
    scrollHitBottom: _debounce(
      /**
       */
      async function () {
        if (!this.hasReachedEnd && this.isUserLoggedIn) {
          this.pagingAssets.currentPage++
          await this.loadAssets()
        }
      },
      500
    )
  }
}
</script>

<style lang="scss" scoped>
$spacer-unit: 8px;
$spacer-unit-x2: 16px;

$blue: #409eff;
$purple: #5856d6;
$grey: #9aa5b2;
$grey-light: #e4e7ed;
$grey-dark: #606266;
$grey-blue-light: #ebeff4;

$list-item-size-height: 55px;
$image-preview-size: 64px;
$border-size: 1px;
$border-radius-default: 4px;
$gutter: $spacer-unit * 3;
$header-height: $spacer-unit-x2 * 2;
$plm-code-height: $spacer-unit / 2;

$font-weight-medium: 500;
$font-size-small: 11px;
$font-size-medium: 12px;
$font-size-larger: 20px;

$columns-image-list: repeat(auto-fill, minmax($image-preview-size, 1fr));
$height-stitch-asset-browser: 300px;
$asset-preview-size: calc(#{$list-item-size-height} - #{$spacer-unit * 2});
$asset-preview-size-image: 85%;

@mixin center {
  $mixin-center: 50%;

  position: relative;
  top: $mixin-center;
  left: $mixin-center;
  transform: translate(-1 * $mixin-center, -1 * $mixin-center);
}

.stitch-asset-browser {
  height: $height-stitch-asset-browser;
}

.stitch-asset-browser--asset-type-selector,
.stitch-asset-browser--no-assets {
  height: fit-content;
}

.stitch-asset-browser--no-assets {
  padding-bottom: $spacer-unit-x2;
}

.stitch-asset-browser__assets,
.stitch-asset-browser__images {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: $spacer-unit;
  text-align: left;
  cursor: default;
}

.stitch-asset-browser__header {
  height: $header-height;
  margin-bottom: $spacer-unit;
  padding: 0 $spacer-unit;
  line-height: $header-height;
}

.stitch-asset-browser__header--buttons {
  display: flex;
  justify-content: space-between;
  padding-right: $spacer-unit-x2;
  color: $blue;
}

.stitch-asset-browser__header-item {
  cursor: pointer;
}

.stitch-asset-browser__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: $font-size-larger;
  border-bottom: 0;
}

.stitch-asset-browser__content {
  flex: 1;
  width: 100%;
  overflow-y: scroll;

  > .stitch-asset-browser__loader {
    padding-top: $spacer-unit;
  }
}

.stitch-asset-browser__list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.stitch-asset-browser__list-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 100%;
  height: $list-item-size-height;
  padding: $spacer-unit;
  color: $grey;
  border-top: $border-size solid $grey-light;
  cursor: pointer;

  &:last-child {
    border-bottom: $border-size solid $grey-light;
  }

  &:hover {
    background-color: $grey-blue-light;
  }
}

.stitch-asset-browser__list-label {
  margin-right: auto;
  color: $grey-dark;
  text-align: left;
}

.stitch-asset-browser__list-label-name {
  margin-bottom: 0;
  font-weight: $font-weight-medium;
  font-size: $font-size-medium;
}

.stitch-asset-browser__list-label-plmcode {
  margin-top: $plm-code-height;
  color: $grey;
  font-size: $font-size-small;
}

.stitch-asset-browser__preview-image {
  flex: 0 0 auto;
  width: $asset-preview-size;
  height: $asset-preview-size;
  margin-right: $spacer-unit-x2;

  img {
    @include center;

    max-width: 100%;
    max-height: 100%;
  }
}

.stitch-asset-browser__asset-name {
  text-transform: uppercase;
}

.stitch-asset-browser__images-list {
  display: grid;
  grid-gap: $spacer-unit;
  grid-template-columns: $columns-image-list;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  margin-top: $spacer-unit;
  padding: 0 $spacer-unit;
}

.stitch-asset-browser__images-list-item {
  width: $image-preview-size;
  height: $image-preview-size;
  background-color: $grey-blue-light;
  border: $border-size solid transparent;
  border-radius: $border-radius-default/1.5;
  cursor: pointer;

  img {
    @include center;

    max-width: $asset-preview-size-image;
    max-height: $asset-preview-size-image;
  }
}

.stitch-asset-browser__images-list-item--selected {
  border-color: $blue;
}

.stitch-asset-browser__back,
.stitch-asset-browser__done {
  cursor: pointer;
}

.stitch-asset-browser__message {
  text-align: center;
  word-break: break-word;

  a {
    color: $purple;
  }
}
</style>

<template>
  <span class="stitch-icon">
    <component
      :is="icon"
      ref="icon"
    />
  </span>
</template>

<script>
// dynamically importing svg https://github.com/visualfanatic/vue-svg-loader/issues/51

import VueTypes from 'vue-types'

export default {
  name: 'StitchIcon',

  props: {
    name: VueTypes.string.isRequired
  },

  computed: {
    /**
     * @returns {object}
     */
    icon () {
      return require(`@/assets/icons/${this.name}.svg?inline`)
    }
  }
}
</script>

<style lang="scss" scoped>
.stitch-icon {
  display: inline-block;
  vertical-align: middle;
}
</style>

const UPLOAD_STATUS = Object.freeze({
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
})

const JOB_STATUS = Object.freeze({
  UPLOADING: 'UPLOADING',
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  FAILED: 'FAILURE',
  SUCCESS: 'SUCCESS',
  DOWNLOAD: 'DOWNLOAD'
})

const JOB_STATUS_TEXT = Object.freeze({
  UPLOADING: 'Uploading',
  PENDING: 'Rendering',
  FAILED: 'Renders Failed',
  STARTED: 'Rendering',
  SUCCESS: 'Renders Completed',
  DOWNLOAD: 'Download',
  CREATED: 'Creating'
})

// TODO: replace renderFarmMessages with Error codes
const RENDER_JOB_FAILED_MESSAGE = Object.freeze({
  NO_SNAPSHOTS: {
    renderFarmMessage:
      'No snapshots found for the garment! Check if the BW file has snapshots.',
    title: "Sorry, we couldn't render your file due to snapshot error.",
    instruction:
      'Please check all pieces have a fabric assigned and save the file including the avatar.'
  },
  SNAPSHOT_ERROR: {
    renderFarmMessage:
      'Failed to render due to snapshot error. Make sure the BW file has only one manual snapshot.',
    title: "Sorry, we couldn't render your file due to snapshot error.",
    instruction:
      'Please check all pieces have a fabric assigned and save the file including the avatar.'
  },
  NO_AVATAR: {
    renderFarmMessage:
      "Couldn't render your file! Please check all pieces have fabric assigned, and save the file including Avatar.",
    title: "Sorry, we couldn't render your file.",
    instruction:
      'Please check all pieces have a fabric assigned and save the file including the avatar.'
  },
  DEFAULT: {
    title: "Sorry, we couldn't render your file.",
    instruction:
      'Please check if the file meets all requirements and try again.'
  }
})

const PREPARE_THUMBNAIL_TIMEOUT = 30 * 60 * 1000

export {
  UPLOAD_STATUS,
  JOB_STATUS,
  JOB_STATUS_TEXT,
  PREPARE_THUMBNAIL_TIMEOUT,
  RENDER_JOB_FAILED_MESSAGE
}

import FeatureFlagsManager from './manager/FeatureFlagsManager'
import Feature from './components/Feature'

/**
 * @param {Vue}    Vue
 * @param {object} [config={}]
 */
const install = (Vue, config = {}) => {
  Vue.prototype.$featureFlagsManager = Vue.featureFlagsManager =
    new FeatureFlagsManager(config)
  Vue.component('Feature', Feature)
}

export default {
  install
}

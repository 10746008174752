// ////////////////////////////////////////////////////
// Index (ROOT)
// ////////////////////////////////////////////////////

import actions from '@/store/modules/root/actions.js'
import getters from '@/store/modules/root/getters.js'
import mutations from '@/store/modules/root/mutations.js'
import getDefaultState from '@/store/modules/root/state.js'

export default {
  actions,
  getters,
  mutations,
  state: getDefaultState()
}

// ////////////////////////////////////////////////////
// Mutations (JOB)
// ////////////////////////////////////////////////////

import Vue from 'vue'
import toItemConverter from '@/services/toItemConverter'

/**
 * @param {Store.state} state
 * @param {object[]}    jobs
 *
 */
const setJobs = (state, jobs) => {
  state.jobs = [
    ...state.jobs,
    ...jobs.map(job => toItemConverter.from.job(job))
  ]
}

/**
 * Uses Vue.delete() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {number}      jobId
 */
const deleteJob = (state, jobId) => {
  const index = state.jobs.findIndex(jobState => jobState.id === jobId)
  state.jobs.splice(index, 1)
  delete state.jobStatuses[jobId]
  Vue.delete(state.jobStatuses, jobId)
}

/**
 * @param {Store.state} state
 * @param {object}      job
 */
const updateJob = (state, job) => {
  const index = state.jobs.findIndex(singleJob => singleJob.id === job.id)

  if (index !== -1) {
    state.jobs.splice(index, 1, toItemConverter.from.job(job))
  }
}

/**
 * @param {Store.state} state
 * @param {number}      jobId
 */
const setLatestJobId = (state, jobId) => {
  state.latestJobId = jobId
}

/**
 * @param {Store.state} state
 * @param {object[]}    jobs
 */
const prependNewJobs = (state, jobs) => {
  const convertedJobs = jobs.map(job => toItemConverter.from.job(job))
  state.jobs.unshift(...convertedJobs)
}

/**
 * @param {Store.state} state
 * @param {object}      jobDetail
 */
const setJobDetail = (state, jobDetail) => {
  state.jobDetail = toItemConverter.from.job(jobDetail)
}

/**
 * @param {Store.state} state
 */
const resetJobDetail = state => {
  state.jobDetail = null
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.sorting
 */
const setSortingJobs = (state, { sorting }) => {
  state.jobSorting = sorting
}

/**
 * @param {Store.state} state
 */
const incrementPagingJobs = state => {
  state.pagingJobs.currentPage++
}

/**
 * @param {Store.state} state
 * @param {boolean}     hasJobsReachedEnd
 */
const setHasJobsReachedEnd = (state, hasJobsReachedEnd) => {
  state.hasJobsReachedEnd = hasJobsReachedEnd
}

/**
 * @param {Store.state} state
 */
const resetJobs = state => {
  state.pagingJobs.currentPage = 1
  state.hasJobsReachedEnd = false
  state.jobs = []
}

/**
 * @param {Store.state} state
 * @param {string}      jobSearchQuery
 */
const setJobSearch = (state, jobSearchQuery) => {
  state.jobSearch = jobSearchQuery
}

/**
 * Uses Vue.set() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {object[]}    jobs
 */
const setJobsUploadStatus = (state, jobs) => {
  jobs.forEach(job => {
    const jobStatus = job.status
    Vue.set(state.jobStatuses, job.id, jobStatus)
  })
}

/**
 * Uses Vue.set() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {object}      job
 */
const setJobStatus = (state, job) => {
  const jobStatus = job.status
  Vue.set(state.jobStatuses, job.id, jobStatus)
}

/**
 * Uses Vue.set() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {object}      payload
 */
const forceJobStatus = (state, payload) => {
  Vue.set(state.jobStatuses, payload.jobId, payload.jobStatus)
}

const mutations = {
  setJobs,
  deleteJob,
  updateJob,
  setLatestJobId,
  prependNewJobs,
  setJobDetail,
  resetJobDetail,
  setSortingJobs,
  incrementPagingJobs,
  setHasJobsReachedEnd,
  resetJobs,
  setJobSearch,
  setJobsUploadStatus,
  setJobStatus,
  forceJobStatus
}

export default mutations

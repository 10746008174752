<template>
  <div class="option-render-steps">
    <ElementSteps
      direction="vertical"
      :active="renderStatus[version.autorenderStatus].step"
    >
      <ElementStep
        :class="renderStatus[JOB_STATUS.UPLOADING].class"
        :title="renderStatus[JOB_STATUS.UPLOADING].title"
        :description="renderStatus[JOB_STATUS.UPLOADING].description"
        :icon="renderStatus[JOB_STATUS.UPLOADING].icon"
      />
      <ElementStep
        :class="renderStatus[JOB_STATUS.CREATED].class"
        :title="renderStatus[JOB_STATUS.CREATED].title"
        :description="renderStatus[JOB_STATUS.CREATED].description"
        :icon="renderStatus[JOB_STATUS.CREATED].icon"
      />
      <ElementStep
        :class="renderStatus[JOB_STATUS.PENDING].class"
        :title="renderStatus[JOB_STATUS.PENDING].title"
        :description="renderStatus[JOB_STATUS.PENDING].description"
        :icon="renderStatus[JOB_STATUS.PENDING].icon"
      />
      <ElementStep
        :class="renderStatus[JOB_STATUS.STARTED].class"
        :title="renderStatus[JOB_STATUS.STARTED].title"
        :description="renderStatus[JOB_STATUS.STARTED].description"
        :icon="renderStatus[JOB_STATUS.STARTED].icon"
      />
    </ElementSteps>
  </div>
</template>

<script>
import { JOB_STATUS } from '@/constants/loadingStatus'

import VueTypes from 'vue-types'
import { BlockVersionShape } from '@/types'

export default {
  name: 'ViewRenderSteps',

  props: {
    version: VueTypes.objectOf(BlockVersionShape).loose
  },

  data () {
    return {
      JOB_STATUS
    }
  },

  computed: {
    /**
     * @returns {object}
     */
    renderStep () {
      return {
        [JOB_STATUS.UPLOADING]: {
          step: 1
        },

        [JOB_STATUS.CREATED]: {
          step: 2
        },

        [JOB_STATUS.PENDING]: {
          step: 3
        },

        [JOB_STATUS.STARTED]: {
          step: 4
        }
      }
    },

    /**
     * @returns {object}
     */
    renderStatus () {
      return {
        [JOB_STATUS.UPLOADING]: {
          step: 1,
          title: 'Block upload',
          icon: this.getStatusStyle(JOB_STATUS.UPLOADING).icon,
          class: this.getStatusStyle(JOB_STATUS.UPLOADING).class,
          description:
            this.version.autorenderStatus === JOB_STATUS.UPLOADING
              ? 'Block is being created'
              : ''
        },

        [JOB_STATUS.CREATED]: {
          step: 2,
          title: 'Block created',
          icon: this.getStatusStyle(JOB_STATUS.CREATED).icon,
          class: this.getStatusStyle(JOB_STATUS.CREATED).class,
          description:
            this.version.autorenderStatus === JOB_STATUS.CREATED
              ? 'Block created successfully'
              : ''
        },

        [JOB_STATUS.PENDING]: {
          step: 3,
          title: 'Render queued',
          icon: this.getStatusStyle(JOB_STATUS.PENDING).icon,
          class: this.getStatusStyle(JOB_STATUS.PENDING).class,
          description:
            this.version.autorenderStatus === JOB_STATUS.PENDING
              ? 'We will start rendering soon'
              : ''
        },

        [JOB_STATUS.STARTED]: {
          step: 4,
          title: 'Rendering',
          icon: this.getStatusStyle(JOB_STATUS.STARTED).icon,
          class: this.getStatusStyle(JOB_STATUS.STARTED).class,
          description:
            this.version.autorenderStatus === JOB_STATUS.STARTED
              ? 'Block is rendering'
              : ''
        }
      }
    }
  },

  methods: {
    /**
     * @param   {string} status
     *
     * @returns {object}
     */
    getStatusStyle (status) {
      return this.version.autorenderStatus === status
        ? {
            icon: 'el-icon-loading',
            class: 'option-render-steps__step-inprogress'
          }
        : this.renderStep[this.version.autorenderStatus].step >
          this.renderStep[status].step
          ? {
              icon: 'el-icon-circle-check',
              class: 'option-render-steps__step-done'
            }
          : {
              icon: '',
              class: 'option-render-steps__step-next'
            }
    }
  }
}
</script>

<style lang="scss">
.option-render-steps {
  width: 100%;
  height: spacing(32) !important;
  padding-top: spacing(4);
}

.el-step__head {
  border-color: $grey-dark;
}

.el-step__title {
  padding-bottom: spacing(0) !important;
  font-weight: $font-weight-medium;
}

.el-step__description.is-finish {
  color: $grey-dark;
  font-weight: $font-weight-medium;
}

.el-step__line {
  border-color: $grey-dark;
}

.option-render-steps__step-done {
  .el-step__title {
    color: $grey-dark;
  }

  .el-step__head {
    color: $green;
  }
}

.option-render-steps__step-inprogress {
  .el-step__description.is-finish {
    color: $blue;
    font-weight: $font-weight-medium;
  }
}

.option-render-steps__step-next {
  .el-step__title {
    color: $grey-light;
  }

  .el-step__head {
    color: $grey-light;
    border-color: $grey-light;
  }
}
</style>

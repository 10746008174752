import fileAPI from '@/services/serverAPI/fileAPI'
import itemAPI from '@/services/serverAPI/itemAPI'
import userAPI from '@/services/serverAPI/userAPI'
import styleAPI from '@/services/serverAPI/styleAPI'
import styleFilterAPI from '@/services/serverAPI/styleFilterAPI'
import invitationAPI from '@/services/serverAPI/invitationAPI'
import notificationsAPI from '@/services/serverAPI/notificationsAPI'

const serverAPI = {
  ...fileAPI,
  ...itemAPI,
  ...userAPI,
  ...styleAPI,
  ...invitationAPI,
  ...styleFilterAPI,
  ...notificationsAPI
}

export default serverAPI

import TrackingMixpanel from './TrackingMixpanel'

class TrackingInterface {
  pageName = null

  pagePath = null

  /**
   * @param {object} [ids={}]
   * @param {string} [ids.mixpanelId]
   * @param {object} config
   */
  constructor ({ mixpanelId } = {}, config) {
    this.trackers = [new TrackingMixpanel(mixpanelId, config)]
  }

  /**
   * @returns {boolean}
   */
  isEnabled () {
    return this.trackers.some(tracker => tracker.isEnabled())
  }

  /**
   * @param {object} [payload={}]
   */
  trackEvent (payload = {}) {
    if (!payload.event) {
      payload.event = `${payload.object} ${payload.action}`
    }

    if (!payload.category && payload.item) {
      payload.category = payload.item.libraryType
    }

    payload.page = this.pageName
    payload.pagePath = this.pagePath

    this.trackers.forEach(tracker => {
      tracker.trackEvent(payload)
    })
  }

  /**
   * @param {UserShape | {}} [user={}]
   */
  identifyUser (user = {}) {
    this.trackers.forEach(tracker => {
      const userIdentifier = user[tracker.settings.userIdentifierField]
      tracker.identifyUser(userIdentifier, user)
    })
  }
}

export default TrackingInterface

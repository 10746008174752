// ////////////////////////////////////////////////////
// Getters (JOB)
// ////////////////////////////////////////////////////

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getPagingJobs = state => state.pagingJobs

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getJobs = state => state.jobs

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getJobDetail = state => state.jobDetail

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getLatestJobId = state => state.latestJobId

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getJobStatuses = state => state.jobStatuses

/**
 * @param   {Store.state}              state
 *
 * @returns {function(number): string}       - Returns a curry function that
 *                                           receives an id and returns a Status
 */
const getJobStatusById = state => id => state.jobStatuses[id]

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getHasJobsReachedEnd = state => state.hasJobsReachedEnd

/**
 * @param   {object} state
 *
 * @returns {string}
 */
const getJobSearch = state => state.jobSearch

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getSortingJobs = state => {
  return state.jobSorting
}

const getters = {
  getPagingJobs,
  getJobs,
  getJobDetail,
  getLatestJobId,
  getJobStatuses,
  getJobStatusById,
  getHasJobsReachedEnd,
  getJobSearch,
  getSortingJobs
}

export default getters

<script>
import Vue from 'vue'
import ElDialog from 'element-ui/lib/dialog'

export default {
  name: 'StitchDialog',

  extends: ElDialog,

  props: {
    // eslint-disable-next-line vue/no-unused-properties -- provides a default value to ElDialog.
    beforeClose: {
      type: Function,
      /**
       * @param {Function} done
       */
      default: function (done) {
        if (this.shouldCloseModal) {
          done()
        }
      },
      required: false
    }
  },

  data: () => ({
    shouldCloseModal: true
  }),

  beforeCreate () {
    Vue.delete(this.$options.props, 'top')
    Vue.delete(this.$options.props, 'width')
  },

  mounted () {
    // It should only cancel the Modal close event (using the beforeClose ElDialog attribute)
    // when you press the mouse button and release it outside the modal area
    this.$el.addEventListener('mousedown', this.setModalClose)
    this.$refs.dialog.addEventListener('mouseleave', this.cancelModalClose)
  },

  methods: {
    /**
     */
    setModalClose () {
      this.shouldCloseModal = true
    },

    /**
     */
    cancelModalClose () {
      this.shouldCloseModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/utils';

$dialog-margin-vertical: spacing(5);
$dialog-padding: spacing(2);
$dialog-margin-bottom: $dialog-margin-vertical;
$dialog-margin-top: $dialog-margin-vertical;
$dialog-max-height: calc(
  100vh - #{$dialog-margin-top} - #{$dialog-margin-bottom}
);
$dialog-header-content-margin-bottom: $spacer-unit * 2;
$dialog-body-margin-bottom: $spacer-unit * 2;
$dialog-footer-margin-bottom: $spacer-unit * 2;

.el-dialog {
  display: flex;
  flex-direction: column;
  max-height: $dialog-max-height;
  margin: $dialog-margin-vertical auto;
  padding: $dialog-padding;
  overflow: hidden;

  > div:last-child {
    margin-bottom: 0;
  }

  &.is-fullscreen {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin: 0;
  }

  .el-dialog__header {
    padding: 0;

    > * {
      margin-bottom: $dialog-header-content-margin-bottom;
    }

    .el-dialog__title {
      display: block;
    }

    .el-dialog__title:empty {
      display: none;
    }
  }

  .el-dialog__body {
    display: flex;
    flex: 1;
    max-height: 100%;
    margin-bottom: $dialog-body-margin-bottom;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .el-dialog__body,
  &.el-dialog--center .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    margin-bottom: $dialog-footer-margin-bottom;
    padding: 0;
  }
}
</style>
